import classNames from "classnames";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import AddPaymentPopup from "../../modals/AddPayment";
import EditDefaultPaymentPopup from "../../modals/EditDefaultPayment";
import ErrorAddingNewPaymentPopup from "../../modals/ErrorAddingNewPayment";
import css from "../../style.scss";
import WalletListItem from "./WalletListItem";
import { useEffect } from "react";
import { selectUserId } from "data/user/selectors";
import { loadWalletList } from "data/user/actions";
import { selectWalletIsLoading , selectWalletList } from "data/user/selectors";

const Wallet = ({
  openAddPaymentPopup,
  openEditDefaultPaymentPopup,
  openErrorAddingNewPaymentPopup
}) => {


  const userID = useSelector(selectUserId)
  const isLoading = useSelector(selectWalletIsLoading)
  const walletList = useSelector(selectWalletList)

  
  const dispatch = useDispatch();


  useEffect(() => {
    if(userID) {
      dispatch(loadWalletList(userID))
    }
  }, [userID])
  


  const AddPaymentMethod = value => {
    if (value) {
      openErrorAddingNewPaymentPopup();
    } else {
      openAddPaymentPopup();
    }
  };

  const EditDefaultPaymentMethod = () => {
    openEditDefaultPaymentPopup();
  };

  return (
    <>
      {/* Wallet */}
      <div
        className={classNames(
          css.d_flex,
          css.justify_content_center,
          css.pb_20
        )}
      >
        <button
          className={classNames(css.btn_lg, css.clsBtnOrng)}
          onClick={() => AddPaymentMethod()}
        >
          {" "}
          Add New Payment Method
        </button>
        <button
          className={classNames(css.btn_lg, css.clsBtnPrimaryGreen, css.ml_15)}
          onClick={() => EditDefaultPaymentMethod()}
        >
          {" "}
          Edit Default Payment Settings
        </button>
      </div>
      <div className={css.clsWalletSection}>
        <div
          className={classNames(css.d_flex, css.justify_content_center, "row")}
        >
          <div className="col-lg-10 col-xs-12">
            {walletList && walletList.map((payment, index) => {
              return (
                <WalletListItem
                  key={index}
                  payment={payment}
                  count={walletList?.length}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

// export default Wallet
export default connect(null, {
  openAddPaymentPopup: AddPaymentPopup.open,
  openEditDefaultPaymentPopup: EditDefaultPaymentPopup.open,
  openErrorAddingNewPaymentPopup: ErrorAddingNewPaymentPopup.open
})(Wallet);
