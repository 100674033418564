
import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import mainCss from "../style.scss";
import css from "./style.scss";
import CreateTeamProfile from "./createTeamProfile";
import ReviewTeam from "./reviewTeam";
import SuccessTeamProfile from "./successTeamProfile";
import MultiStepForm from "components/MultiStepForm";
import FormStep from "components/MultiStepForm/FormStep";
import { FormProvider } from "../../../context/FormContext";
import {profileValidationSchema, reviewTeamValidationSchema } from "./validationSchemas";
import { connect, useSelector } from "react-redux";
import LeaveMessagePopup from "../modals/LeaveMessage";
import { selectSelectedTeamMember } from "data/user/selectors";

const ReviewTeamMemberProfile = ({history,leaveMessagePopup}) => {

  const teamMemberDetails = useSelector(selectSelectedTeamMember).toJS();

  const steps = [
    {
      component: (
        <FormStep defaultValues={teamMemberDetails}  CancelButtonLabel="Cancel" showCancelButton={true} >
          <CreateTeamProfile review={true} />
        </FormStep>
      ),
      validationSchema: profileValidationSchema,
      initialValues: teamMemberDetails,
      screen: '1'
    },
    {
      component: (
        <FormStep  showNextButton={false} showBackButton={false} CancelButtonLabel="Back to Profile" showCancelButton={true}>
          <ReviewTeam review={true} />
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isSubmissionStep: true, // No validation for review step
      screen: '2'
    },
    {
      component: (
        <FormStep continueButtonLabel="Return to Account Home" showBackButton={false} >
          <SuccessTeamProfile review={true}/>
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isRedirectStep: true,
      screen: '3'
    },
  ];

  const handleNextStep = (formData, nextStep, currentStep) => {
    if (
      formData?.type !== "SCOUT" && 
      currentStep?.screen === "1" &&  
      (!formData?.spendingLimit || !formData?.propertyLimit)
    ) {
      leaveMessagePopup({
        title : 'Are you sure want to proceed?',
       message : 'without setting usage limits , This will allow unlimited usage.',
        CancelRedirect: () => null, // Stay on the same step
        yesRedirect: () => nextStep(), // Move to the next step
      });
      return; // Prevent navigation until the user selects an option
    }

    if(typeof nextStep === "function") {
      nextStep(); // Move to the next step if no popup is needed    
    }
  };


  const handleSubmit = async (finalData) => {
    try {
      console.log("Submitting: ", finalData);
  
      // Call API and wait for response
      // await createTeamMember(finalData);
  
      // If API is successful, move to next step
      // Call the next step after successful API call
    // if (typeof nextStep === "function") {
    //   nextStep();
    // }
    } catch (error) {
      console.error("Submission error: ", error);
      alert("An error occurred while submitting the form.");
    }
  };

  const handleCancel = () => {
    console.log("Cancel button clicked!");
    leaveMessagePopup({
      CancelRedirect: () => null, 
      yesRedirect: () =>  history.push("/accountnew/landing"),
    });
    // Add any logic to handle the cancel action (e.g., navigate back, reset form, etc.)
  };


  return (
    <div className={mainCss.clsInnerMainBody}>
      <div className={mainCss.clsInnerPage}>
        <FormProvider>
          <MultiStepForm steps={steps} onSubmit={handleSubmit} onCancel={handleCancel} history={history} redirectPath="/accountnew/landing" 
          onNext={handleNextStep}
          />
        </FormProvider>
      </div>
    </div>
  );
};


export default withRouter(connect(null, {
  leaveMessagePopup: LeaveMessagePopup.open
})(ReviewTeamMemberProfile));