

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import React from 'react';
import UploadProfilePicture from '../components/uploadProfilePicture';

import { Form, Formik } from "formik";
// import { isValidEmail, isValidPhone } from 'utils/validation';
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

import { selectSelectedTeamMember } from "data/user/selectors";
import { useDispatch, useSelector } from 'react-redux';

import { getTeamMembers } from 'data/user/actions';
import apiService from 'data/user/apiService';
import { useState } from 'react';
import CreditCardDropdown from '../components/CreditCardDropdown';
import { editProfileValidationSchema } from '../Team/validationSchemas';


const EditTeamProfilePopup = (props) => {


    const teamMemberDetails = useSelector(selectSelectedTeamMember).toJS();
    const [imageUrl, setImageUrl] = useState('');
    const dispatch = useDispatch();

    const getFullDataURL = (base64String) => {
        // Decode the Base64 string to check its format
        const binaryString = atob(base64String);

        // Get the first 4 bytes (magic number) as hexadecimal
        const first4Bytes = binaryString
            .slice(0, 4)
            .split("")
            .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
            .join(" ");

        // Determine the MIME type based on the magic number
        let mimeType = "";
        if (first4Bytes === "89 50 4e 47") {
            mimeType = "image/png";
        } else if (first4Bytes.startsWith("ff d8 ff")) {
            mimeType = "image/jpeg";
        } else if (binaryString.trim().startsWith("<svg")) {
            mimeType = "image/svg+xml";
        } else {
            throw new Error("Unknown image format");
        }

        // Construct the full Data URL
        return `data:${mimeType};base64,${base64String}`;
    }

    // Ensure `initialValues` contains a valid object
    const initialValues = {
        firstName: teamMemberDetails.firstName || '',
        lastName: teamMemberDetails.lastName || '',
        phoneNumber: teamMemberDetails.phone || '',
        email: teamMemberDetails.username || '',
        profilePhoto: '',
        confirmEmail: teamMemberDetails.username || '',
        password: '',
        confirmPassword: '',
        savedLimits: '',
        spendingLimits: ''
    };

    const handleClose = () => {
        props.closePopup();
    };

    const handleSubmit = async (values) => {
        let updateValues = { ...initialValues, ...values }
        const payload = {
            "userId": teamMemberDetails.id,
            "teamMember": updateValues,
            "paymentMethods": []
        }

        try {
            const response = await updateTeamMember(payload); // Await the async function
            if (response.success) {
                console.log("Update successful:", response.teamMember);
                dispatch(getTeamMembers(userID))
                handleClose()
            } else {
                console.error("Update failed:", response.error);
            }
        } catch (error) {
            console.error("Unexpected error:", error);
        }

    };

    const updateTeamMember = async (teamMemberData) => {
        try {
            const response = await apiService.put('/ps/team/member/update', teamMemberData);

            if (response.status === 200 || response.status === 201) {
                return { success: true, data: response.data };
            } else {
                throw new Error(`Unexpected status code: ${response.status}`);
            }

        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccEditMarketingProfileModal)} isOpen uniqId="EditMarketingProfilePopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
                <div className={classNames(css.clsModalContent, "modal-content w-100")}>
                    <div className={classNames(css.clsModalContentBody, teamMemberDetails.scoutUser ? css.teamModalBodyScoutUser : css.teamModalBody, "modal-body rounded")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={editProfileValidationSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                            onSubmit={(values) => {
                                handleSubmit(values)
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={css.clsModalHeader}>
                                        <h2 className={css.clsModalTitle}> Team Member Profile</h2>
                                        <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle, css.mw_651)}>
                                            Enter Profile and Account Information for Your Team Member
                                        </h5>
                                    </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> General Information </h4>

                                                <div className={classNames(css.rowInfo1, "flex-column-reverse flex-lg-row row")}>
                                                    <div className={classNames("col-lg-8 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="firstName" label="First Name" placeholder="First Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="lastName" label="Last Name" placeholder="Last Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="phoneNumber" label="Phone Number" placeholder="Phone Number" errors={errors} touched={touched} required={true} numbers={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames("col-lg-4 col-md-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                                                            <label className={classNames(css.clsFormLabel)}>  Profile Picture </label>
                                                            <div className={css.clsSelectProfileImg}>
                                                                <div className={css.clstProfileImg}>
                                                                    {imageUrl ? (
                                                                        <img src={getFullDataURL(imageUrl)} alt="Profile" />
                                                                    ) : (
                                                                        <img src={userImg} alt="Profile" />
                                                                    )}
                                                                </div>
                                                                <UploadProfilePicture key={imageUrl} label={imageUrl ? 'Edit Photo' : 'Add Photo'} filed={'profilePhoto'} setFieldValue={setFieldValue} setImageUrl={setImageUrl} />
                                                                {imageUrl && (
                                                                    <a
                                                                        href="#"
                                                                        className={css.clsAddTxt}
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); // Prevent page reload
                                                                            setImageUrl(null); // Clear image preview
                                                                            setFieldValue("profilePhoto", null); // Set form value to null
                                                                        }}
                                                                    >
                                                                        Remove Photo
                                                                    </a>
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.mt_48, "clearfix")}>
                                                    <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> Account Information </h4>
                                                    <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                        <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                            <div className={classNames(css.clsFormGroup)}>
                                                                <FormikText name="email" label="Email/Username" errors={errors} placeholder="Email Address" touched={touched} required={true} />
                                                            </div>
                                                        </div>
                                                        <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                            <div className={classNames(css.clsFormGroup)}>
                                                                <FormikText name="confirmEmail" label="Confirm Email/Username" errors={errors} placeholder="Confirm Email Address" touched={touched} required={true} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                        <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                            <div className={classNames(css.clsFormGroup)}>
                                                                <FormikText name="password" label="New Password" errors={errors} placeholder="New Password" touched={touched} required={true} type="password" />
                                                            </div>
                                                        </div>
                                                        <div className={classNames("col-lg-6 col-12")}>
                                                            <div className={classNames(css.clsFormGroup)}>
                                                                <FormikText name="confirmPassword" label="Confirm New Password" errors={errors} placeholder="Confirm New Password" touched={touched} required={true} type="password" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.mt_48, "clearfix")}>
                                                    {!teamMemberDetails.scoutUser && (
                                                        <>
                                                            <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> Usage Limits </h4>
                                                            <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                                <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                                    <div className={classNames(css.clsFormGroup)}>
                                                                        <FormikText name="propertyLimit" label="Save Limit (Monthly)" errors={errors} placeholder="Save Limit (Monthly)" touched={touched} required={false} numbers={true} />
                                                                    </div>
                                                                </div>
                                                                <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                                    <div className={classNames(css.clsFormGroup)}>
                                                                        <FormikText name="spendingLimit" label="Spending Limit (Monthly)" errors={errors} placeholder="Spending Limit (Monthly)" touched={touched} required={false} numbers={true} decimal={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            {!teamMemberDetails.scoutUser && (<div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.mb_24, "text-left")}> Billing </h4>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <label className={classNames(css.clsFormLabel)}> Default for Subscriptions</label>
                                                            <CreditCardDropdown
                                                                tag={false}
                                                                options={[{ label: "A", cardEnding: '0059', expDate: '05/2026' }, { label: "B", cardEnding: '0100', expDate: '65/2027' }, { label: "C", cardEnding: '0099', expDate: '05/2028' }]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <label className={classNames(css.clsFormLabel)}> Default For In App Purchases</label>
                                                            <CreditCardDropdown
                                                                tag={false}
                                                                options={[{ label: "A", cardEnding: '0059', expDate: '05/2026' }, { label: "B", cardEnding: '0100', expDate: '65/2027' }, { label: "C", cardEnding: '0099', expDate: '05/2028' }]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter, css.d_flex, css.justify_content_center)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng, css.ml_20)} type="submit" >  Save </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </Modal>
    );
};


const registrationId = getPopupRegistration(EditTeamProfilePopup);
EditTeamProfilePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditTeamProfilePopup.close = () => closePopup({ popup: registrationId });

export default EditTeamProfilePopup;