import downArrow from "assets/images/account-page/down-arrow.svg";
import upArrowWhite from "assets/images/account-page/up-arrow-white.svg";
import visaCard from "assets/images/account-page/visa-card.svg";
import classNames from "classnames";
import { dropdownable } from "components/base/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import css from "../style.scss";

const CreditCardDropdown = ({
  options,
  isOpen,
  onClick,
  closeDropdown,
  onChange,
  selectedId,
  tag = true
}) => {
  const dropdownToggleRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);


  const handleOptionClick = option => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
    closeDropdown(); // Close dropdown after selection
  };

  useEffect(() => {
   if(selectedId && options?.length) {
   let selectedOption = options.find((option) => option.paymentMethodId === selectedId)
   setSelectedOption(selectedOption)
   } else {
    if(options) {
      setSelectedOption(options[0])
    }
   }
  }, [options,selectedId])
  
  

  const handleClickOutside = event => {
    if (
      dropdownListRef.current &&
      !dropdownListRef.current.contains(event.target) &&
      dropdownToggleRef.current &&
      !dropdownToggleRef.current.contains(event.target)
    ) {
      closeDropdown(); // Close dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={css.dropdown} ref={dropdownToggleRef}>
      <div onClick={onClick}>
        <div className={classNames(css.clsDropdownCard, { [css.clsDropdownCardOpen]: isOpen })}>
          <div
            className={classNames(
              css.clsPayCard,
              css.clsPayCardFull,
              css.align_items_center,
              css.justify_content_between,
              "flex-fill"
            )}
          >
            <div className={classNames(css.d_flex, css.align_items_center)}>
              <img src={selectedOption?.cardTypeDetails?.image} className="mr-3" alt={selectedOption?.cardTypeDetails?.name} />
              <div className="clearfix">
                <h5 className={css.clsMediaTxt1}>Ending in {selectedOption?.cardEnding}</h5>
                <p className={css.clsMediaTxt2}>Exp. {selectedOption?.expDate}</p>
              </div>
            </div>
            {tag ? (<p
              className={classNames(
                css.clsBtnSm,
                css.clsBtnPrimaryGreen,
                "mb-0"
              )}
            >
              Default for Recurring Services
            </p>) :  null}
            
          </div>
          <a className={css.pl_16} href="#" onClick={(e) => e.preventDefault()}>
            <img src={isOpen ? upArrowWhite : downArrow} alt="" />
          </a>
        </div>
      </div>

      {isOpen && (
        <div
          className={classNames(css.dropdownList, { [css.open]: isOpen })}
          ref={dropdownListRef}
        >
          {options.map((option, index) => (
            <div key={index} onClick={() => handleOptionClick(option)}  className={classNames(css.dropdownOptionList, css.d_flex, css.align_items_center)}>
              {/* {option.label} */} 
              <img src={option?.cardTypeDetails?.image} className="mr-3" alt={option?.cardTypeDetails?.name} />
              <div className="clearfix">
              <p className={css.dropdownOptionList1}>Ending in {option?.cardEnding} </p>
              <p className={css.dropdownOptionList2}>Exp. {option?.expDate}</p>
               </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default dropdownable(CreditCardDropdown);
