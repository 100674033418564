export const ReportTypes = {
  analysis: 'ANALYSIS',
  premium: 'PREMIUM',
  property: 'PROPERTY',
};

export default ReportTypes;

export const Reports = {
  comparativeMarketAnalysis: 'COMPARATIVE_MARKET_ANALYSIS',
  comparableProperty: 'COMPARABLE_PROPERTY',
};

export const Permissions = {
  contactView: 'CONTACT_VIEW',
  marketingView: 'MARKETING_VIEW',
  rehabView: 'REHAB_VIEW',
  analysisView: 'ANALYSIS_VIEW',
  searchFull: 'SEARCH_FULL',
};

export const UserTypes = [
  { value: 'FULL', label: 'Full User', colorDark: '#2468c7', colorLight: '#e5eaf1' },
  { value: 'TEAM', label: 'Pro User', colorDark: '#218c0a', colorLight: '#eff4e6' },
  { value: 'TEAM_FULL', label: 'Pro User', colorDark: '#218c0a', colorLight: '#eff4e6' },
  { value: 'SCOUT', label: 'Scout User', colorDark: '#e64f15', colorLight: '#fbebe2' },
];

export const UserTypeMap = UserTypes.reduce((m, t) => ({ ...m, [t.value]: t }), {});

export const Countries = [
  {
    code: 'US',
    states: [
      { code: 'AL', name: 'Alabama' },
      { code: 'AK', name: 'Alaska' },
      { code: 'AS', name: 'American Samoa' },
      { code: 'AZ', name: 'Arizona' },
      { code: 'AR', name: 'Arkansas' },
      { code: 'CA', name: 'California' },
      { code: 'CO', name: 'Colorado' },
      { code: 'CT', name: 'Connecticut' },
      { code: 'DE', name: 'Delaware' },
      { code: 'DC', name: 'District of Columbia' },
      { code: 'FM', name: 'Federated States of Micronesia' },
      { code: 'FL', name: 'Florida' },
      { code: 'GA', name: 'Georgia' },
      { code: 'GU', name: 'Guam' },
      { code: 'HI', name: 'Hawaii' },
      { code: 'ID', name: 'Idaho' },
      { code: 'IL', name: 'Illinois' },
      { code: 'IN', name: 'Indiana' },
      { code: 'IA', name: 'Iowa' },
      { code: 'KS', name: 'Kansas' },
      { code: 'KY', name: 'Kentucky' },
      { code: 'LA', name: 'Louisiana' },
      { code: 'ME', name: 'Maine' },
      { code: 'MH', name: 'Marshall Islands' },
      { code: 'MD', name: 'Maryland' },
      { code: 'MA', name: 'Massachusetts' },
      { code: 'MI', name: 'Michigan' },
      { code: 'MN', name: 'Minnesota' },
      { code: 'MS', name: 'Mississippi' },
      { code: 'MO', name: 'Missouri' },
      { code: 'MT', name: 'Montana' },
      { code: 'NE', name: 'Nebraska' },
      { code: 'NV', name: 'Nevada' },
      { code: 'NH', name: 'New Hampshire' },
      { code: 'NJ', name: 'New Jersey' },
      { code: 'NM', name: 'New Mexico' },
      { code: 'NY', name: 'New York' },
      { code: 'NC', name: 'North Carolina' },
      { code: 'ND', name: 'North Dakota' },
      { code: 'MP', name: 'Northern Mariana Islands' },
      { code: 'OH', name: 'Ohio' },
      { code: 'OK', name: 'Oklahoma' },
      { code: 'OR', name: 'Oregon' },
      { code: 'PW', name: 'Palau' },
      { code: 'PA', name: 'Pennsylvania' },
      { code: 'PR', name: 'Puerto Rico' },
      { code: 'RI', name: 'Rhode Island' },
      { code: 'SC', name: 'South Carolina' },
      { code: 'SD', name: 'South Dakota' },
      { code: 'TN', name: 'Tennessee' },
      { code: 'TX', name: 'Texas' },
      { code: 'AE', name: 'U.S. Armed Forces - Europe' },
      { code: 'AP', name: 'U.S. Armed Forces - Pacific' },
      { code: 'AA', name: 'U.S. Armed Forces - Americas' },
      { code: 'UT', name: 'Utah' },
      { code: 'VT', name: 'Vermont' },
      { code: 'VI', name: 'Virgin Islands' },
      { code: 'VA', name: 'Virginia' },
      { code: 'WA', name: 'Washington' },
      { code: 'WV', name: 'West Virginia' },
      { code: 'WI', name: 'Wisconsin' },
      { code: 'WY', name: 'Wyoming' },
    ],
    name: 'United States',
    stateLabel: 'State',
    postalCodePattern: '^\\d{5}(\\-?\\d{4})?$',
  },
  {
    code: 'CA',
    states: [
      { code: 'AB', name: 'Alberta' },
      { code: 'BC', name: 'British Columbia' },
      { code: 'MB', name: 'Manitoba' },
      { code: 'NB', name: 'New Brunswick' },
      { code: 'NL', name: 'Newfoundland and Labrador' },
      { code: 'NT', name: 'Northwest Territories' },
      { code: 'NS', name: 'Nova Scotia' },
      { code: 'NU', name: 'Nunavut' },
      { code: 'ON', name: 'Ontario' },
      { code: 'PE', name: 'Prince Edward Island' },
      { code: 'QC', name: 'Quebec' },
      { code: 'SK', name: 'Saskatchewan' },
      { code: 'YT', name: 'Yukon' },
    ],
    name: 'Canada',
    stateLabel: 'Province',
    postalCodePattern: '^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]\\d[a-zA-Z] *\\d[a-zA-Z]\\d$',
  },
  { code: 'AF', states: [], name: 'Afghanistan' },
  { code: 'AX', states: [], name: 'Aland Island' },
  { code: 'AL', states: [], name: 'Albania' },
  { code: 'DZ', states: [], name: 'Algeria' },
  { code: 'AD', states: [], name: 'Andorra' },
  { code: 'AO', states: [], name: 'Angola' },
  { code: 'AI', states: [], name: 'Anguilla' },
  { code: 'AG', states: [], name: 'Antigua and Barbuda' },
  { code: 'AR', states: [], name: 'Argentina' },
  { code: 'AM', states: [], name: 'Armenia' },
  { code: 'AW', states: [], name: 'Aruba' },
  { code: 'AU',
    states: [
      { code: 'ACT', name: 'Australian Capital Territory' },
      { code: 'JBT', name: 'Jervis Bay Territory' },
      { code: 'NSW', name: 'New South Wales' },
      { code: 'NT', name: 'Northern Territory' },
      { code: 'QLD', name: 'Queensland' },
      { code: 'SA', name: 'South Australia' },
      { code: 'TAS', name: 'Tasmania' },
      { code: 'VIC', name: 'Victoria' },
      { code: 'WA', name: 'Western Australia' },
    ],
    name: 'Australia',
    stateLabel: 'State' },
  { code: 'AT', states: [], name: 'Austria' },
  { code: 'AZ', states: [], name: 'Azerbaijan' },
  { code: 'BS', states: [], name: 'Bahamas' },
  { code: 'BH', states: [], name: 'Bahrain' },
  { code: 'BD', states: [], name: 'Bangladesh' },
  { code: 'BB', states: [], name: 'Barbados' },
  { code: 'BY', states: [], name: 'Belarus' },
  { code: 'BE', states: [], name: 'Belgium' },
  { code: 'BZ', states: [], name: 'Belize' },
  { code: 'BJ', states: [], name: 'Benin' },
  { code: 'BM',
    states: [
      { code: 'HA', name: 'City of Hamilton' },
      { code: 'DEV', name: 'Devonshire' },
      { code: 'HAM', name: 'Hamilton' },
      { code: 'PAG', name: 'Paget' },
      { code: 'PEM', name: 'Pembroke' },
      { code: 'SAN', name: 'Sandys' },
      { code: 'SMI', name: 'Smith\u0027s' },
      { code: 'SOU', name: 'Southampton' },
      { code: 'SGE', name: 'St. George\u0027s' },
      { code: 'SG', name: 'Town of St. George\u0027s' },
      { code: 'WAR', name: 'Warwick' },
    ],
    name: 'Bermuda',
    stateLabel: 'Parish' },
  { code: 'BT', states: [], name: 'Bhutan' },
  { code: 'BO', states: [], name: 'Bolivia' },
  { code: 'BQ', states: [], name: 'Bonaire' },
  { code: 'BA', states: [], name: 'Bosnia-Herzegovina' },
  { code: 'BW', states: [], name: 'Botswana' },
  { code: 'BR', states: [], name: 'Brazil' },
  { code: 'BN', states: [], name: 'Brunei Darussalam' },
  { code: 'BG', states: [], name: 'Bulgaria' },
  { code: 'BF', states: [], name: 'Burkina Faso' },
  { code: 'BI', states: [], name: 'Burundi' },
  { code: 'KH', states: [], name: 'Cambodia' },
  { code: 'CM', states: [], name: 'Cameroon' },
  { code: 'KY', states: [], name: 'Cayman Islands' },
  { code: 'CF', states: [], name: 'Central African Republic' },
  { code: 'TD', states: [], name: 'Chad' },
  { code: 'CL', states: [], name: 'Chile' },
  { code: 'CN', states: [], name: 'China' },
  { code: 'CX', states: [], name: 'Christmas Island' },
  { code: 'CC', states: [], name: 'Cocos Island' },
  { code: 'CO', states: [], name: 'Colombia' },
  { code: 'KM', states: [], name: 'Comoros' },
  { code: 'CG', states: [], name: 'Congo' },
  { code: 'CD', states: [], name: 'Congo, Democratic Republic of the' },
  { code: 'CK', states: [], name: 'Cook Islands' },
  { code: 'CR', states: [], name: 'Costa Rica' },
  { code: 'HR', states: [], name: 'Croatia' },
  { code: 'CW', states: [], name: 'Curacao' },
  { code: 'CY', states: [], name: 'Cyprus' },
  { code: 'CZ', states: [], name: 'Czech Republic' },
  { code: 'DK', states: [], name: 'Denmark' },
  { code: 'DJ', states: [], name: 'Djibouti' },
  { code: 'DM', states: [], name: 'Dominica' },
  { code: 'DO', states: [], name: 'Dominican Republic' },
  { code: 'EC', states: [], name: 'Ecuador' },
  { code: 'EG', states: [], name: 'Egypt' },
  { code: 'SV', states: [], name: 'El Salvador' },
  { code: 'GQ', states: [], name: 'Equatorial Guinea' },
  { code: 'ER', states: [], name: 'Eritrea' },
  { code: 'EE', states: [], name: 'Estonia' },
  { code: 'ET', states: [], name: 'Ethiopia' },
  { code: 'FK', states: [], name: 'Falkland Islands' },
  { code: 'FO', states: [], name: 'Faroe Islands' },
  { code: 'FJ', states: [], name: 'Fiji' },
  { code: 'FI', states: [], name: 'Finland' },
  { code: 'FR', states: [], name: 'France' },
  { code: 'GF', states: [], name: 'French Guiana' },
  { code: 'PF', states: [], name: 'French Polynesia' },
  { code: 'GA', states: [], name: 'Gabon' },
  { code: 'GM', states: [], name: 'Gambia' },
  { code: 'GE', states: [], name: 'Georgia' },
  { code: 'DE', states: [], name: 'Germany' },
  { code: 'GH', states: [], name: 'Ghana' },
  { code: 'GI', states: [], name: 'Gibraltar' },
  { code: 'GR', states: [], name: 'Greece' },
  { code: 'GL', states: [], name: 'Greenland' },
  { code: 'GD', states: [], name: 'Grenada' },
  { code: 'GP', states: [], name: 'Guadeloupe' },
  { code: 'GU', states: [], name: 'Guam' },
  { code: 'GT', states: [], name: 'Guatemala' },
  { code: 'GG', states: [], name: 'Guernsey' },
  { code: 'GN', states: [], name: 'Guinea' },
  { code: 'GW', states: [], name: 'Guinea-Bissau' },
  { code: 'GY', states: [], name: 'Guyana' },
  { code: 'HT', states: [], name: 'Haiti' },
  { code: 'HN', states: [], name: 'Honduras' },
  { code: 'HK', states: [], name: 'Hong Kong' },
  { code: 'HU', states: [], name: 'Hungary' },
  { code: 'IS', states: [], name: 'Iceland' },
  { code: 'IN', states: [], name: 'India' },
  { code: 'ID', states: [], name: 'Indonesia' },
  { code: 'IR', states: [], name: 'Iran' },
  { code: 'IQ', states: [], name: 'Iraq' },
  { code: 'IE', states: [], name: 'Ireland' },
  { code: 'IM', states: [], name: 'Isle of Man' },
  { code: 'IL', states: [], name: 'Israel' },
  { code: 'IT', states: [], name: 'Italy' },
  { code: 'JM', states: [], name: 'Jamaica' },
  { code: 'JP', states: [], name: 'Japan' },
  { code: 'JE', states: [], name: 'Jersey' },
  { code: 'JO', states: [], name: 'Jordan' },
  { code: 'KZ', states: [], name: 'Kazakhstan' },
  { code: 'KE', states: [], name: 'Kenya' },
  { code: 'KI', states: [], name: 'Kiribati' },
  { code: 'KW', states: [], name: 'Kuwait' },
  { code: 'KG', states: [], name: 'Kyrgyzstan' },
  { code: 'LA', states: [], name: 'Laos' },
  { code: 'LV', states: [], name: 'Latvia' },
  { code: 'LB', states: [], name: 'Lebanon' },
  { code: 'LS', states: [], name: 'Lesotho' },
  { code: 'LR', states: [], name: 'Liberia' },
  { code: 'LY', states: [], name: 'Libya' },
  { code: 'LI', states: [], name: 'Liechtenstein' },
  { code: 'LT', states: [], name: 'Lithuania' },
  { code: 'LU', states: [], name: 'Luxembourg' },
  { code: 'MO', states: [], name: 'Macao' },
  { code: 'MK', states: [], name: 'Macedonia' },
  { code: 'MG', states: [], name: 'Madagascar' },
  { code: 'MW', states: [], name: 'Malawi' },
  { code: 'MY',
    states: [
      { code: 'KUL', name: 'Federal Territory of Kuala Lumpur' },
      { code: 'LBN', name: 'Federal Territory of Labuan' },
      { code: 'PJY', name: 'Federal Territory of Putrajaya' },
      { code: 'JHR', name: 'Johor' },
      { code: 'KDH', name: 'Kedah' },
      { code: 'KTN', name: 'Kelantan' },
      { code: 'MLK', name: 'Malacca' },
      { code: 'NSN', name: 'Negeri Sembilan' },
      { code: 'PHG', name: 'Pahang' },
      { code: 'PNG', name: 'Penang' },
      { code: 'PRK', name: 'Perak' },
      { code: 'PLS', name: 'Perlis' },
      { code: 'SBH', name: 'Sabah' },
      { code: 'SRW', name: 'Sarawak' },
      { code: 'SGR', name: 'Selangor' },
      { code: 'TRG', name: 'Terengganu' },
    ],
    name: 'Malaysia',
    stateLabel: 'State' },
  { code: 'MV', states: [], name: 'Maldives' },
  { code: 'ML', states: [], name: 'Mali' },
  { code: 'MT', states: [], name: 'Malta' },
  { code: 'MQ', states: [], name: 'Martinique' },
  { code: 'MR', states: [], name: 'Mauritania' },
  { code: 'MU', states: [], name: 'Mauritius' },
  { code: 'YT', states: [], name: 'Mayotte' },
  { code: 'MX',
    states: [
      { code: 'DU', name: 'Durango' },
      { code: 'AG', name: ' Aguascalientes' },
      { code: 'BN', name: ' Baja California' },
      { code: 'BS', name: ' Baja California Sur' },
      { code: 'CM', name: ' Campeche' },
      { code: 'CP', name: ' Chiapas' },
      { code: 'CH', name: ' Chihuahua' },
      { code: 'CA', name: ' Coahuila' },
      { code: 'CL', name: ' Colima' },
      { code: 'DF', name: ' Federal District' },
      { code: 'GT', name: ' Guanajuato' },
      { code: 'GR', name: ' Guerrero' },
      { code: 'HI', name: ' Hidalgo' },
      { code: 'JA', name: ' Jalisco' },
      { code: 'MX', name: ' Mexico State' },
      { code: 'MC', name: ' Michoacán' },
      { code: 'MR', name: ' Morelos' },
      { code: 'NA', name: ' Nayarit' },
      { code: 'NL', name: ' Nuevo León' },
      { code: 'OA', name: ' Oaxaca' },
      { code: 'PU', name: ' Puebla' },
      { code: 'QE', name: ' Querétaro' },
      { code: 'QR', name: ' Quintana Roo' },
      { code: 'SL', name: ' San Luis Potosí' },
      { code: 'SI', name: ' Sinaloa' },
      { code: 'SO', name: ' Sonora' },
      { code: 'TB', name: ' Tabasco' },
      { code: 'TM', name: ' Tamaulipas' },
      { code: 'TL', name: ' Tlaxcala' },
      { code: 'VE', name: ' Veracruz' },
      { code: 'YU', name: ' Yucatán' },
      { code: 'ZA', name: ' Zacatecas' },
    ],
    name: 'Mexico',
    stateLabel: 'State',
  },
  { code: 'MD', states: [], name: 'Moldova' },
  { code: 'MC', states: [], name: 'Monaco' },
  { code: 'MN', states: [], name: 'Mongolia' },
  { code: 'ME', states: [], name: 'Montenegro' },
  { code: 'MS', states: [], name: 'Montserrat' },
  { code: 'MA', states: [], name: 'Morocco' },
  { code: 'MZ', states: [], name: 'Mozambique' },
  { code: 'MM', states: [], name: 'Myanmar' },
  { code: 'NA', states: [], name: 'Namibia' },
  { code: 'NR', states: [], name: 'Nauru' },
  { code: 'NP', states: [], name: 'Nepal' },
  { code: 'NL', states: [], name: 'Netherlands' },
  { code: 'NC', states: [], name: 'New Caledonia' },
  { code: 'NZ', states: [], name: 'New Zealand' },
  { code: 'NI', states: [], name: 'Nicaragua' },
  { code: 'NE', states: [], name: 'Niger' },
  { code: 'NG', states: [], name: 'Nigeria' },
  { code: 'NU', states: [], name: 'Niue' },
  { code: 'NF', states: [], name: 'Norfolk Island' },
  { code: 'NO', states: [], name: 'Norway' },
  { code: 'OM', states: [], name: 'Oman' },
  { code: 'PK', states: [], name: 'Pakistan' },
  { code: 'PA', states: [], name: 'Panama' },
  { code: 'PG', states: [], name: 'Papua New Guinea' },
  { code: 'PY', states: [], name: 'Paraguay' },
  { code: 'PE', states: [], name: 'Peru' },
  { code: 'PH', states: [], name: 'Philippines' },
  { code: 'PN', states: [], name: 'Pitcairn Island' },
  { code: 'PL', states: [], name: 'Poland' },
  { code: 'PT', states: [], name: 'Portugal' },
  { code: 'PR', states: [], name: 'Puerto Rico' },
  { code: 'QA', states: [], name: 'Qatar' },
  { code: 'RE', states: [], name: 'Reunion' },
  { code: 'RO', states: [], name: 'Romania' },
  { code: 'RU', states: [], name: 'Russia' },
  { code: 'RW', states: [], name: 'Rwanda' },
  { code: 'BL', states: [], name: 'Saint Barthelemy' },
  { code: 'SH', states: [], name: 'Saint Helena' },
  { code: 'KN', states: [], name: 'Saint Kitts' },
  { code: 'LC', states: [], name: 'Saint Lucia' },
  { code: 'MF', states: [], name: 'Saint Martin' },
  { code: 'PM', states: [], name: 'Saint Pierre and Miquelon' },
  { code: 'VC', states: [], name: 'Saint Vincent and the Grenadines' },
  { code: 'SM', states: [], name: 'San Marino' },
  { code: 'ST', states: [], name: 'Sao Tome and Principe' },
  { code: 'SA', states: [], name: 'Saudi Arabia' },
  { code: 'SN', states: [], name: 'Senegal' },
  { code: 'RS', states: [], name: 'Serbia' },
  { code: 'SC', states: [], name: 'Seychelles' },
  { code: 'SL', states: [], name: 'Sierra Leone' },
  { code: 'SG', states: [], name: 'Singapore' },
  { code: 'SX', states: [], name: 'Sint Maarten' },
  { code: 'SK', states: [], name: 'Slovakia' },
  { code: 'SI', states: [], name: 'Slovenia' },
  { code: 'SB', states: [], name: 'Solomon Islands' },
  { code: 'ZA', states: [], name: 'South Africa' },
  { code: 'KR', states: [], name: 'South Korea' },
  { code: 'ES', states: [], name: 'Spain' },
  { code: 'LK', states: [], name: 'Sri Lanka' },
  { code: 'SD', states: [], name: 'Sudan' },
  { code: 'SR', states: [], name: 'Suriname' },
  { code: 'SZ', states: [], name: 'Swaziland' },
  { code: 'SE', states: [], name: 'Sweden' },
  { code: 'CH', states: [], name: 'Switzerland' },
  { code: 'TW', states: [], name: 'Taiwan' },
  { code: 'TJ', states: [], name: 'Tajikistan' },
  { code: 'TZ', states: [], name: 'Tanzania' },
  { code: 'TH', states: [], name: 'Thailand' },
  { code: 'TL', states: [], name: 'Timor-Leste' },
  { code: 'TG', states: [], name: 'Togo' },
  { code: 'TK', states: [], name: 'Tokelau' },
  { code: 'TO', states: [], name: 'Tonga' },
  { code: 'TT', states: [], name: 'Trinidad and Tobago' },
  { code: 'TN', states: [], name: 'Tunisia' },
  { code: 'TR', states: [], name: 'Turkey' },
  { code: 'TM', states: [], name: 'Turkmenistan' },
  { code: 'TC', states: [], name: 'Turks and Caicos Islands' },
  { code: 'TV', states: [], name: 'Tuvalu' },
  { code: 'UG', states: [], name: 'Uganda' },
  { code: 'UA', states: [], name: 'Ukraine' },
  { code: 'AE', states: [], name: 'United Arab Emirates' },
  { code: 'GB', states: [], name: 'United Kingdom' },
  { code: 'UY', states: [], name: 'Uruguay' },
  { code: 'UZ', states: [], name: 'Uzbekistan' },
  { code: 'VU', states: [], name: 'Vanuatu' },
  { code: 'VE', states: [], name: 'Venezuela' },
  { code: 'VN', states: [], name: 'Viet Nam' },
  { code: 'VG', states: [], name: 'Virgin Islands (British)' },
  { code: 'WF', states: [], name: 'Wallis and Futuna Islands' },
  { code: 'YE', states: [], name: 'Yemen' },
  { code: 'ZM', states: [], name: 'Zambia' },
  { code: 'ZW', states: [], name: 'Zimbabwe' },
];

export const CountryOptions = Countries.map(country => ({ label: country.name, value: country.code }));
export const CountryMap = Countries.reduce((map, country) => {
  const c = Object.assign({}, country);
  const m = map;

  if (!c.postalCodeLabel) c.postalCodeLabel = country.code === 'US' ? 'Zip Code' : 'Postal Code';
  if (!c.stateLabel) c.stateLabel = 'State';

  m[c.code] = c;
  return m;
}, {});

export const UsStateOptions = [{ value: '', label: '' }].concat(CountryMap.US.states.map(state => ({ label: state.name, value: state.code })));
export const UsStateShortOptions = UsStateOptions.map(s => ({ value: s.value, label: s.value }));

export const CcMonthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => ({ label: month, value: month }));

export const getCcYearOptions = (selectedYear) => {
  const year = new Date().getFullYear();
  const yearOptions = [];
  if (selectedYear < year) yearOptions.push({ label: selectedYear, value: selectedYear });
  for (let i = 0; i < 15; i++) yearOptions.push({ label: year + i, value: year + i });

  return yearOptions;
};

export const CVV_PAYMENT_THRESHOLD = 100;

export const planTypes = [{id: 'Essentials', preLoaded : 0,maxfull: 5},{id: 'Pro', preLoaded : 2, maxfull: 5},{id: 'Elite', preLoaded : 9, maxfull:9}]


// will remove after done with team section , as of now api's are not working
export const teamRes = {
  "teamMembers": [
      {
          "id": 1647318,
          "firstName": " Karthika",
          "lastName": " Periasamy",
          "phone": "1234567891",
          "username": "karthika.periasamy@propstream.com",
          "emailFriendlyFrom": " Karthika  Periasamy",
          "replyToEmail": " karthika.periasamy@propstream.com",
          "businessName": " Karthika Periasamy",
          "businessFirstName": " Karthika",
          "businessLastName": " Periasamy",
          "businessEmail": " karthika.periasamy@propstream.com",
          "marketingContactLimit": 5000,
          "marketingListPropertyLimit": 5000,
          "favoritePropertyLimit": 100,
          "newFeatureLabelEnabled": false,
          "purchaseNotificationEnabled": false,
          "spendingReportEnabled": false,
          "smsEnabled": false,
          "clearLogo": false,
          "countySearchEnabled": true,
          "dev": false,
          "billingPaymentMethod": false,
          "purchasePaymentMethod": false,
          "fullAccount": true,
          "pabEnabled": false,
          "overdueAccount": false,
          "customPostcardRate": false,
          "listManagementEnabled": true,
          "subUser": 0,
          "scoutUser": false,
          "termsAgreementRequired": false,
          "appPaymentMethodRequired": false,
          "paymentMethodId": 2029277,
          "purchasePaymentMethodId": 2029277,
          "type": "FULL",
          "accountPlanMrc": {
              "id": 1117,
              "amount": 99.00,
              "prorateAmount": 0.00,
              "billDate": "2025-03-27T00:00:00",
              "quantity": 10000,
              "prorateDays": 15,
              "trialDays": 7,
              "annual": false,
              "prorated": true,
              "upsellEnabled": false,
              "trialAvailable": false,
              "type": "SUBSCRIPTION",
              "userType": "FULL"
          },
          "fullName": " Karthika  Periasamy",
          "businessFullName": " Karthika  Periasamy",
          "smsRegistered": false,
          "name": " Karthika  Periasamy"
      },
      {
          "id": 1921805,
          "firstName": "First Name",
          "lastName": "Last Name",
          "phone": "1234567891",
          "username": "firstname@gmail.com",
          "emailFriendlyFrom": null,
          "replyToEmail": null,
          "businessName": null,
          "businessFirstName": null,
          "businessLastName": null,
          "businessEmail": null,
          "marketingContactLimit": 5000,
          "marketingListPropertyLimit": 5000,
          "favoritePropertyLimit": 100,
          "newFeatureLabelEnabled": true,
          "purchaseNotificationEnabled": false,
          "spendingReportEnabled": false,
          "smsEnabled": false,
          "clearLogo": false,
          "countySearchEnabled": true,
          "dev": false,
          "billingPaymentMethod": false,
          "purchasePaymentMethod": false,
          "fullAccount": true,
          "pabEnabled": false,
          "overdueAccount": false,
          "customPostcardRate": false,
          "listManagementEnabled": true,
          "subUser": 1,
          "scoutUser": false,
          "termsAgreementRequired": true,
          "appPaymentMethodRequired": false,
          "paymentMethodId": null,
          "purchasePaymentMethodId": null,
          "type": "TEAM",
          "accountPlanMrc": {
              "id": 2089,
              "amount": 20.00,
              "prorateAmount": 10.71,
              "billDate": "2025-03-27T00:00:00",
              "quantity": 0,
              "prorateDays": 15,
              "trialDays": 0,
              "annual": false,
              "prorated": true,
              "upsellEnabled": true,
              "trialAvailable": false,
              "type": "ACTIVATION_CODE",
              "userType": "TEAM"
          },
          "fullName": "First Name Last Name",
          "businessFullName": "null null",
          "smsRegistered": false,
          "name": "First Name Last Name"
      },
      {
          "id": 1921736,
          "firstName": "test1",
          "lastName": "test1",
          "phone": "1234567891",
          "username": "test1@gmail.com",
          "emailFriendlyFrom": null,
          "replyToEmail": null,
          "businessName": null,
          "businessFirstName": null,
          "businessLastName": null,
          "businessEmail": null,
          "marketingContactLimit": 5000,
          "marketingListPropertyLimit": 5000,
          "favoritePropertyLimit": 100,
          "newFeatureLabelEnabled": false,
          "purchaseNotificationEnabled": false,
          "spendingReportEnabled": false,
          "smsEnabled": false,
          "clearLogo": false,
          "countySearchEnabled": true,
          "dev": false,
          "billingPaymentMethod": false,
          "purchasePaymentMethod": false,
          "fullAccount": true,
          "pabEnabled": false,
          "overdueAccount": false,
          "customPostcardRate": false,
          "listManagementEnabled": true,
          "subUser": 1,
          "scoutUser": false,
          "termsAgreementRequired": false,
          "appPaymentMethodRequired": false,
          "paymentMethodId": 2029277,
          "purchasePaymentMethodId": 2029277,
          "type": "TEAM_FULL",
          "accountPlanMrc": {
              "id": 3089,
              "amount": 35.00,
              "prorateAmount": 18.75,
              "billDate": "2025-03-27T00:00:00",
              "quantity": 0,
              "prorateDays": 15,
              "trialDays": 0,
              "annual": false,
              "prorated": true,
              "upsellEnabled": true,
              "trialAvailable": false,
              "type": "SUB_USER",
              "userType": "TEAM_FULL"
          },
          "fullName": "test1 test1",
          "businessFullName": "null null",
          "smsRegistered": false,
          "name": "test1 test1"
      },
      {
          "id": 1921673,
          "firstName": "testPro",
          "lastName": "testPro",
          "phone": "1234567891",
          "username": "testPro@gmail.com",
          "emailFriendlyFrom": null,
          "replyToEmail": null,
          "businessName": null,
          "businessFirstName": null,
          "businessLastName": null,
          "businessEmail": null,
          "marketingContactLimit": 5000,
          "marketingListPropertyLimit": 5000,
          "favoritePropertyLimit": 100,
          "newFeatureLabelEnabled": false,
          "purchaseNotificationEnabled": false,
          "spendingReportEnabled": false,
          "smsEnabled": false,
          "clearLogo": false,
          "countySearchEnabled": true,
          "dev": false,
          "billingPaymentMethod": false,
          "purchasePaymentMethod": false,
          "fullAccount": true,
          "pabEnabled": false,
          "overdueAccount": false,
          "customPostcardRate": false,
          "listManagementEnabled": true,
          "subUser": 1,
          "scoutUser": false,
          "termsAgreementRequired": true,
          "appPaymentMethodRequired": false,
          "paymentMethodId": 2029277,
          "purchasePaymentMethodId": 2029277,
          "type": "TEAM",
          "accountPlanMrc": {
              "id": 2089,
              "amount": 20.00,
              "prorateAmount": 10.71,
              "billDate": "2025-03-27T00:00:00",
              "quantity": 0,
              "prorateDays": 15,
              "trialDays": 0,
              "annual": false,
              "prorated": true,
              "upsellEnabled": true,
              "trialAvailable": false,
              "type": "ACTIVATION_CODE",
              "userType": "TEAM"
          },
          "fullName": "testPro testPro",
          "businessFullName": "null null",
          "smsRegistered": false,
          "name": "testPro testPro"
      },
      {
          "id": 1921637,
          "firstName": "testScout",
          "lastName": "testScout",
          "phone": "1234567891",
          "username": "testScout@gmail.com",
          "emailFriendlyFrom": null,
          "replyToEmail": null,
          "businessName": null,
          "businessFirstName": null,
          "businessLastName": null,
          "businessEmail": null,
          "marketingContactLimit": 5000,
          "marketingListPropertyLimit": 5000,
          "favoritePropertyLimit": 100,
          "newFeatureLabelEnabled": false,
          "purchaseNotificationEnabled": false,
          "spendingReportEnabled": false,
          "smsEnabled": false,
          "clearLogo": false,
          "countySearchEnabled": true,
          "dev": false,
          "billingPaymentMethod": false,
          "purchasePaymentMethod": false,
          "fullAccount": true,
          "pabEnabled": false,
          "overdueAccount": false,
          "customPostcardRate": false,
          "listManagementEnabled": true,
          "subUser": 1,
          "scoutUser": true,
          "termsAgreementRequired": true,
          "appPaymentMethodRequired": false,
          "paymentMethodId": 2029277,
          "purchasePaymentMethodId": 2029277,
          "type": "SCOUT",
          "accountPlanMrc": {
              "id": 2461,
              "amount": 0.00,
              "prorateAmount": 0.00,
              "billDate": "2025-03-27T00:00:00",
              "quantity": 0,
              "prorateDays": 15,
              "trialDays": 0,
              "annual": false,
              "prorated": false,
              "upsellEnabled": true,
              "trialAvailable": false,
              "type": "SUB_USER",
              "userType": "SCOUT"
          },
          "fullName": "testScout testScout",
          "businessFullName": "null null",
          "smsRegistered": false,
          "name": "testScout testScout"
       }
    ]
}

export const billingDashboardMock = {
  "userId": "number",

  "mainSubscription": "296",
  "mainSubscriptionChangesPending" : false,
  "mainSubscriptionExpiringSoon" : false,
  "mainSubscriptionRenews": "03/25/2025",
  "mainSubscriptionExpires": "03/25/2025",

  "leadAutomator": "52",
  "leadAutomatorFAUserExpiring": "3",
  "leadAutomatorChangesPending" : false,
  "leadAutomatorExpiringSoon" : true,
  "leadAutomatorExpires": "01/25/2025",
  "leadAutomatorRenews": "01/25/2025",

  "teamMember": "21",
  "teamMemberChangesPending" : false,
  "teamMemberExpiringSoon" : true,
  "teamMemberFAUserExpiring": "1",
  "teamMemberRenews": "01/25/2025",
  "teamMemberExpires": "01/25/2025",

}

export const walletListMock = {
  "userId": 1863766,
  "wallets": [
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test1",
      "cardNumber": "3700********0002",
      "cardExp": "12/2029",
      "cardType": "AMEX",
      "paymentMethodId": 2029406,
      "billingStreetAddress": "123 Main Street",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": false,
      "purchasePaymentMethod": false,
      "defaultPaymentMethods": [
        {
          "fullName": "Michael2Multiple CC test Account2",
          "userType": "TEAM",
          "paymentMethodType": "ALL",
          "defaultRecurring": true
        }
      ]
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test3",
      "cardNumber": "6011********0012",
      "cardExp": "12/2029",
      "cardType": "DISCOVER",
      "paymentMethodId": 2029407,
      "billingStreetAddress": "123 Main Street",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": false,
      "purchasePaymentMethod": false,
      "defaultPaymentMethods": [
        {
          "fullName": "Michael1Multiple CC test Account1",
          "userType": "TEAM_FULL",
          "paymentMethodType": "ALL",
          "defaultRecurring": true
        },
        {
          "fullName": "Michael1Multiple CC test Account2",
          "userType": "TEAM_FULL",
          "paymentMethodType": "ALL",
          "defaultRecurring": true
        }
      ]
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test4",
      "cardNumber": "5424********0015",
      "cardExp": "12/2023",
      "cardType": "MC",
      "paymentMethodId": 2029408,
      "billingStreetAddress": "123 Main Street",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": false,
      "purchasePaymentMethod": false,
      "defaultPaymentMethods": []
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test5",
      "cardNumber": "4007********0027",
      "cardExp": "12/2029",
      "cardType": "VISA",
      "paymentMethodId": 2029409,
      "billingStreetAddress": "123 Main Street",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": false,
      "purchasePaymentMethod": false,
      "defaultPaymentMethods": []
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test6",
      "cardNumber": "4012********8888",
      "cardExp": "12/2029",
      "cardType": "VISA",
      "paymentMethodId": 2029410,
      "billingStreetAddress": "123 Main Street",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": false,
      "purchasePaymentMethod": false,
      "defaultPaymentMethods": [
        {
          "fullName": "Michael2Multiple CC test Account2",
          "userType": "TEAM",
          "paymentMethodType": "Purchase",
          "defaultRecurring": false
        }
      ]
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test Card1",
      "cardNumber": "4111********1111",
      "cardExp": "12/2030",
      "cardType": "VISA",
      "paymentMethodId": 2029484,
      "billingStreetAddress": "123 Main Street11111",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": true,
      "purchasePaymentMethod": true,
      "defaultPaymentMethods": [
        {
          "fullName": "MichaelMultiple CC test Account",
          "userType": "FULL",
          "paymentMethodType": "ALL",
          "defaultRecurring": true
        }
      ]
    },
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "firstName": "Michael",
      "lastName": "Multiple CC test Account",
      "cardName": "Test Card1",
      "cardNumber": "4111********1111",
      "cardExp": "12/2030",
      "cardType": "VISA",
      "paymentMethodId": 2029484,
      "billingStreetAddress": "123 Main Street11111",
      "billingStreetAddress2": "",
      "billingCity": "Los Vegas",
      "billingState": "CA",
      "billingZip": "41111",
      "billingCountry": "US",
      "billingPaymentMethod": true,
      "purchasePaymentMethod": true,
      "defaultPaymentMethods": [
        {
          "fullName": "MichaelMultiple CC test Account",
          "userType": "FULL",
          "paymentMethodType": "ALL",
          "defaultRecurring": true
        }
      ]
    }
  ]
}

export const defaultPaymentSettingList = {
  "userId": 1863766,
  "wallets": [
    {
      "userId": 1863766,
      "accountMrcId": 1945151,
      "userType": "FULL",
      "fullName": "MichaelMultiple CC test Account",
      "defaultSubsPaymentMethodId": 2029406,
      "defaultInAppPaymentMethodId": 2029407
    },
    {
      "userId": 1921838,
      "accountMrcId": 2001761,
      "userType": "TEAM_FULL",
      "fullName": "Michael1Multiple CC test Account1",
      "defaultSubsPaymentMethodId": 2029407,
      "defaultInAppPaymentMethodId": 2029407
    },
    {
      "userId": 1921837,
      "accountMrcId": 2001760,
      "userType": "TEAM",
      "fullName": "Michael2Multiple CC test Account2",
      "defaultSubsPaymentMethodId": 2029406,
      "defaultInAppPaymentMethodId": 2029410
    },
    {
      "userId": 1921839,
      "accountMrcId": 2001761,
      "userType": "TEAM_FULL",
      "fullName": "In-App Purchases",
      "defaultSubsPaymentMethodId": '',
      "defaultInAppPaymentMethodId": 2029407
    },
    {
      "userId": 1921810,
      "accountMrcId": 2001760,
      "userType": "TEAM",
      "fullName": "Only Subscriptions",
      "defaultSubsPaymentMethodId": 2029406,
      "defaultInAppPaymentMethodId": ''
    }

  ]
}



export const getBillingList = {
    "userId": 1863766,
    "history": [
      {
        "id": 12044783,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "AMEX",
        "cardNumber": "0000"
      },

      // productName: 'Subscription',
      // purchasedBy: 'S. Dhanasekaran',
      // cardExpire: '03/26/2024',
      // tag: 'Monthly',
      // userList: 'PropStream Pro Team Members (3)',
      // cardNumber: '- 0559',
      // priceTotal: '289.00',
      // priceList: ['199.00', '90']

      {
        "id": 12044781,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "Recurring",
        "price": 0,
        "term": "Monthly",
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 12044777,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 12044757,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 12030091,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12030089,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12030087,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12030085,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "Recurring",
        "price": 0,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12026701,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12026699,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12026695,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 12026693,
        "productName": "PropStream",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "Recurring",
        "price": -5,
        "term": "Monthly",
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 1544367,
        "productName": "Document Image",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": 5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 1544366,
        "productName": "Skip Trace",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": 0,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 1544364,
        "productName": "Document Image",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": 5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122088,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122088,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122088,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122088,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122087,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122087,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122087,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 14122087,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/05/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "AMEX",
        "cardNumber": "0000"
      },
      {
        "id": 1542453,
        "productName": "Skip Trace",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "InApp",
        "price": 0
      },
      {
        "id": 14110179,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/02/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110179,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/02/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110179,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/02/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110179,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/02/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110163,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110163,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110163,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110163,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110162,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110162,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110162,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110162,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 1542442,
        "productName": "Skip Trace",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": 0,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110128,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110128,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110128,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110128,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110127,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110127,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110127,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110127,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110126,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110126,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110126,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110126,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110125,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael1 Multiple CC test Account1",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110125,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael2 Multiple CC test Account2",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110125,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michael Multiple CC test Account",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      },
      {
        "id": 14110125,
        "productName": "Prepaid Credits",
        "purchasedBy": "Michaell3 Multiple CC test Accountl3",
        "purchasedDate": "08/01/2024",
        "purchaseType": "InApp",
        "price": -5,
        "cardType": "VISA",
        "cardNumber": "0000"
      }
    ]

}