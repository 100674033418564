import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import classNames from "classnames";
import Modal from "components/base/Modal";
import { loadDefaultPaymentSettingList , updateDefaultPaymentSettingList , loadWalletList } from "data/user/actions";
import { selectCardList, selectDefaultPaymentSetting, selectUserId } from "data/user/selectors";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditCardDropdown from "../components/CreditCardDropdown";
import css from "../style.scss";
import defaultPaymentModalCss from './EditDefaultPayment.scss';

const EditDefaultPaymentPopup = (props) => {
  const cardList = useSelector(selectCardList);
  const defaultPaymentSettingList = useSelector(selectDefaultPaymentSetting);
  const userID = useSelector(selectUserId);
  const dispatch = useDispatch();

  // ✅ Filter `defaultPaymentSettingList` by `props.data?.userId` if `props.selectedCard` is true
  const filteredPaymentList = useMemo(() => {
    if (props.selectedCard) {
      return defaultPaymentSettingList.filter((payment) => payment?.userId === props.data?.userId);
    }
    return defaultPaymentSettingList;
  }, [props.selectedCard, defaultPaymentSettingList, props.data?.userId]);

  // ✅ State to track updates
  const [updatedPaymentList, setUpdatedPaymentList] = useState([]);

  useEffect(() => {
    if (filteredPaymentList.length > 0) {
      setUpdatedPaymentList(filteredPaymentList);
    }
  }, [filteredPaymentList]);

  // ✅ Fetch payment settings if `selectedCard` is not available
  useEffect(() => {
    if (userID) {
      dispatch(loadDefaultPaymentSettingList(userID));
    }
  }, [userID, dispatch]);

  // ✅ Handle card selection change
  const handleCardChange = (selectedOption, defaultPayment, type) => {
    setUpdatedPaymentList((prevList) =>
      prevList.map((payment) =>
        payment.userId === defaultPayment.userId
          ? { ...payment, [type]: selectedOption.paymentMethodId }
          : payment
      )
    );
  };

  const update = async () => {
    const payload = {
       userId : userID,
       wallets: updatedPaymentList
    }
    try {
          const response = await updateDefaultPaymentSettingList(payload);
          if (response.success) {
            dispatch(loadWalletList(userID))
            props.closePopup()
          } else {
            console.error(`Update wallet failed:`, response.error);
          }
        } catch (error) {
          console.error("Unexpected error:", error);
        }
  }

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="MakeDefaultPaymentPopup"
      caption=""
      width="852px"
      padding="0px"
    >
      <div className={classNames(css.clsModalContentBody, "modal-body")}>
        <div className={css.clsModalHeader}>
          <h2 className={css.clsModalTitle}>Default Payment Settings</h2>
          <h5 className={css.clsModalSubTitle}>
            Set the default payment methods for yourself and your team members.
            Note that you cannot set defaults for subscriptions for any team members that are included
            with your Pro or Elite tier plan.
          </h5>
        </div>

        <div className={css.clsModalBody}>
          {updatedPaymentList && updatedPaymentList?.length > 0 ? (
            updatedPaymentList?.map((defaultPayment, index) => (
              <div key={index} className="row justify-content-center w-100 pb-5">
                <div className="col-lg-11 px-0">
                  <h2 className={classNames(defaultPaymentModalCss.accountHolder, defaultPaymentModalCss.mb_16)}>
                    {defaultPayment?.userType === 'FULL' ? 'Account Holder ' : 'Team Member '} - {defaultPayment?.fullName}
                  </h2>

                  <div className={classNames("row align-items-center")}>
                    {/* Default for Subscriptions */}
                    <div className="col-lg-6">
                      {defaultPayment?.defaultSubsPaymentMethodId && (
                        <div className={classNames(css.clsFormGroup)}>
                          <label className={classNames(css.clsFormLabel)}>Default for Subscriptions</label>
                          <CreditCardDropdown
                            tag={false}
                            options={cardList}
                            selectedId={defaultPayment?.defaultSubsPaymentMethodId}
                            onChange={(selectedOption) => handleCardChange(selectedOption, defaultPayment, 'defaultSubsPaymentMethodId')}
                          />
                        </div>
                      )}
                    </div>

                    {/* Default for In-App Purchases */}
                    <div className={classNames("col-lg-6")}>
                      {defaultPayment?.userType !== 'TEAM' && defaultPayment?.defaultInAppPaymentMethodId && (
                        <div className={classNames(css.clsFormGroup)}>
                          <label className={classNames(css.clsFormLabel)}> Default for In-App Purchases</label>
                          <CreditCardDropdown
                            tag={false}
                            options={cardList}
                            selectedId={defaultPayment?.defaultInAppPaymentMethodId}
                            onChange={(selectedOption) => handleCardChange(selectedOption, defaultPayment, 'defaultInAppPaymentMethodId')}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading payment settings...</p>
          )}
        </div>

        <div className={classNames(css.clsModalFooter)}>
          <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary)} onClick={props.closePopup}>
            Cancel
          </button>
          <button className={classNames(css.btn_lg, css.clsBtnOrng)} onClick={update}>Save</button>
        </div>
      </div>
    </Modal>
  );
};
const registrationId = getPopupRegistration(EditDefaultPaymentPopup);
EditDefaultPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditDefaultPaymentPopup.close = () => closePopup({ popup: registrationId });

export default EditDefaultPaymentPopup;
