import threeDot from 'assets/images/account-page/three-dot-icon.svg';
import userIcon from 'assets/images/account-page/user-icon.png';
import classNames from "classnames";
import { getTeamMembers, selectedTeamMember } from "data/user/actions";
import { selectCanCreateFullAccess, selectShowFinishBuildingYourTeam, selectTeamMembers, selectUserId , selectUserPlan , selectTeamCounts } from "data/user/selectors";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { titleCaseFormat } from '../../../utils/validation';
import EditTeamProfilePopup from "../modals/EditTeamProfile";
import RemoveTeamMemberPopup from "../modals/RemoveTeamMember";
import css from '../style.scss';
import { CenterIconInfographic, EliteInfographic, EssentialInfographic, ProInfographic } from './TeamSectionInfographic';
import bannerImg from 'assets/images/account-page/team-card-banner.png'


const TeamSection = (props) => {

  const [tooltip, setTooltip] = useState(false)
  const [pending, setpending] = useState(false)

  const userID = useSelector(selectUserId)
  const selectTeamMembersSection = useSelector(selectTeamMembers)
  const showFinishBuildingYourTeam = useSelector(selectShowFinishBuildingYourTeam);
  
  const dispatch = useDispatch();



  useEffect(() => {
    if (userID) {
      dispatch(getTeamMembers(userID))
    }
  }, [dispatch,userID])

  const tooltipToggle = () => {
    props.history.push(`/accountnew/team`)
    // setTooltip((prev) => !prev);
  };


  if(pending) {
      return null
  }

  return (
    <div className={classNames(css.clsCard, css.clsTeamCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <h4 className={classNames(css.clsCardTitle)}>Team</h4>
        <div className={css.clsInfoPopup}>
        
          {showFinishBuildingYourTeam &&
            <a href="javascript:;" onClick={tooltipToggle} className={classNames(css.clsCardTxt, css.text_danger)}>
              Finish Building Your Team
              {/* <span className={css.clsInfoIcon}><img src={infoDanger} alt="info" /></span> */}
              {/* <div className={classNames(css.clsInfoPopupContent, css.clsInfoPopupRight, { [css.clsOpenPopup]: tooltip })}>
            <h5 className={css.clsInfoContentTitle}>Finish Building Your Team <span className={css.clsCloseIcon} onClick={tooltipToggle}>  &times;   </span></h5>
            <p className={css.clsInfoContentPara}>PropStream’s Pro Plan offers access for up to two team members, with the option to add three more for an additional charge per spot. The Elite Plan provides access for up to nine members via prepaid slots.</p>
          </div> */}
            </a>
          }
        </div>
      </div>

      <div className={css.clsCardBody}>
        {selectTeamMembersSection && selectTeamMembersSection.length > 0 ? (
        <TeamSectionData history={props.history} selectTeamMembersSection={selectTeamMembersSection} props={props} />
        ) : (
          <NoTeamMember history={props.history} />
        )}
      </div>
    </div>
  );
};


const TeamSectionData = ({history,selectTeamMembersSection,props}) => {

  const counts = useSelector(selectTeamCounts);
  return (
    <>
    <TeamHeader history={history} counts={counts} />
   <div className={classNames(css.pt_20, "table-responsive", css.responsivetable)}>
   <TeamMemberTable members={selectTeamMembersSection} props={props} />
 </div>
   </>
  )
}

const TeamMemberRow = ({ member, index, openIndex, setOpenIndex, props }) => {
  const isOpen = openIndex === index;

  const [isExpiringSoonHovered, setIsExpiringSoonHovered] = useState(false);

  const [isShowTag, setShowTag] = useState(index === 2);

  return (
    <tr key={index}>
      <td>
        <div className={classNames(css.d_flex, css.align_items_center)} style={{ width: "100%", position: "relative" }}>
          <img src={userIcon} alt="User" width={30} height={30} className={css.mr_5px} />

          <div className={css.clsTableTxtAlrt} style={{ flexGrow: 1, overflow: "hidden", position: "relative" }}>
            <span
              className={css.clsTableTxt}
              style={{
                display: "inline-block",
                maxWidth: isExpiringSoonHovered ? "20px" : (isShowTag  ? "100px" : "205px"),
                minWidth: isExpiringSoonHovered ? "20px" : (isShowTag  ? "100px" : "205px"),
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            // 
            >
              {member.fullName}
            </span>
            {isShowTag && (
              <ExpiringSoonButton onHoverChange={setIsExpiringSoonHovered} />
            )}
          </div>
        </div>
      </td>
      <td className={classNames(css.verticalAlignMiddle)}>
        {member.scoutUser ? titleCaseFormat(member.type) : "Full Access"}
      </td>

      {member.scoutUser ? (<td className={classNames(css.verticalAlignMiddle)}>
        {"N/A"}
      </td>) : (
        <td>
          <div>
            {/* {member.limitUsed} */}
            200,000
          </div>
          <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>
            /200,000
            {/* {member.limitTotal} */}
          </div>
        </td>
      )}

      {member.scoutUser ? (<td className={classNames(css.verticalAlignMiddle)}>
        {"N/A"}
      </td>) : (
        <td>
          <div>
            {/* {member.saveExportUsed} */}
            $5,666.70
          </div>
          <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>
            {/* /{member.saveExportTotal} */}
            /Unlimited
          </div>
        </td>
      )}



      <td>
        <TeamMemberDropdown
          member={member}
          isOpen={isOpen}
          toggleDropdown={() => setOpenIndex(isOpen ? null : index)}
          closeDropdown={() => setOpenIndex(null)}
          props={props}
        />
      </td>
    </tr>
  );
};

const TeamMemberDropdown = ({ member, isOpen, toggleDropdown, closeDropdown, props }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const upgradeToFullAccess = useSelector(selectCanCreateFullAccess);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);




  const handleItemClick = (action) => {

    let memberId = member.id

    dispatch(selectedTeamMember(member))

    switch (action) {
      case 'edit':
      case 'set':
        props.EditTeamProfilePopup()
        break;
      case 'upgrade':
        props.history.push(`/accountnew/reviewteam/${memberId}`)
        break;
      case 'downgrade':
        props.history.push(`/accountnew/teamdowngrade/${memberId}/review`)
        break;
      case 'remove':
        props.RemoveTeamMemberPopup()
        // props.history.push(`/accountnew/removeteam/${memberId}/review`)
        break;
      case 'review':
        props.history.push(`/accountnew/reviewpendingchanges/${memberId}/review`)
        break;
      default:
        break;
    }

    closeDropdown();
  };




  return (
    <div className={css.clsTableActionBtn} ref={dropdownRef}>
      <div className={classNames(css.clsActionIcon, { [css.active]: isOpen })} onClick={toggleDropdown}>
        <ReactSVG src={threeDot} />
      </div>
      {isOpen && (
        <ul className={classNames(css.clsActionDropdown, { [css.clsOpenPopup]: isOpen })}>
          <li className={css.listStyleNone}>
            <a href="javascript:;" onClick={() => handleItemClick('edit')}>Edit Profile</a>
          </li>
          {member.scoutUser && upgradeToFullAccess ? (
            <li className={css.listStyleNone} >
              <a href="javascript:;" onClick={() => handleItemClick('upgrade')}>Upgrade to Full-Access</a>
            </li>
          ) : (
            null
          )
          }
          {!member.scoutUser ? (
            <li className={css.listStyleNone}>
              <a href="javascript:;" onClick={() => handleItemClick('downgrade')}>Downgrade to Scout</a>
            </li>
          ) : null
          }
          <li className={css.listStyleNone}>
            <a href="javascript:;" onClick={() => handleItemClick('remove')}>Remove Team Member</a>
          </li>
          <li className={css.listStyleNone}>
            <a href="javascript:;" onClick={() => handleItemClick('review')}>Review Pending Changes</a>
          </li>
        </ul>
      )}
    </div>
  );
};

const TeamMemberTable = ({ members, props }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <table className={classNames(css.clsTable, css.clsTableBgGray, "table")}>
      <thead>
        <tr>
          <th>Member</th>
          <th>Type</th>
          <th>Exports</th>
          <th>Spending</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {members && members?.map((member, index) => (
          <TeamMemberRow
            key={index}
            index={index}
            member={member}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            props={props}
          />
        ))}
      </tbody>
    </table>
  );
};

const TeamHeader = ({ history, counts }) => {

  const plan =  useSelector(selectUserPlan);

  const renderPlanComponent = () => {

    const fullAccessCount = counts.fullAccessCount;

    switch (plan) {
      case "Essentials":
        return <Essential fullAccessCount={fullAccessCount} className={css.mr_5px} />;
      case "Pro":
        return <Pro fullAccessCount={fullAccessCount} className={css.mr_5px} />;
      case "Elite":
        return <Elite fullAccessCount={fullAccessCount} className={css.mr_5px} />;
      default:
        return null; // If no plan matches, render nothing
    }
  };

  return (
    <div className={classNames(css.d_flex, css.align_items_center, css.justify_content_between)}>
      <ul className={classNames(css.clsTeamList, css.unstyled)}>
        <li className={css.clsBorderRight}>
          <div className={css.clsTeamContent}>
            {/* Based on account type need to show*/}
            {renderPlanComponent()}
            <div className={css.clsTeamContentTxt}>
              <h5 className={css.clsTeamContent1}>
                Full-Access
              </h5>
              <h4 className={css.clsTeamContent2}>{counts.fullAccessCount}</h4>
            </div>
          </div>
        </li>
        <li>
          <div className={css.clsTeamContent}>
            <div className={css.clsTeamContentTxt}>
              <h5 className={css.clsTeamContent1}>
                Scouts
              </h5>
              <h4 className={css.clsTeamContent2}>{counts.scoutUserCount}</h4>
            </div>
          </div>
        </li>
      </ul>
      <div className={css.clearfix}>
        <button className={classNames(css.btn_primary, css.clsBtnPrimaryGreen)} onClick={() => history.push(`/accountnew/team`)}>Add To Team</button>
      </div>
    </div>
  )
}

const NoTeamMember = ({ history }) => {
  return (
    <div className={css.clsTeamCardBanner}>
      <div className={css.clsBannerImg}>
        <img src={bannerImg} alt='banner' />
      </div>
      <div className={css.clsBannerContent}>
        <div className={classNames(css.pb_35, "clearfix w-100")}>
          <p className={css.clsBannerPara}>Add team members to enhance collaboration and your property search strategies.</p>
        </div>
        <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)} onClick={() => history.push(`/accountnew/team`)}>Create Your Team Today</button>
      </div>
    </div>
  )
}


const Essential = ({ fullAccessCount }) => (

  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >


    {CenterIconInfographic.map(({ d, index }) => (
      <path key={index} d={d} fill="#128292" />
    ))}


    {EssentialInfographic.map(({ id, d, index }) => (
      <>
        <mask id={id} fill="white">
          <path d={d} />
        </mask>
        <path key={index} d={d} stroke={index <= fullAccessCount ? "#128292" : "#BCC1C7"} strokeWidth="3" mask={`url(#${id})`} fill={fullAccessCount === 0 ? "white" : index <= fullAccessCount ? "#128292" : "white"} />
      </>

    ))}
  </svg>
);

const Pro = ({ fullAccessCount }) => (
  <svg
    width={65}
    height={65}
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {CenterIconInfographic.map(({ d, index }) => (
      <path key={index} d={d} fill="#128292" />
    ))}



    {ProInfographic.map(({ id, d, index }) => (
      <>
        <mask id={id} fill="white">
          <path d={d} />
        </mask>
        <path key={'Prosvg' + index} d={d} stroke={index <= Math.max(fullAccessCount, 2) ? "#128292" : "#BCC1C7"} strokeWidth="3" mask={`url(#${id})`} fill={fullAccessCount === 0 ? "white" : index <= fullAccessCount ? "#128292" : "white"} />
      </>

    ))}
  </svg>
);

const Elite = ({ fullAccessCount }) => (
  <svg
    width={64}
    height={65}
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >


    {CenterIconInfographic.map(({ d, index }) => (
      <path key={index} d={d} fill="#128292" />
    ))}


    {EliteInfographic.map(({ id, d, index }) => (
      <>
        <mask id={id} fill="white">
          <path d={d} />
        </mask>
        <path key={id} d={d} stroke={"#128292"} strokeWidth="3" mask={`url(#${id})`} fill={fullAccessCount === 0 ? "white" : index <= fullAccessCount ? "#128292" : "white"} />
      </>

    ))}

  </svg>
);

const ExpiringSoonButton = ({ onHoverChange }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange(true); // ✅ Send true to parent
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange(false); // ✅ Send false to parent
  };

  return (
    <span
      className={classNames(css.clsBtnSm, {
        [css.clsBtnDanger]: !isHovered,
        [css.clsBtnPrimaryGreen]: isHovered,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? "Review Pending Changes" : "Expiring Soon"}
    </span>
  );
};



export default withRouter(connect(null, {
  EditTeamProfilePopup: EditTeamProfilePopup.open,
  RemoveTeamMemberPopup: RemoveTeamMemberPopup.open
})(TeamSection));
