import { fromJS } from 'immutable';

import API from 'config/constants/API';
import { SAVE_PROPERTY_SUCCESS, EXPORT_GROUP_PROPERTIES_SUCCESS } from 'data/property';
import { SAVE_LISTINGS_SUCCESS } from 'data/search';
import { getUserLogoUrl } from 'utils/brand';

import * as ActionType from './actions';
import { planTypes } from './constants';
import { inferCardType } from '../../utils/payment';


export const defaultPaymentMethod = {
  paymentMethodId: '',
  cardNumber: '',
  cardCode: '',
  maskedCardNumber: '',
  cardName: '',
  cardExpMonth: 1,
  cardExpYear: new Date().getFullYear(),
  billingStreetAddress: '',
  billingStreetAddress2: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: 'US',
};

const defaultProfile = {
  firstName: '',
  lastName: '',
  phone: '',
  phone2: '',
  username: '',
  password: '',
  businessFirstName: '',
  businessLastName: '',
  businessName: '',
  businessUrl: '',
  businessEmail: '',
  businessPhone: '',
  emailFriendlyFrom: '',
  replyToEmail: '',
  businessStreetAddress: '',
  businessStreetAddress2: '',
  businessCity: '',
  businessState: '',
  businessZip: '',
  logoUrl: '',
  logoHash: Date.now(),
  favoritePropertyAddLimit: 0,
  marketingListAddLimit: 0,
  appendContactRate: 0,
  countySearchEnabled: false,
  newFeatureLabelEnabled: false,
  fullAccount: false,
  overdueAccount: false,
  activeSavedPropertyLimit: 0,
  assignedSavedPropertyLimit: 0,
  periodSavedPropertyQuantity: 0,
  remainingSavedProperties: 0,
  activeExportPropertyLimit: 0,
  assignedExportPropertyLimit: 0,
  periodExportPropertyQuantity: 0,
  remainingExportProperties: 0,
  nextCycleDate: null,
  mrcTotal: 0,
  csPhone: '',
  csEmail: '',
  pabEnabled: false,
  voicemailUrl: null,
  postcardUrl: null,
  contactAppendUrl: null,
  paymentMethods: [],
  catalogProducts: [],
  planMrcs: [],
  listManagementEnabled: false,
  subscriptionPlanMrcId: null,
  listManagementPlanMrcId: null,
  remainingPrepaidCredit: 0,
  ...defaultPaymentMethod,
};
const defaultLeadAutomator = {
  userId: '',
  planMrcs: [],
  accountPlanMrcs: [],
  paymentMethods: [],
  monitoredProperties: 0,
  monitoredPropertiesUsed: 0,
  monitoredPropertiesRemaining: 0,
  percentOfUsedProperties: ""
};


export const billingSection = {
  dashboard:{},
  wallet: [],
  cardList: [],
  defaultPaymentSettingList: [],
  billingHistory: [],
  isLoading: false,
  error: null,
};

const defaultState = fromJS({
  duplicateSession: false,    // Whether the user is already logged in in a different session. When true they're presented with the option of logging out the other person.
  loggedOut: false,           // Whether the user has been logged out, either manually or automatically.
  logoutMessage: null,        // Logout message to be displayed to user. When null they are simply redirected to the Login screen.
  isLoading: false,
  loading: false,             // Separate "loading" variable; the "isLoading" variable triggers a full screen loading spin wheel, and this one is intended for specific element loading status. (E.g. just buttons instead of whole window.)
  isAuthed: false,            // User has been authenticated
  alertLoading: false,
  offerLoading: false,
  profile: defaultProfile,
  reports: [],
  permissions: [],
  offers: [],
  pages: [],
  alerts: [],
  layouts: [],
  users: null,
  transactions: null,
  chatVisible: false,
  initialPath: null,          // Initial path / route to load.
  marketingProfile: {},
  prepaidCredits: null,
  userProfile: {},
  leadAutomator: defaultLeadAutomator,
  teams: {
    teamMembers: [],
    selectedTeamMember: {},
    teamCounts: { fullAccessCount: 0, scoutUserCount: 0 },
    canCreate: false,
    isPaidUser:false,
    error:null
  },
  plan: "Pro",
  billing: billingSection
});

const getProfile = profile => ({
  ...defaultProfile,
  ...profile,
  logoUrl: `${API.BASE_URL}${profile.logoUrl}`,
  cardNumberMasked: profile.cardNumber,
  cardNumber: '',
  planMrcs: profile.accountPlanMrcs || [],
});

function saveProfile(state) {
  return state.merge({
    isLoading: true,
  });
}
// reducer function
export default function reducer(state = defaultState, action) {
  const { response } = action;

  switch (action.type) {
    case ActionType.AUTHENTICATE:
      return state.set('isLoading', true);

    case ActionType.AUTHENTICATION_SUCCESS:
    case ActionType.SAVE_PRELIMINARY_DATA_SUCCESS: {
      const { duplicateSession, profile, reports, alerts, layouts = [], permissions, pages, analysisFields, teamMembers = [], authToken, cardCodeRequired, initialPath } = response;

      if (authToken) window.appAuthToken = authToken;

      return state.mergeDeep({
        duplicateSession: duplicateSession,
        isLoading: false,
        loading: false,
        isAuthed: true,
        profile: getProfile(profile),
        reports: reports,
        alerts: alerts,
        layouts: layouts || [],
        permissions: permissions,
        pages: pages,
        analysisFields: analysisFields,
        cardCodeRequired,
        initialPath,
        users: (teamMembers || []).map(u => ({ ...u, logoUrl: getUserLogoUrl(u.id) })),
      });
    }

    case ActionType.AUTHENTICATION_ERROR:
      return state.set('isLoading', false);

    case ActionType.SET_LOGOUT:
      return state.merge({ loggedOut: true, logoutMessage: action.message });

    case ActionType.SET_CHAT_VISIBLE:
      return state.merge({ chatVisible: action.visible });

    case ActionType.UPGRADE_ACCOUNT:
    case ActionType.GET_USERS:
    case ActionType.SAVE_USER:
    case ActionType.DELETE_USER:
    case ActionType.GET_TRANSACTIONS:
    case ActionType.SAVE_LAYOUT:
    case ActionType.DELETE_LAYOUT:
    case ActionType.SAVE_TAG:
    case ActionType.DELETE_TAG:
    case ActionType.SAVE_PRELIMINARY_DATA:
    case ActionType.SAVE_PREPAID_CREDIT:
      return state.set('loading', true);
      
    // ID23-133, update remainingPrepaidCredit value and return the new state
    case ActionType.GET_PREPAID_CREDIT_SUCCESS:
        let remainingPrepaidCredit = action.response
        if (!remainingPrepaidCredit) {
          remainingPrepaidCredit = 0;
        }
        return state.set('loading', false).setIn(['profile', 'remainingPrepaidCredit'], remainingPrepaidCredit);

    case ActionType.UPGRADE_ACCOUNT_ERROR:
    case ActionType.GET_USERS_ERROR:
    case ActionType.SAVE_USER_ERROR:
    case ActionType.DELETE_USER_ERROR:
    case ActionType.GET_TRANSACTIONS_ERROR:
    case ActionType.SAVE_LAYOUT_ERROR:
    case ActionType.DELETE_LAYOUT_ERROR:
    case ActionType.SAVE_TAG_ERROR:
    case ActionType.DELETE_TAG_ERROR:
    case ActionType.SAVE_PRELIMINARY_DATA_ERROR:
    case ActionType.SAVE_PREPAID_CREDIT_ERROR:
      return state.set('loading', false);

    case ActionType.SAVE_TAG_SUCCESS:
    case ActionType.DELETE_TAG_SUCCESS:
      return state.set('loading', false);

    case ActionType.GET_USERS_SUCCESS:
    case ActionType.SAVE_USER_SUCCESS:
    case ActionType.DELETE_USER_SUCCESS:
      return state.merge({ loading: false, users: (response || []).map(u => ({ ...u, logoUrl: getUserLogoUrl(u.id) })) });

    case ActionType.GET_TRANSACTIONS_SUCCESS:
      return state.merge({ loading: false, transactions: response || [] });

    case ActionType.SAVE_LAYOUT_SUCCESS:
    case ActionType.DELETE_LAYOUT_SUCCESS:
      return state.merge({ loading: false, layouts: response.layouts });

    case ActionType.DISABLE_ALERT:
      return state.set('alertLoading', true);

    case ActionType.DISABLE_ALERT_SUCCESS:
    case ActionType.DISABLE_ALERT_ERROR:
      return state.set('alertLoading', false);

    case ActionType.SAVE_PROFILE:
      return saveProfile(state);

    case ActionType.SAVE_PROFILE_SUCCESS:
      return state.merge({
        isLoading: false,
        profile: getProfile(response),
      });

    case ActionType.SAVE_PREPAID_CREDIT_SUCCESS:
      return state.set('loading', false).setIn(['profile', 'remainingPrepaidCredit'], response.remainingPrepaidCredit);

    case ActionType.SAVE_PROFILE_ERROR:
      return state.merge({
        isLoading: false,
      });

    case ActionType.REMOVE_ERROR:
      return state.merge({
        profile: {
          success: null,
          message: null,
        },
      });

    case ActionType.UPGRADE_ACCOUNT_SUCCESS:
    case EXPORT_GROUP_PROPERTIES_SUCCESS:
    case SAVE_PROPERTY_SUCCESS:
    case SAVE_LISTINGS_SUCCESS: {
      const { accountPlanMrcs } = response;
      const fields = 'fullAccount,overdueAccount,nextCycleDate,mrcTotal,paymentMethods,listManagementEnabled,subscriptionPlanMrcId,listManagementPlanMrcId,accountPlanMrcs,activeSavedPropertyLimit,assignedSavedPropertyLimit,periodSavedPropertyQuantity,remainingSavedProperties,activeExportPropertyLimit,assignedExportPropertyLimit,periodExportPropertyQuantity,remainingExportProperties,planMrcs'.split(',');
      const profile = fields.reduce((r, f) => ({ ...r, ...(response[f] === undefined ? {} : { [f]: response[f] }) }), {});

      if (accountPlanMrcs) profile.planMrcs = accountPlanMrcs;

      return state
        .set('loading', false)
        .mergeIn(['profile'], profile);
    }

    case ActionType.LOAD_OFFERS:
    case ActionType.SAVE_OFFER:
    case ActionType.DELETE_OFFER:
    case ActionType.LOAD_OFFER_REPORT:
      return state.set('offerLoading', true);

    case ActionType.LOAD_OFFERS_ERROR:
    case ActionType.SAVE_OFFER_ERROR:
    case ActionType.DELETE_OFFER_ERROR:
    case ActionType.LOAD_OFFER_REPORT_ERROR:
      return state.set('offerLoading', false);

    case ActionType.LOAD_OFFERS_SUCCESS:
    case ActionType.SAVE_OFFER_SUCCESS:
    case ActionType.DELETE_OFFER_SUCCESS:
      return state.set('offerLoading', false).merge({ offers: response || [] });

    case ActionType.LOAD_OFFER_REPORT_SUCCESS:
      return state;

    // marketing profile 
    case ActionType.LOAD_MARKETING_PROFILE_SUCCESS:
      return state.set('loading', false).set('marketingProfile', fromJS(action.payload));

   case ActionType.LOAD_USER_PROFILE_SUCCESS:
    return state.set('loading', false).set('userProfile', fromJS(action.payload));

   case ActionType.LOAD_PREPAID_CREDITS_SUCCESS:
    return state.set('loading', false).set('prepaidCredits', fromJS(action.payload));

   case ActionType.LOAD_PLAN_SUCCESS:
    return state.set('loading', false).set('planDetails', fromJS(action.payload));

   case ActionType.LOAD_LEAD_AUTOMATOR_SUCCESS:
    return state.set('loading', false).set('leadAutomator', fromJS(action.payload));

        case ActionType.LOAD_TEAM_MEMBERS_SUCCESS:
      const teamMembers = fromJS(action.payload || []);

      // Compute teamCounts
      const teamCounts = teamMembers.reduce(
        (acc, member) => {
          if (member.get("scoutUser")) {
            acc.scoutUserCount += 1;
          } else {
            acc.fullAccessCount += 1;
          }
          return acc;
        },
        { fullAccessCount: 0, scoutUserCount: 0 }
      );

             // Get user plan from state
            const userPlan = state.get("plan");
            // Find the corresponding plan
            const plan = planTypes.find((p) => p.id === userPlan);
            const canCreate = teamCounts.fullAccessCount < plan.maxfull;
            const isPaidUser = teamCounts.fullAccessCount >= plan.preLoaded &&  teamCounts.fullAccessCount < plan.maxfull

      return state
      .set("loading", false)
      .setIn(["teams", "teamMembers"], fromJS(teamMembers))
      .setIn(["teams", "teamCounts"], fromJS(teamCounts))
      .setIn(["teams", "error"], null)
      .setIn(["teams", "canCreate"], fromJS(canCreate))
      .setIn(["teams", "isPaidUser"], fromJS(isPaidUser));

    case ActionType.SET_SELECTED_TEAM_MEMBER:
      return state.set('loading', false).setIn(['teams', 'selectedTeamMember'], fromJS(action.payload));
    
      // billing 
        case ActionType.LOAD_BILLING_DASHBOARD_SUCCESS:
          return state.set('loading', false).setIn(['billing', 'dashboard'], fromJS(action.payload));

      case ActionType.LOAD_PAYMENT_LIST_SUCCESS:
      const walletlist = action.payload || [];
        const cardList = walletlist?.map((data) => {
          return {
            label: data?.cardName,
            cardEnding: data?.cardNumber?.slice(-4),
            expDate: data?.cardExp,
            paymentMethodId: data?.paymentMethodId,
            cardTypeDetails: inferCardType(data?.cardNumber || '')
          };
        }) 

        return state
        .set("loading", false)
        .setIn(["billing", "wallet"], fromJS(walletlist))
        .setIn(["billing", "cardList"], fromJS(cardList))


        case ActionType.LOAD_DEFAULT_PAYMENT_SETTING_SUCCESS:
          return state.set('loading', false).setIn(['billing', 'defaultPaymentSettingList'], fromJS(action.payload));
        
        case ActionType.LOAD_WALLET_HISTORY_LIST_SUCCESS:
          return state.set('loading', false).setIn(['billing', 'billingHistory'], fromJS(action.payload));
          
      
          

      // case ActionType.ADD_PAYMENT_METHOD_SUCCESS:
      //   return state.set('isLoading', false).update('wallet', (wallet) => wallet.push(fromJS(action.payload)));
      
      // case ActionType.UPDATE_PAYMENT_METHOD_SUCCESS:
      //   return state.set('isLoading', false).update('wallet', (wallet) =>
      //     wallet.map((method) => (method.get('id') === action.payload.paymentMethodId ? fromJS(action.payload) : method))
      //   );
      
      // case ActionType.DELETE_PAYMENT_METHOD_SUCCESS:
      //   return state.set('isLoading', false).update('wallet', (wallet) =>
      //     wallet.filter((method) => method.get('id') !== action.payload.paymentMethodId)
      //   );

         case  ActionType.LOAD_PAYMENT_LIST:
          case ActionType.ADD_PAYMENT_METHOD:
          case ActionType.UPDATE_PAYMENT_METHOD:
          case ActionType.DELETE_PAYMENT_METHOD:
         return state.set('isLoading', true);
      

         
         case ActionType.LOAD_BILLING_DASHBOARD_ERROR:
          case ActionType.LOAD_PAYMENT_LIST_ERROR:
          case ActionType.ADD_PAYMENT_METHOD_ERROR:
          case ActionType.UPDATE_PAYMENT_METHOD_ERROR:
          case ActionType.DELETE_PAYMENT_METHOD_ERROR:
          case ActionType.LOAD_DEFAULT_PAYMENT_SETTING_ERROR:
         return state.set('isLoading', false);

    default:
      return state;
  }
}
