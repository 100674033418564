import classNames from "classnames";
import React from "react";
import css from "./style.scss";
import { Field, ErrorMessage, useFormikContext } from "formik";

const FormikText = ({
  name,
  label,
  type = "text",
  placeholder,
  required,
  errors,
  touched,
  disabled = false,
  maxLength, // Optional, default is unlimited
  validationPattern, // Default: No restriction (user can type anything)
}) => {
  const { setFieldValue } = useFormikContext(); // Access Formik context

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Apply validation pattern only if provided
    if (validationPattern) {
      value = value.replace(validationPattern, "");
    }

    // Enforce max length only if it's set
    if (maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    setFieldValue(name, value); // Update Formik state
  };

  return (
    <div className={css.clsFormGroup}>
      <label
        className={classNames(
          css.clsFormLabel,
          errors[name] && touched[name] && css.text_danger
        )}
      >
        <>
          {label}
          {required && <span className={css.text_primary}>*</span>}
        </>
      </label>

      <Field
        name={name}
        type={type}
        className={classNames(
          css.clsFormControl,
          errors[name] && touched[name] && css.clsBorderDanger,
          { [css.disabled]: disabled }
        )}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength || undefined} // Apply maxLength only if provided
        onChange={handleInputChange} // Prevent invalid input if pattern exists
      />
      <ErrorMessage
        name={name}
        component="p"
        className={classNames(css.clsFormErrorText, css.text_danger)}
      />
    </div>
  );
};

export default FormikText;
