import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChangeDefaultPaymentPopup from "../../modals/ChangeDefaultPayment";
import RemovePaymentPopup from "../../modals/RemovePayment";
import css from "../../style.scss";
import AddPaymentPopup from "../../modals/AddPayment";
import RemoveDefaultPaymentPopup from "../../modals/RemoveDefaultPayment";
import { inferCardType } from 'utils/payment';
import EditDefaultPaymentPopup from "../../modals/EditDefaultPayment";
import { getLastFourDigits, isCardExpired } from "../../../../utils/validation";
import { maskCardNumber } from "../../../../utils/payment";


const WalletListItem = ({
  count,
  payment,
  isDefault,
  defaultPaymentMethods,
  openRemovePaymentPopup,
  openRemoveDefaultPaymentPopup,
  openEditDefaultPaymentPopup,
  openEditPaymentPopup,
  history,
  openChangeDefaultPaymentPopup
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggleExpand = () => setExpanded(!expanded);

  const handleOpenRemovePayment = (payment) => openRemovePaymentPopup(payment);
  const handleOpenRemoveDefaultPayment = (payment) => openRemoveDefaultPaymentPopup(payment);
  const handleOpenChangeDefaultPayment = (payment) => openEditDefaultPaymentPopup(); // {selectedCard: true, data : payment}
  const handleOpenEditPaymentPopup = (payment) => openEditPaymentPopup({ type: 'edit', data: payment });

  const handleMakeDefaultPaymentPopup = (payment) => openChangeDefaultPaymentPopup(payment);
  
  const { name, image } = inferCardType(payment?.cardNumber || '');


  const getImages = (cardNumber) => {
     const {  image } = inferCardType(cardNumber);
     return image
  }

  return (
    <div key={payment.id} className={css.clsWalletCard}>
      <div className={css.clsWalletCardHeader}>
        <div className={classNames(css.d_flex, css.align_items_center, "row")}>
          <div className="col-md-3">
            <div className={classNames(css.d_flex, css.align_items_center)}>
              <img src={image} alt={name} />
              <div className={classNames(css.clearfix, css.pl_15)}>
                <h4 className={isCardExpired(payment?.cardExp) ? `${css.text_danger} ${css.clsTextLine1}` : css.clsTextLine1}>Ending in {getLastFourDigits(payment?.cardNumber)}</h4>
                <h4 className={isCardExpired(payment?.cardExp) ?  `${css.text_danger} ${css.clsTextLine2}`: css.clsTextLine2}>Exp. {payment?.cardExp}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h4 className={css.clsTextLine1}>{payment?.firstName} {payment?.lastName}</h4>
          </div>
          <div className="col-md-4">
            <div
              className={classNames(
                css.d_flex,
                css.align_items_center,
                css.justify_content_end
              )}>
                {isCardExpired(payment?.cardExp) ? (
                    <span className={classNames(css.clsTextPrimaryDanger, css.mr_25, css.clsBtnDanger)}>
                    Expired
                </span>
                ) : 
                 ( payment?.defaultPaymentMethods?.length > 0 && (
                    <span className={classNames(css.clsTextPrimaryGreen, css.mr_25, css.clsBtnDanger)}>
                      Default -  {payment?.defaultPaymentMethods?.length > 1 ? 'Multiple' : payment?.defaultPaymentMethods[0]?.fullName}
                    </span>
                  )
                )}
             
              <a href="javascript:;" onClick={handleToggleExpand}>
                <img
                  src={
                    expanded
                      ? "/assets/images/select-up-arrow.svg"
                      : "/assets/images/select-down-arrow.svg"
                  }
                  alt={expanded ? "upArrow" : "downArrow"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {expanded ? (
        <div className={`${css.clsWalletCardCollapseBody}`}>
          <div className={css.clsWalletCardBody}>
            <div className={classNames(css.d_flex, "row")}>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>NAME ON CARD</h4>
                <h4 className={css.clsTextLine2}>{payment?.cardName}</h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>CARD NUMBER</h4>
                <h4 className={css.clsTextLine2}>{maskCardNumber(payment?.cardNumber)}</h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>BILLING ADDRESS</h4>
                <h4 className={css.clsTextLine2}>
                  {[payment?.billingStreetAddress, 
                  payment?.billingStreetAddress2, 
                  `${payment?.billingCity || ''} ${payment?.billingState || ''} ${payment?.billingZip || ''}`]
                  .filter(Boolean) // Remove empty or undefined values
                  .map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>DEFAULT PAYMENT METHOD</h4>
                {payment?.defaultPaymentMethods && payment?.defaultPaymentMethods.length > 0 ? payment?.defaultPaymentMethods?.map((defaultPaymentMethod, index) => {
                  return (
                    <h4 key={index} className={css.clsTextLine2}>
                      {defaultPaymentMethod?.fullName} - {defaultPaymentMethod?.paymentMethodType}
                    </h4>
                  );
                }): <h4 className={css.clsTextLine2}>None</h4>}
              </div>
            </div>
          </div>

          <div className={css.clsWalletCardFooter}>
            <span />
            <div className={css.clearfix}>
              <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.text_link_hover,
                  css.ml_8
                )}
                onClick={() => handleOpenChangeDefaultPayment(payment)}
              >
                 {/* onClick={() => payment?.defaultPaymentMethods?.length > 0 ? handleOpenChangeDefaultPayment(payment) : handleMakeDefaultPaymentPopup(payment)} */}
                 {'Change Defaults'}
                {/* {payment?.defaultPaymentMethods?.length > 0 ? 'Change Defaults' : 'Make Defaults'} */}
              </button>
              <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.text_link_hover,
                  css.ml_8
                )}
                onClick={() => handleOpenEditPaymentPopup(payment)}
              >
                Edit
              </button>
              {count > 1 && (
                <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.text_link_hover,
                  css.ml_8
                )}
                onClick={() => payment?.defaultPaymentMethods?.length > 0 ? handleOpenRemoveDefaultPayment(payment) : handleOpenRemovePayment(payment)}
              >
                Remove
              </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(
  connect(null, {
    openRemovePaymentPopup: RemovePaymentPopup.open,
    openRemoveDefaultPaymentPopup: RemoveDefaultPaymentPopup.open,
    openChangeDefaultPaymentPopup: ChangeDefaultPaymentPopup.open,
    openEditDefaultPaymentPopup: EditDefaultPaymentPopup.open,
    openEditPaymentPopup: AddPaymentPopup.open
  })(WalletListItem)
);
