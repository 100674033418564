import React from "react";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import Modal from "components/base/Modal";
import css from "../style.scss";
import classNames from "classnames";
import { getLastFourDigits } from "../../../utils/validation";
import { useEffect } from "react";
import { getCardImageByCode, inferCardType } from "../../../utils/payment";
import { deleteWalletAPI, loadWalletList } from "data/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "data/user/selectors";

const RemovePaymentPopup = props => {

  const userID = useSelector(selectUserId);

  const dispatch = useDispatch();

  const { image, name } = inferCardType(props?.cardNumber || "");

  const handleClose = () => {
    props.closePopup();
  };

  const deletePayment = async () => {
    try {
      const response = await deleteWalletAPI(userID,props.paymentMethodId);
      if (response.success) {
        console.log(`delete wallet successful:`, response);
        dispatch(loadWalletList(userID));
        handleClose();
      } else {
        console.error(`delete wallet failed:`, response.error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="RemovePaymentPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}>
      <div
        className={classNames(
          css.modal_lg,
          "modal-dialog modal-lg m-0 modal-dialog-scrollable"
        )}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
          <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Remove Payment Method?</h2>
              <h5 className={css.clsModalSubTitle}>
                Are You Sure You Want To Remove This Payment Method?
              </h5>
            </div>

            <div className={css.clsModalBody}>
              <p className={css.clsBodyTxtLine1}>
                This Card Will Be Removed From Your Account
              </p>
              <div className={css.clsPayCard}>
                <img src={image} className="mr-3" alt={name} />
                <div className="clearfix">
                  <h5 className={css.clsMediaTxt1}>
                    Ending in {getLastFourDigits(props?.cardNumber)}
                  </h5>
                  <p className={css.clsMediaTxt2}>Exp. {props?.cardExp}</p>
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button
                className={classNames(
                  css.btn_lg,
                  css.btn_primary,
                  css.text_primary
                )}
                onClick={handleClose}
              >
                {" "}
                Cancel
              </button>
              <button
                className={classNames(css.btn_lg, css.clsBtnOrng)}
                onClick={deletePayment}
              >
                {" "}
                Remove Card{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(RemovePaymentPopup);
RemovePaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
RemovePaymentPopup.close = () => closePopup({ popup: registrationId });

export default RemovePaymentPopup;
