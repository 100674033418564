

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import React from 'react';
import CreditCardDropdown from '../components/CreditCardDropdown';
import css from "../style.scss";


const ReloadPrepaidCreditsPopup = (props) => {

  const handleClose = () => {
    props.closePopup();
  };

  return (
    <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccPrepaidCreditsModal)} isOpen uniqId="ReloadPrepaidCreditsPopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>

      {/* Reload Prepaid Credits */}
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
          <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Reload Prepaid Credits</h2>
              <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle,)}>
                Prepaid Credits can be used for Marketing, Skip Tracing, and Document Purchases.
              </h5>
            </div>

            <div className={classNames(css.clsModalBody)}>
              <div className={classNames(css.clsModalBodyInner, "clearfix w-100")}>
                <h2 className={css.clsModalTitle2}>$579.90</h2>
                <h5 className={css.clsModalSubTitle3}>
                  Current Balance as of April 26, 1:58PM PST
                </h5>

                <div className={classNames("clearfix")}>
                  <div className={classNames(css.clsFormGroup, css.pl_16, css.pr_16, css.pt_32)}>
                    <label className={classNames(css.clsFormLabel)}> Payment Method</label>

                    <CreditCardDropdown
                      tag={false}
                      options={[{ label: "A", cardEnding: '0059', expDate: '05/2026' }, { label: "B", cardEnding: '0100', expDate: '65/2027' }, { label: "C", cardEnding: '0099', expDate: '05/2028' }]}
                    />

                    {/* <div className={css.clsDropdownCard}>
                        <div className={classNames(css.clsPayCard, css.clsPayCardFull, css.align_items_center, css.justify_content_between, "flex-fill")}>
                          <div className={classNames(css.d_flex, css.align_items_center)}>
                            <img src={visaCard} className="mr-3" alt="" />
                            <div className="clearfix">
                              <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                              <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                            </div>
                          </div> 
                        </div>
                        <a className={css.pl_16} href="javascript:;">
                          <img src={downArrow} alt="" />
                        </a>
                      </div> */}
                  </div>
                  <div className={classNames(css.clsFormGroup, css.pl_16, css.pr_16, css.pt_32)}>
                    <label className={classNames(css.clsFormLabel)}>Amount
                      <span className={classNames(css.text_gray, css.text_m)}> (Min. $5)</span>
                    </label>
                    <div className={classNames(css.clsFormGroup)}>
                      <input
                        name="amount"
                        type="text"
                        className={classNames(
                          css.clsFormControl,
                        )}
                        placeholder="Enter Amount"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
              <button className={classNames(css.btn_lg, css.clsBtnOrng)}>  Purchase Credits </button>
            </div>
          </div>
        </div>
      </div>


    </Modal >


  );
};


const registrationId = getPopupRegistration(ReloadPrepaidCreditsPopup);
ReloadPrepaidCreditsPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
ReloadPrepaidCreditsPopup.close = () => closePopup({ popup: registrationId });

export default ReloadPrepaidCreditsPopup;







