import React from 'react';
import { ReactSVG } from 'react-svg';
import ReloadIcon from '../../../assets/images/reload.svg';
import css from './errorDisplay.scss';

const ErrorDisplay = ({ errorFetched, onReload }) => {
    return (
        <div className={css.errorContainer}>
            <div className={css.errorHeader}>
                <h4 className={css.errorTitle}>Unable to Load Module</h4>
                <button className={css.errorBtn} onClick={onReload}>
                    <ReactSVG src={ReloadIcon} className={css.errorIcon} />
                    <span className={css.errorBtnLabel}>Reload Module</span>
                </button>
            </div>
            <div className={css.errorBody}>
                {errorFetched > 1 &&
                    <div className={css.errorText}>
                        If you continue to encounter this error, please contact us at [VENDOR_SUPPORT_NUMBER].
                    </div>
                }
            </div>
        </div>
    )
}

export default ErrorDisplay;
