export const CenterIconInfographic = [
  {
    index: 1,
    d: 'M36.5268 33.9488C35.8041 33.9488 35.1072 34.065 34.449 34.2844C36.088 36.2977 37.0689 38.8658 37.0689 41.6666C37.0663 41.8266 37.0767 42.0383 37.056 42.1957H42.0249C42.8058 42.2022 43.3891 41.5131 43.3155 40.7374C43.3155 36.9946 40.2697 33.9488 36.527 33.9488H36.5268Z'
  },
  {
    index: 2,
    d: 'M36.5265 33.1356C39.0561 33.1356 41.108 31.0707 41.108 28.5411C41.108 26.0115 39.0559 23.9466 36.5265 23.9466C35.5328 23.9466 34.6165 24.2692 33.855 24.7984C33.8808 24.9533 33.8808 25.1081 33.8808 25.263C33.8808 27.1215 33.2871 28.8509 32.2676 30.2705C32.9645 31.9483 34.6036 33.1356 36.5265 33.1356Z'
  },
  {
    index: 3,
    d: 'M25.2469 32.4298C20.1541 32.4298 16.01 36.5738 16.01 41.6667C15.9171 42.4565 16.4785 43.2064 17.3006 43.2H33.1918C34.0153 43.2064 34.5753 42.4579 34.4824 41.6667C34.4824 36.5738 30.3394 32.4298 25.2469 32.4298Z'
  },
  {
    index: 4,
    d: 'M25.2465 31.3147C28.5866 31.3147 31.3034 28.598 31.3034 25.2579C31.3034 21.9178 28.5867 19.2 25.2465 19.2C21.9064 19.2 19.1897 21.9167 19.1897 25.2579C19.1897 28.598 21.9064 31.3147 25.2465 31.3147Z'
  },
  {
    index: 5,
    d: 'M44.9992 35.1615C44.4496 35.1615 43.9197 35.2498 43.4192 35.4166C44.6655 36.9475 45.4113 38.9003 45.4113 41.0299C45.4094 41.1516 45.4173 41.3126 45.4015 41.4323H49.1798C49.7736 41.4372 50.2171 40.9132 50.1612 40.3234C50.1612 37.4775 47.8452 35.1615 44.9993 35.1615H44.9992Z'
  },

  {
    index: 6,
    d: 'M44.9989 34.5431C46.9224 34.5431 48.4826 32.9729 48.4826 31.0495C48.4826 29.126 46.9223 27.5559 44.9989 27.5559C44.2433 27.5559 43.5466 27.8012 42.9676 28.2036C42.9872 28.3213 42.9872 28.4391 42.9872 28.5569C42.9872 29.97 42.5358 31.285 41.7605 32.3645C42.2904 33.6402 43.5367 34.5431 44.9989 34.5431Z'
  },

]
export const EssentialInfographic = [
  {
    index: 1,
    id: 'path-7-inside-1_5422_5011', 
    d: 'M32.9046 3.33201C32.9601 1.56557 34.4413 0.16213 36.1933 0.393713C41.4584 1.08963 46.4855 3.08793 50.8091 6.22926C55.1328 9.37059 58.5868 13.5341 60.8756 18.3264C61.6373 19.9212 60.7602 21.7635 59.0974 22.3622C57.4345 22.9608 55.6188 22.0859 54.8175 20.5107C52.99 16.9182 50.3325 13.7938 47.0473 11.407C43.7622 9.02016 39.9694 7.45821 35.988 6.83032C34.2423 6.555 32.8491 5.09845 32.9046 3.33201Z'
  },
  {
    index: 2,
    id: 'path-8-inside-2_5422_5011', 
    d: 'M59.6565 24.0829C61.3536 23.5898 63.146 24.5647 63.4672 26.3026C64.4323 31.525 64.0853 36.9236 62.4338 42.0063C60.7823 47.0891 57.8899 51.6606 54.0394 55.3183C52.7581 56.5355 50.7349 56.2707 49.6517 54.8743C48.5685 53.4778 48.8395 51.4805 50.09 50.2317C52.9419 47.3835 55.0922 43.8906 56.347 40.0286C57.6019 36.1667 57.9153 32.0769 57.2822 28.0964C57.0046 26.351 57.9593 24.5759 59.6565 24.0829Z'
  },
  {
    index: 3,
    id: 'path-9-inside-3_5422_5011', 
    d: 'M48.188 55.9377C49.1814 57.3994 48.8081 59.4054 47.2545 60.2479C42.5859 62.7796 37.3443 64.1178 32 64.1178C26.6557 64.1178 21.4141 62.7796 16.7455 60.2479C15.192 59.4054 14.8186 57.3994 15.812 55.9377C16.8054 54.476 18.7886 54.1165 20.3628 54.9199C23.9528 56.7521 27.9393 57.7178 32 57.7178C36.0607 57.7178 40.0472 56.7521 43.6372 54.9199C45.2114 54.1165 47.1946 54.476 48.188 55.9377Z'
  },
  {
    index: 4,
    id: 'path-10-inside-4_5422_5011', 
    d: 'M14.3483 54.8743C13.2651 56.2707 11.2419 56.5355 9.96058 55.3183C6.11011 51.6606 3.21768 47.0891 1.56619 42.0063C-0.0853002 36.9236 -0.432342 31.525 0.532793 26.3026C0.853965 24.5647 2.6464 23.5898 4.34354 24.0829C6.04068 24.5759 6.99543 26.351 6.71781 28.0964C6.08466 32.0769 6.39814 36.1667 7.65295 40.0286C8.90777 43.8906 11.0581 47.3835 13.91 50.2317C15.1605 51.4805 15.4315 53.4778 14.3483 54.8743Z'
  },
  {
    index: 5,
    id: 'path-11-inside-5_5422_5011', 
    d: 'M4.90264 22.3621C3.23981 21.7635 2.36274 19.9212 3.12441 18.3264C5.41326 13.5341 8.86722 9.37057 13.1909 6.22925C17.5145 3.08792 22.5416 1.08962 27.8067 0.393711C29.5588 0.162129 31.0399 1.56557 31.0954 3.33201C31.1509 5.09845 29.7577 6.555 28.012 6.83031C24.0306 7.4582 20.2379 9.02016 16.9527 11.407C13.6676 13.7938 11.01 16.9182 9.18255 20.5107C8.38124 22.0859 6.56546 22.9608 4.90264 22.3621Z'
  }

]

export const ProInfographic = [
  {
    index: 1,
    id: 'path-7-inside-1_5422_4808', 
    d: 'M33.3587 3.33201C33.4142 1.56557 34.8954 0.16213 36.6474 0.393713C41.9125 1.08963 46.9396 3.08793 51.2632 6.22926C55.5869 9.37059 59.0409 13.5341 61.3297 18.3264C62.0914 19.9212 61.2143 21.7635 59.5515 22.3622C57.8886 22.9608 56.0729 22.0859 55.2716 20.5107C53.4441 16.9182 50.7866 13.7938 47.5014 11.407C44.2163 9.02016 40.4235 7.45821 36.4421 6.83032C34.6964 6.555 33.3032 5.09845 33.3587 3.33201Z'
  },
  {
    index: 2,
    id: 'path-8-inside-2_5422_4808', 
    d: 'M60.1106 24.0829C61.8077 23.5898 63.6001 24.5647 63.9213 26.3026C64.8864 31.525 64.5394 36.9236 62.8879 42.0063C61.2364 47.0891 58.344 51.6606 54.4935 55.3183C53.2122 56.5355 51.189 56.2707 50.1058 54.8743C49.0226 53.4778 49.2936 51.4805 50.5441 50.2317C53.396 47.3835 55.5463 43.8906 56.8011 40.0286C58.056 36.1667 58.3694 32.0769 57.7363 28.0964C57.4587 26.351 58.4134 24.5759 60.1106 24.0829Z'
  },
  {
    index: 3,
    id: 'path-9-inside-3_5422_4808', 
    d: 'M48.6421 55.9377C49.6355 57.3994 49.2622 59.4054 47.7086 60.2479C43.04 62.7796 37.7984 64.1178 32.4541 64.1178C27.1098 64.1178 21.8682 62.7796 17.1996 60.2479C15.6461 59.4054 15.2727 57.3994 16.2661 55.9377C17.2595 54.476 19.2427 54.1165 20.8169 54.9199C24.4069 56.7521 28.3934 57.7178 32.4541 57.7178C36.5148 57.7178 40.5013 56.7521 44.0913 54.9199C45.6655 54.1165 47.6487 54.476 48.6421 55.9377Z'
  },
  {
    index: 4,
    id: 'path-10-inside-4_5422_4808', 
    d: 'M14.8024 54.8743C13.7192 56.2707 11.696 56.5355 10.4147 55.3183C6.56421 51.6606 3.67179 47.0891 2.02029 42.0063C0.368801 36.9236 0.0217593 31.525 0.986895 26.3026C1.30807 24.5647 3.10051 23.5898 4.79764 24.0829C6.49478 24.5759 7.44953 26.351 7.17191 28.0964C6.53876 32.0769 6.85224 36.1667 8.10705 40.0286C9.36187 43.8906 11.5122 47.3835 14.3641 50.2317C15.6146 51.4805 15.8856 53.4778 14.8024 54.8743Z'
  },
  {
    index: 5,
    id: 'path-11-inside-5_5422_4808', 
    d: 'M5.35674 22.3621C3.69391 21.7635 2.81685 19.9212 3.57852 18.3264C5.86736 13.5341 9.32132 9.37057 13.645 6.22925C17.9686 3.08792 22.9957 1.08962 28.2608 0.393711C30.0129 0.162129 31.494 1.56557 31.5495 3.33201C31.605 5.09845 30.2118 6.555 28.4661 6.83031C24.4847 7.4582 20.692 9.02016 17.4068 11.407C14.1217 13.7938 11.4641 16.9182 9.63665 20.5107C8.83535 22.0859 7.01957 22.9608 5.35674 22.3621Z'
  }

]


export const EliteInfographic = [
  {
    index: 1,
    id: 'elite-1', 
    d: 'M33.3587 3.33201C33.4142 1.56557 34.8953 0.162122 36.6474 0.393696C41.2677 1.00437 45.6998 2.61736 49.6318 5.11914C51.1229 6.06785 51.3555 8.09495 50.2627 9.48388C49.1699 10.8728 47.1664 11.0932 45.652 10.1821C42.8268 8.48222 39.6991 7.34395 36.4421 6.83031C34.6964 6.555 33.3032 5.09845 33.3587 3.33201Z'
  },
  {
    index: 2,
    id: 'elite-2', 
    d: 'M51.6502 10.6481C52.8282 9.33061 54.8649 9.20751 56.0582 10.5111C59.205 13.9488 61.5634 18.0333 62.9674 22.4773C63.4998 24.1625 62.375 25.8648 60.6451 26.2264C58.9151 26.5879 57.2387 25.4689 56.6643 23.7975C55.5927 20.6793 53.9284 17.7969 51.7636 15.3099C50.6033 13.9769 50.4723 11.9656 51.6502 10.6481Z'
  },
  {
    index: 3,
    id: 'elite-3', 
    d: 'M60.9597 28.0101C62.7089 27.758 64.3482 28.9729 64.4244 30.7385C64.6253 35.3947 63.8064 40.0396 62.0255 44.3463C61.3501 45.9795 59.3942 46.5605 57.8366 45.7255C56.279 44.8905 55.714 42.9557 56.3484 41.3061C57.5318 38.2286 58.1097 34.9508 58.0499 31.6541C58.0179 29.8871 59.2104 28.2622 60.9597 28.0101Z'
  },
  {
    index: 4,
    id: 'elite-4', 
    d: 'M56.931 47.2941C58.433 48.2254 58.9079 50.2098 57.8313 51.6113C54.9923 55.3073 51.3794 58.3391 47.2468 60.4935C45.6796 61.3104 43.8078 60.4983 43.1514 58.8574C42.4949 57.2166 43.3058 55.3713 44.852 54.5154C47.7368 52.9186 50.2864 50.7791 52.3597 48.2153C53.471 46.8411 55.429 46.3628 56.931 47.2941Z'
  },
  {
    index: 5,
    id: 'elite-5', 
    d: 'M41.4493 59.477C42.0013 61.1559 41.0896 62.9813 39.364 63.3629C34.8135 64.3693 30.097 64.3694 25.5464 63.3634C23.8207 62.9819 22.9089 61.1566 23.4608 59.4776C24.0126 57.7987 25.8199 56.9063 27.5546 57.2446C30.7908 57.8756 34.1192 57.8755 37.3554 57.2443C39.09 56.9059 40.8974 57.7981 41.4493 59.477Z'
  },


  {
    index: 6,
    id: 'elite-6', 
    d: 'M21.7587 58.8582C21.1024 60.4991 19.2306 61.3114 17.6634 60.4945C13.5307 58.3405 9.91748 55.3089 7.07823 51.6131C6.00154 50.2116 6.4763 48.2272 7.97826 47.2958C9.48022 46.3644 11.4383 46.8426 12.5496 48.2167C14.6231 50.7803 17.1729 52.9197 20.0577 54.5163C21.604 55.3721 22.415 57.2173 21.7587 58.8582Z'
  },
  {
    index: 7,
    id: 'elite-7', 
    d: 'M7.07256 45.7273C5.51503 46.5624 3.55907 45.9815 2.88359 44.3484C1.1023 40.0418 0.283125 35.3969 0.48371 30.7408C0.559774 28.9751 2.199 27.7601 3.94826 28.0121C5.69752 28.264 6.89013 29.8889 6.85824 31.6559C6.79874 34.9526 7.37683 38.2304 8.56047 41.3078C9.19491 42.9573 8.6301 44.8921 7.07256 45.7273Z'
  },

  {
    index: 8,
    id: 'elite-8', 
    d: 'M4.28345 26.1299C2.55476 25.7625 1.43577 24.0563 1.97395 22.3729C3.39315 17.9338 5.76551 13.8574 8.92406 10.4305C10.1218 9.13097 12.1581 9.26104 13.3315 10.5825C14.505 11.9041 14.3671 13.9149 13.2022 15.244C11.0289 17.7235 9.35476 20.6003 8.27249 23.7148C7.69238 25.3841 6.01214 26.4974 4.28345 26.1299Z'
  },

  {
    index: 9,
    id: 'elite-9', 
    d: 'M14.6439 9.48512C13.551 8.09626 13.7835 6.06915 15.2745 5.12034C19.2064 2.61828 23.6384 1.00498 28.2586 0.393988C30.0107 0.162292 31.4918 1.56564 31.5475 3.33207C31.6031 5.09851 30.21 6.55515 28.4643 6.83059C25.2074 7.34445 22.0798 8.48294 19.2546 10.183C17.7404 11.0942 15.7369 10.874 14.6439 9.48512Z'
  },
 

]

