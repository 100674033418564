import React, { useState, useEffect } from "react";
import classNames from "classnames";
import css from '../style.scss';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import downFullArrow from '../../../assets/images/account-page/down-full-arrow.svg'
import downArrow from '../../../assets/images/account-page/down-arrow.svg'
import visaCard from '../../../assets/images/account-page/visa-card.svg'
import leadsmIcon from 'assets/images/account-page/lead-auto-sm-img.svg';
import CreditCardDropdown from "../components/CreditCardDropdown";


const ManageLeadAutomatorScreen = (props) => {


  const { type } = props.match.params;
  const [screenType, setscreenType] = useState(type);
 
  

    const [successful, setSuccessful] = useState(false); // Default selected plan
  

  const goToManageleadAutomator = () => {
    props.history.push(`/accountnew/leadautomator`);
  }

  // const goToManageLeadAutomator = (type) => {
  //   props.history.push(`/accountnew/manageleadautomator/${type}`);
  // };

  const goToAccount = () => {
    props.history.push(`/accountnew/landing`);
  }
  
  

  return (
    <>
      <div className={css.clsInnerMainBody}>
        <div>
           <div className="container">
            <div className={css.clsLAReviewPaySec}>
              <div className="row justify-content-center" style={{"padding-bottom": '13rem'}}>
                <div className="col-lg-4">
                 
                 {screenType === 'unsubscribe' ? ( <FromToSelectedTier 
                  fromtype="MONITORED PROPERTIES"
                  fromAmount="50,000"
                  totype="None"
                  toAmount=""
                  />) : (
                  <FromToSelectedTier 
                  fromtype="MONITORED PROPERTIES"
                  fromAmount="50,000"
                  totype="MONITORED PROPERTIES"
                  toAmount="120,000"
                  />
                 )}

                </div>
                {!successful ? (
                  <div className="col-lg-5">
                    {screenType === 'unsubscribe' ? (<ReviewConfirmUnsubscribe />) : (
                       <div className={css.clsLAReviewPayCard2}>
                       <h3 className={css.clsLAReviewHeading}>Review And Confirm Changes</h3>
                       <div className={css.clsLAReviewPayTimelineContainer}>
                         <div className={css.clsLAReviewPayTimeline}>
                           <div className={css.clsLATimelineItem}>
                             <div className={css.clsLATimelineDot}></div>
                             <div className={css.clsLATimelineContent}>
                               <div className={css.clsLATimelineHead}>
                                 <h4 className={css.clsLATimelineLabel}>Due Today</h4>
                                 <h5 className={css.clsLATimelinePrice}>$7</h5>
                               </div>
                               <p className={css.clsLATimelineDescription}>Team Member gains full access, 7 days until next billing cycle.</p>
                             </div>
                           </div>
   
                           <div className={css.clsLATimelineLine}></div>
   
                           <div className={css.clsLATimelineItem}>
                             <div className={css.clsLATimelineDot}></div>
                             <div className={css.clsLATimelineContent}>
                               <div className={css.clsLATimelineHead}>
                                 <h4 className={css.clsLATimelineLabel}>Starting June 17, 2024</h4>
                                 <h5 className={css.clsLATimelinePrice}>$30/month</h5>
                               </div>
                             </div>
                           </div>  
                         </div>
                       </div>
   
                       <div className={css.pb_48}>
                         <div className={css.clsLAReviewPMCard}>
                           <h4 className={css.clsLAReviewPMCardHeading}>Payment Method</h4>
                           <CreditCardDropdown
                              tag={false}
                              options={[{ label: "A", cardEnding: '0059', expDate: '05/2026' }, { label: "B", cardEnding: '0100', expDate: '65/2027' }, { label: "C", cardEnding: '0099', expDate: '05/2028' }]}
                            />
                         </div>
                       </div>
   
                       <div className={css.clsLAReviewContentPara}>
                         <p className={classNames(css.clsPara, css.mb_16)}>
                           <span className={css.text_b}>Starting on June 17, 2024 you’ll be charged $30.00 automatically every month until you cancel.</span> This does not include any other PropStream services you may be subscribed to. Your price may change as described in the <a target="_blank" href="https://www.propstream.com/terms-of-use" className={css.terms_conditions}>PropStream Terms and Conditions.</a>
                         </p>
                         <p className={css.clsPara}>
                           By clicking “Confirm Changes” you agree to the <a target="_blank" href="https://www.propstream.com/terms-of-use" className={css.terms_conditions}>PropStream Terms and Conditions.</a>
                         </p>
                       </div>
                     </div>
                    )}
                 
                </div>
                ) : (<SuccessfulFlow />)}
                

              </div>
            </div>
          </div>    
        </div>

        <div className={css.clsInnerFooter}>

        {!successful ? (
           <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)} onClick={() => goToManageleadAutomator()}> Back to Tiers </button>
        ) : (
          <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)} onClick={() =>goToAccount()}> Return to Account Home</button>
        ) }
          
          <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)} > Confirm Changes</button>
        </div>
      </div> 
    </>
  );
};



const FromToSelectedTier = (props) => {
  return (
    <div className={css.clsLAReviewPayCard}>
    <div className={css.clsLAReviewLeadAutoDetils}>
      <div className="d-flex align-items-center">
        <div className={css.clsLAReviewLeadAutoImgSmall}>
          <img src={leadsmIcon} alt="leadsmIcon" />
        </div>
        <h5 className={css.clsLAReviewLeadAutomatorTxt}>Lead Automator</h5>
      </div>
    </div>
    <div className={css.clsLAReviewTier}>
      <h4 className={css.clsTxtLine1}>{props?.fromtype}</h4>
      <h4 className={css.clsTxtLine2}>{props?.fromAmount}</h4>
    </div>
    <div className="text-center">
      <ReactSVG src={downFullArrow} className={classNames(css.mb_16, css.mt_16)} />
    </div>
    <div className={css.clsLAReviewTier}>
      <h4 className={css.clsTxtLine1}>{props?.totype}</h4>
      <h4 className={css.clsTxtLine2}>{props?.toAmount}</h4>
    </div>
  </div>
  )
}

const ReviewConfirmUnsubscribe = (props) => {
  return (
    <div className={css.clsLAReviewPayCard2}>
    <h3 className={css.clsLAReviewHeading}>Review And Confirm Changes</h3>
    <div className={css.clsLAReviewPayTimelineContainer}>
      <div className={css.clsLAReviewPayTimeline}>
        <div className={css.clsLATimelineItem}>
          <div className={css.clsLATimelineDot}></div>
          <div className={css.clsLATimelineContent}>
            <div className={css.clsLATimelineHead}>
              <h4 className={css.clsLATimelineLabel}>Today</h4>
            </div>
            <p className={css.clsLATimelineDescription}>7 days remaining in your Lead Automator plan</p>
          </div>
        </div>

        <div className={css.clsLATimelineLine2}></div>

        <div className={css.clsLATimelineItem}>
          <div className={css.clsLATimelineDot}></div>
          <div className={css.clsLATimelineContent}>
            <div className={css.clsLATimelineHead}>
              <h4 className={css.clsLATimelineLabel}>Starting June 17, 2024</h4>
            </div>
            <p className={css.clsLATimelineDescription}>Access to Lead Automator features ends. Resubscribe to Lead Automator at any time to regain access to Lead Automator features.</p>
          </div>
        </div> 
      </div>
    </div>

  

    <div className={css.clsLAReviewContentPara}>
      
      <p className={css.clsPara}>
        By clicking “Confirm Changes” you agree to the <a target="_blank" href="https://www.propstream.com/terms-of-use" className={css.terms_conditions}>PropStream Terms and Conditions.</a>
      </p>
    </div>
  </div>
  )
}


const SuccessfulFlow = () => {
  return (
    <div className="col-lg-5">
    <div className={css.clsLAReviewPayCard2}>
      <h3 className={css.clsLAReviewHeading}>Changes Successful</h3>
      <div className={css.clsLAReviewPayTimelineContainer}>
        <div className={css.clsLAReviewPayTimeline}>
          <div className={css.clsLATimelineItem}>
            <div className={css.clsLATimelineDot}></div>
            <div className={css.clsLATimelineContent}>
              <div className={css.clsLATimelineHead}>
                <h4 className={css.clsLATimelineLabel}>Paid Today</h4>
                <h5 className={css.clsLATimelinePrice}>$10.07</h5>
              </div>
              <p className={css.clsLATimelineDescription}>Lead Automator plan begins</p>
            </div>
          </div>

          <div className={css.clsLATimelineLine}></div>

          <div className={css.clsLATimelineItem}>
            <div className={css.clsLATimelineDot}></div>
            <div className={css.clsLATimelineContent}>
              <div className={css.clsLATimelineHead}>
                <h4 className={css.clsLATimelineLabel}>Starting June 17, 2024</h4>
                <h5 className={css.clsLATimelinePrice}>$47/month</h5>
              </div>
            </div>
          </div>  
        </div>
      </div>

    
    </div>
  </div>
  )
}


export default withRouter(ManageLeadAutomatorScreen);
