import groupIocn from 'assets/images/account-page/group-iocn.svg';
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { loadPlanSuccess } from "../../../data/user/actions";
import { selectPlanDetails, selectUserId } from '../../../data/user/selectors';
import css from '../style.scss';
import config from 'config';
import ErrorDisplay from '../components/ErrorDisplay';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const PlanSection = (props) => {
  const [errorFetched, setErrorFetched] = useState(-3);
  const userID = useSelector(selectUserId);
  const dispatch = useDispatch();
  const planDetails = useSelector(selectPlanDetails)?.toJS();

  useEffect(() => {
    if (userID) {
      getPlanDetails()
    }
  }, [userID])

  const getPlanDetails = () => {
    fetch(`${PRICING_UPDATE_URL}/ps/plan/${userID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.userId) {
          dispatch(loadPlanSuccess(data.accountPlanMrcs[0]))
          setErrorFetched(0);
        } else {
          setErrorFetched(prevCount => prevCount + 1);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorFetched(prevCount => prevCount + 1);
      });
  }
  const goToMonthlyPlan = () => {
    props.history.push(`/accountnew/plan`);
  }

  const goToAnnualPlan = () => {
    props.history.push(`/accountnew/plan/annual`);
  }

  return (
    <div className={classNames(css.clsCard, css.clsPlanCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <h4 className={classNames(css.clsCardTitle)}>Plan</h4>
        <div className={css.clsCardTxtGrp}>
          <a href="javascript:;" onClick={goToAnnualPlan} className={classNames(css.clsCardTxt, css.text_warning)}>Upgrade to an Annual Plan to Save 17%</a>
          <a href="javascript:;" onClick={goToMonthlyPlan} className={classNames(css.clsCardTxt, css.text_primary)}>Manage Plan</a>
        </div>
      </div>

      <div className={css.clsCardBody}>
        {errorFetched > 0 ? (<ErrorDisplay errorFetched={errorFetched} onReload={getPlanDetails} />) :
          (
            <div className={classNames(css.d_flex, css.justify_content_center)}>
              <div className={css.clsProTxt}>
                <h4> <img src={groupIocn} className={css.clsImg} /> <span>Pro</span></h4>
              </div>
              <div className={css.clsTypeTxt}>
                <h4>TYPE</h4>
                <h5>{planDetails?.annual ? "ANNUAL" : "MONTHLY"}</h5>
              </div>
              <div className={classNames(css.clsTypeTxt, css.clsSaveTxt)}>
                <h4>SAVES & EXPORTS</h4>
                <h5>{planDetails?.amount}/mo</h5>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default withRouter(PlanSection);
