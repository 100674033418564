import React, { useState, useEffect } from "react";
import classNames from "classnames";
import css from '../style.scss';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import downFullArrow from 'assets/images/account-page/down-full-arrow.svg'
import leadsmIcon from 'assets/images/account-page/lead-auto-sm-img.svg';
import LeaveMessagePopup from "../modals/LeaveMessage";
import { connect, useSelector } from "react-redux";
import userImg from 'assets/images/account-page/user-icon-lg.png';
import { selectSelectedTeamMember , selectUserId } from "data/user/selectors";
import { formatToLongDate, numberOfDaysFromToday } from "../../../utils/date/formatDate";
import { RemoveTeamMemberAPI } from "data/user/actions";

const RemoveTeamMember = (props) => {
  const { type , memberId } = props.match.params;
   const [screenType, setscreenType] = useState(type);
  
   
   const teamMemberDetails = useSelector(selectSelectedTeamMember).toJS();
   const userId = useSelector(selectUserId)
 
 
     useEffect(() => {
       if(screenType) {
        setscreenType(type)
       }
     }, [type])
     
 
   const handleCancel = () => {
    props.leaveMessagePopup({
        CancelRedirect: () => null, 
        yesRedirect: () =>  props.history.push("/accountnew/landing"),
      });
   }

 
   const goToAccount = () => {
     props.history.push(`/accountnew/landing`);
   }
   
   const handleConfirmChanges = async () => {
    console.log("Changes confirmed!");
    const req = { 
        userId: userId,
        teamMemberId : memberId
    }
    props.history.push(`/accountnew/removeteam/${memberId}/success`);
    // Enable after API works
    // try {
    //   const response = await RemoveTeamMemberAPI(req); // Await the async function
    //   if (response.success) {
    //     props.history.push(`/accountnew/removeteam/${memberId}/success`);
    //     console.log("successful:", response.teamMember);
    //   } else {
    //     console.error("failed:", response.error);
    //   }
    // } catch (error) {
    //   console.error("Unexpected error:", error);
    // }
  };
 
   return (
     <>
       <div className={css.clsInnerMainBody}>
         <div className={css.clsInnerPage}>
            <div className="container">
             <div className={css.clsLAReviewPaySec}>
               <div className="row justify-content-center">
                 <div className="col-lg-4">
                  
                 <FromToSelectedTier 
                 teamMember={teamMemberDetails}
                   />
 
                 </div>
                 {screenType === 'review' ? (
                   <div className="col-lg-5">
                     <ReviewConfirmUnsubscribe teamMember={teamMemberDetails} onConfirm={handleConfirmChanges} />
                 </div>
                 ) : (<SuccessfulFlow teamMember={teamMemberDetails} />)}
               </div>
             </div>
           </div>    
         </div>
 
         <div className={css.clsInnerFooter}>
 
         {screenType === 'review' ? (
            <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)} onClick={() => handleCancel()}> Cancel </button>
         ) : (
           <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)} onClick={() =>goToAccount()}> Return to Account </button>
         ) }
          <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}> Confirm Changes</button>
         </div>
       </div> 
     </>
   );
 };
 
 
 
 const FromToSelectedTier = ({teamMember}) => {
   return (
     <div className={css.clsLAReviewPayCard}>
     <div className={css.clsLAReviewLeadAutoDetils}>
       <div className="d-flex align-items-center">
         <div className={css.clsLAReviewLeadAutoImgSmall}>
           <img src={userImg} alt="user" />
         </div>
         <h5 className={css.clsLAReviewLeadAutomatorTxt}>{teamMember?.fullName}</h5>
       </div>
     </div>
     <div className={css.clsLAReviewTier}>
       <h4 className={css.clsTxtLine1}>MEMBER TIER</h4>
       <h4 className={css.clsTxtLine2}>Team Member</h4>
     </div>
     <div className="text-center">
       <ReactSVG src={downFullArrow} className={classNames(css.mb_16, css.mt_16)} />
     </div>
     <div className={css.clsLAReviewTier}>
       {/* <h4 className={css.clsTxtLine1}>MEMBER TIER</h4> */}
       <h4 className={css.clsTxtLine2}>None</h4>
     </div>
   </div>
   )
 }
 
 const ReviewConfirmUnsubscribe = (props) => {

  const {teamMember} = props

   return (
     <div className={css.clsLAReviewPayCard2}>
     <h3 className={css.clsLAReviewHeading}>Review And Confirm Changes</h3>
     <div className={css.clsLAReviewPayTimelineContainer}>
       <div className={css.clsLAReviewPayTimeline}>
         <div className={css.clsLATimelineItem}>
           <div className={css.clsLATimelineDot}></div>
           <div className={css.clsLATimelineContent}>
             <div className={css.clsLATimelineHead}>
               <h4 className={css.clsLATimelineLabel}>Today</h4>
             </div>
             <p className={css.clsLATimelineDescription}>
                 {teamMember.type === 'SCOUT' ? `${numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)} days remaining until Team Member’s account is deleted` : 
                                  ` ${numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)} days remaining for Team Member’s full-access privileges`
                 }
                </p>
           </div>
         </div>
 
         <div className={css.clsLATimelineLine2}></div>
 
         <div className={css.clsLATimelineItem}>
           <div className={css.clsLATimelineDot}></div>
           <div className={css.clsLATimelineContent}>
             <div className={css.clsLATimelineHead}>
               <h4 className={css.clsLATimelineLabel}> {formatToLongDate(teamMember?.accountPlanMrc?.billDate)}</h4>
             </div>
             <p className={css.clsLATimelineDescription}>Team Member is deleted</p>
           </div>
         </div> 
       </div>
     </div>
     <div className={css.clsLAReviewContentPara}>
       <p className={css.clsPara}>
         By clicking “Confirm Changes” you agree to the <a target="_blank"  href="https://www.propstream.com/terms-of-use" className={css.terms_conditions}>PropStream Terms and Conditions.</a>
       </p>
     </div>
   </div>
   )
 }
 
 
 const SuccessfulFlow = (props) => {

  const {teamMember} = props

   return (
     <div className={css.clsLAReviewPayCard2}>
       <h3 className={css.clsLAReviewHeading}>Changes Successful</h3>
       <div className={css.clsLAReviewPayTimelineContainer}>
         <div className={css.clsLAReviewPayTimeline}>
           <div className={css.clsLATimelineItem}>
             <div className={css.clsLATimelineDot}></div>
             <div className={css.clsLATimelineContent}>
               <div className={css.clsLATimelineHead}>
                 <h4 className={css.clsLATimelineLabel}>Today</h4>
               </div>
               <p className={css.clsLATimelineDescription}>
               {teamMember.type === 'SCOUT' ? `${numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)} days remaining until Team Member’s account is deleted` : 
                                  ` ${numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)} days remaining for Team Member’s full-access privileges`
                 }
                </p>
             </div>
           </div>
 
           <div className={css.clsLATimelineLine}></div>
           <div className={css.clsLATimelineItem}>
             <div className={css.clsLATimelineDot}></div>
             <div className={css.clsLATimelineContent}>
             <div className={css.clsLATimelineHead}>
               <h4 className={css.clsLATimelineLabel}> {formatToLongDate(teamMember?.accountPlanMrc?.billDate)}</h4>
             </div>
             <p className={css.clsLATimelineDescription}>Team Member is deleted</p>
             </div>
           </div>  
         </div>
       </div>
 
     
     </div>
   )
 }
 


 export default withRouter(connect(null, {
  leaveMessagePopup: LeaveMessagePopup.open
})(RemoveTeamMember));
