import masterCard from 'assets/images/account-page/master-card.svg';
import classNames from "classnames";
import React, { useState, useEffect } from 'react';
import css from '../../style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadBillingHistoryList } from '../../../../data/user/actions';
import { selectUserId, selectBillingHistory } from "data/user/selectors";

import amex from 'assets/images/account-page/amex.svg';
import discover from 'assets/images/account-page/discover-card.svg';
import mc from 'assets/images/account-page/master-card.svg';
import visa from 'assets/images/account-page/visa-card.svg';


const BillingHistory = () => {
  const list = useSelector(selectBillingHistory);
  const userID = useSelector(selectUserId);
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState([]);

  const tabs = ['All', 'Recurring', 'In-App Purchases', 'Prepaid Credits'];

  useEffect(() => {
    if (userID) {
      dispatch(loadBillingHistoryList(userID));
    }
  }, [userID, dispatch]);

  // Update table data when list changes
  useEffect(() => {
    filterTableData(tabIndex, list);
  }, [list]); 

  const filterTableData = (index, data) => {
    if (!data) return;

    let filteredData = [];
    switch (index) {
      case 1:
        filteredData = data.filter(item => item?.purchaseType === "Recurring");
        break;
      case 2:
        filteredData = data.filter(item => item?.purchaseType === "InApp");
        break;
      case 3:
        filteredData = data.filter(item => item?.purchaseType === "PrepaidCredits");
        break;
      default:
        filteredData = data; // Show all
    }
    setTableData(filteredData);
  };

  const tabSelected = (index) => {
    setTabIndex(index);
    filterTableData(index, list);
  };

  const getImage = (type) => {
    switch (type) {
      case 'VISA':
        return { image: visa, name: 'Visa' };
  
      case 'AMEX':
        return { image: amex, name: 'American Express' };
  
      case 'DISCOVER':
        return { image: discover, name: 'Discover' };
  
      case 'MC':
        return { image: mc, name: 'MasterCard' };
  
      default:
        return { image: '', name: '' }; // Provide a fallback
    }
  };
  

  return (
    <>
      <div className={classNames(css.d_flex, css.justify_content_between, css.pb_20)}>
        <ul className={classNames(css.clsBilingTab, css.clsBilingInnerTab)}>
          {tabs.map((tab, index) => (
            <li className={css.clsTabItem} onClick={() => tabSelected(index)} key={index}>
              <a href="javascript:;" className={`${css.clsTabLink} ${tabIndex === index ? css.active : ''}`}>{tab}</a>
            </li>
          ))}
        </ul>
        <button className={classNames(css.btn_primary, css.clsBtnOrng)}> Download PDF</button>
      </div>

      <div className={classNames("table-responsive")}>
        <table className={classNames(css.clsTable, css.clsTableDarkHead, css.clsBilingTable, css.mb_0, "table")}>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Purchased By</th>
              <th>Method</th>
              <th>Date</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
          {tableData && tableData?.length > 0 ? (
            tableData.map((data, index) => {
              const { productName, term, userList, purchasedBy, cardNumber, purchasedDate, price, priceList } = data;
              return (
                <tr key={index}>
                  <td>
                    <div className={classNames(css.d_flex, css.align_items_center)}>
                      <span>{productName}</span>
                      {term && <span className={classNames(css.clsBtnSm, css.clsBtnGray, css.d_inline_block, "ml-2")}>{term}</span>}
                    </div>
                    {userList && <span className={classNames(css.clsTxtLine2)}> {userList} </span>}
                  </td>
                  <td>{purchasedBy}</td>
                  <td>
                    {getImage(data?.cardType)?.image && (
                      <span className={classNames(css.mr_10, css.d_inline_block)}>
                        <img src={getImage(data?.cardType).image} alt={getImage(data?.cardType).name} />
                      </span>
                    )}
                    <span>{cardNumber && `- ${cardNumber}`}</span>
                  </td>
                  <td>{purchasedDate}</td>
                  <td>
                    <div>${price}</div>
                    <div className={classNames(css.clsPriceTxt)}>
                      {priceList?.map((price, idx) => (
                        <span key={idx}>${price}</span>
                      ))}
                    </div>
                  </td>
                </tr>
              );
            })
              ) : (
                <tr>
                  <td colSpan="5" className={classNames(css.text_center, css.p_20)}>No Data Found</td>
                </tr>
              )}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default BillingHistory;
