import getCardType, { inferCardType } from './payment';

/**
 * Wrapper function for simple form validation. Pass this method a function containing
 * calls to verify() for every validation that needs to be done. verify() will throw an
 * error if validation fails, which this method will catch and show error as needed.
 */
export function validate(func) {
  let res;

  try {
    res = func();

    res = typeof res === 'undefined' || res == null ? true : !!res;
  } catch (e) {
    if (console && console.log) console.log(e, 'Validation Exception');
    if (e.message && e.message !== '') alert(e.message);
    if (e.el) {
      if (e.el.focus) e.el.focus();
      if (e.el.select) e.el.select();
    }

    res = false;
  }

  return res;
}

/**
 * Function that simply checks isValid and throws and error if false.
 * This is meant to be called from within a validate() wrapper, which will handle the error.
 *
 * @param el
 * @param isValid
 * @param message
 * @returns {boolean}
 */
export function verify(el, isValid, message = '') {
  if (!isValid) {
    const err = { el, message };

    throw err;
  }

  return true;
}

/**
 * Pass verification if value is a non-blank string or any other non-string object.
 *
 * @param el
 * @param value
 * @param message
 * @returns {boolean}
 */
export function verifyRequired(el, value, message = '', required = true) {
  return verify(el, !required || (value != null && typeof value !== 'undefined' && (typeof value !== 'string' || value.trim() !== '')), message);
}

export function isValidEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(typeof email === 'string' ? email.trim() : '');
}

export function isValidZip(zip) {
  return /^\d{5}(-?\d{4})?$/.test(zip);
}

export function isValidPhone(val) {
  return /^\d{10,15}$/.test(val.replace(/\D/g, ''));
}

export function isValidLuhnNumber(num) {
  const noDigit = num.length;
  const oddoeven = noDigit & 1; // eslint-disable-line no-bitwise
  let sum = 0;

  for (let count = 0; count < noDigit; count++) {
    let digit = parseInt(num.charAt(count), 10);
    if (!((count & 1) ^ oddoeven)) { // eslint-disable-line no-bitwise
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    sum += digit;
  }

  return sum % 10 === 0;
}

export const isValidCardNumber = num => getCardType(num).valid && isValidLuhnNumber(num);

export const isValidCardCode = (code, num) => inferCardType(num).codePattern.test(code);

export const isValidCardExpiration = (y, m) => {
  const d = new Date();
  return new Date(y, m - 1, 1) >= new Date(d.getFullYear(), d.getMonth(), 1);
};

export const  titleCaseFormat = (text) => {
  return text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
};

export const handleNumericInput = (value, setFieldValue, fieldName, maxLength) => {
  let numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
  if (maxLength) numericValue = numericValue.substring(0, maxLength); // Limit length
  setFieldValue(fieldName, numericValue);
};


// only shows last 4 digits only
export const getLastFourDigits = (cardNumber) => {
  if(!cardNumber) return cardNumber
  return cardNumber.slice(-4); // Extracts the last 4 characters
};

export const isCardExpired = (cardExp) => {
  if (!cardExp) return true; // Treat missing values as expired

  const [expMonth, expYear] = cardExp.split('/').map(Number);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-based in JS
  const currentYear = currentDate.getFullYear();

  return expYear < currentYear || (expYear === currentYear && expMonth < currentMonth);
};

export const validateCvv = (value, cardNumber) => {
  if (!value) return false;
  const cleanedValue = value.replace(/\D/g, '');
  const cardType = getCardType(cardNumber).code;
  if (cardType === 'amex') return cleanedValue.length === 4;
  return cleanedValue.length === 3;
};

const maxLength = 50
const zipCodeLength = 5
const llowText = /[^a-zA-Z0-9]/g

export const getMarketingProfileValidationRules = () => {
  return {
    maxLength: {
      firstName: maxLength,
      lastName: maxLength,
      businessName: maxLength,
      businessPhone: maxLength,
      businessWebsite: maxLength,
      businessEmail: maxLength,
      emailFriendlyFrom: maxLength,
      replyToEmail: maxLength,
      addressLine1: maxLength,
      addressLine2: maxLength,
      city: maxLength,
      zipCode: zipCodeLength,
    },
  
    validationPattern: {
      firstName: /[^a-zA-Z0-9]/g, // Allow only a-z, A-Z, 0-9
      lastName: /[^a-zA-Z0-9]/g, 
      businessName: /[^a-zA-Z0-9]/g, 
      businessPhone: /[^0-9]/g, // Allow only numbers
      businessWebsite: null, // Custom validation for website format (handled separately)
      businessEmail: null, // Custom validation for email format (handled separately)
      emailFriendlyFrom: /[^a-zA-Z0-9]/g, 
      replyToEmail: null, // Custom validation for email format
      addressLine1: /[^a-zA-Z0-9\-#\s]/g, // Allow a-z, A-Z, 0-9, -, #
      addressLine2: /[^a-zA-Z0-9\-#\s]/g, 
      city: /[^a-zA-Z0-9\- ]/g, // Allow a-z, A-Z, 0-9, -
      zipCode: /[^0-9]/g, // Allow only numbers
    }
  };
};
