import React from "react";
import css from "./style.scss";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

import EssentialsIcon from "../../../../assets/images/account-page-plan-essentials.svg";
import ProIcon from "../../../../assets/images/account-page-plan-pro.svg";
import EliteIcon from "../../../../assets/images/account-page-plan-elite.svg";
import InfoIcon from "../../../../assets/images/account-page-info-icon.svg";

const PlanCard = ({
  planName,
  planDescription,
  currentPrice,
  discountPrice,
  currentPlan,
  annualPlan,
  extraValue,
  planIncludes,
  selectPlan
}) => {
  const getIcon = () => {
    if (planName == "Essentials") return EssentialsIcon;
    else if (planName == "Pro") return ProIcon;
    else return EliteIcon;
  };

  const getPlanClass = () => {
    if (planName == "Pro") return css.pro;
    else if (planName == "Elite") return css.elite;
    else "";
  };

  const getExtraClass = () => {
    if (planName == "Pro") return css.proBg;
    else if (planName == "Elite") return css.eliteBg;
    else "";
  };

  const getSpace = () => {
    if (annualPlan) return <div className={css.annualExportSpace}>&nbsp;</div>;
    else return <div className={css.monthExportSpace}>&nbsp;</div>;
  };

  return (
    <div
      className={
        annualPlan && planName == "Pro"
          ? css.recommendedPlanCard
          : css.recommendedPlanCardNull
      }
      style={annualPlan ? { minHeight: "707px" } : {}}
    >
      {annualPlan && planName == "Pro" ? <span className={css.recommendedTitle}>Recommended</span> : null}
      <div
        className={css.planCard}
        style={annualPlan ? { minHeight: "707px" } : {}}
      >
        <div className={css.planHeader}>
          <div>
            <ReactSVG src={getIcon()} className={css.planIcon} />
            <h4 className={getPlanClass()}>{planName}</h4>
          </div>
          <div className={classNames('position-relative')}>
            {annualPlan && (
              <div className={classNames(css.extraContainer, getExtraClass(), "position-absolute")}>
                <p className={classNames(css.extraValue, 'm-0')}>{extraValue}%</p>
                <p className={classNames('m-0')} style={{width: "max-content"}}>
                  Extra Value
                </p>
              </div>
            )}
          </div>
        </div>
        <h6>{planDescription}</h6>
        {planName == "Essentials" ? (
          <div className={css.saveExportDetail}>
            <p>
              Saves & Exports <img src={InfoIcon} />
            </p>
            <select>
              <option>25,000/mo</option>
              <option>50,000/mo</option>
            </select>
          </div>
        ) : (
          getSpace()
        )}
        <div className={css.planPrice}>
          <p className={css.priceStarts}>
            {planName == "Essentials" ? "starting at " : ""}
            {annualPlan ? <span>${discountPrice}/mo</span> : ""}
          </p>
          <p className={css.price}>
            <span>${currentPrice}</span>/mo
          </p>
          {annualPlan ? <p className={css.annual}>billed annually</p> : ""}
        </div>
        <button onClick={selectPlan} className={css.clsBtnOrng}>Choose Plan and Continue</button>
        <div>
          <h5>{planName} Includes:</h5>
          <ul className={annualPlan ? css.annual : ""}>
            {planIncludes.map(include => (
              <li>
                {include.highlightText && <b>{include.highlightText} </b>}
                {include.text}
                <img src={InfoIcon} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
