import classNames from "classnames";
import React, { useEffect, useState } from "react";
import css from "./style.scss";
import { ReactSVG } from 'react-svg';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import downFullArrow from '../../../assets/images/account-page/down-full-arrow.svg'
import downArrow from '../../../assets/images/account-page/down-arrow.svg';
import visaCard from '../../../assets/images/account-page/visa-card.svg';
import payFailedIcon from '../../../assets/images/exclamation.svg'

import CreditCardDropdown from '../components/CreditCardDropdown';
import { useFormContext as useCustomFormContext } from "../../../context/FormContext";
import { useFormContext as useRHFormContext } from "react-hook-form";
import { formatToLongDate, numberOfDaysFromToday } from "../../../utils/date/formatDate";
import { selectSelectedTeamMember ,selectShowFinishBuildingYourTeam , selectIsPaidUser } from "data/user/selectors";
import { useSelector } from "react-redux";

const ReviewTeam = (props) => {


    const teamMember = useSelector(selectSelectedTeamMember).toJS();
const showFinishBuildingYourTeam = useSelector(selectShowFinishBuildingYourTeam); // true user not fill with reloaded full 
       const { setValue, watch } = useRHFormContext();
        const selectedTier = watch("type");
    
         const { formData , updateFormData , onUpdateValidationSchema } = useCustomFormContext();

    const [scoutUser, setScoutUser] = useState(() => formData?.type === "SCOUT");

    const isPaidUser = useSelector(selectIsPaidUser)
    
    const handleConfirmChanges = () => {
        setValue('agreeToTerms', true); // Update state
      };

    return (

        <div className="container">
            <div className={css.clsTMReviewPaySec}>
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className={css.clsTMReviewPayCard}>
                            <div className={css.clsTMReviewUserDetils}>
                                <div className="d-flex align-items-center">
                                    <div className={css.clsTMReviewUserImg}>
                                        <img src={userImg} alt="Profile" />
                                    </div>
                                    <h5 className={css.clsTMReviewUserName}>{formData.firstName} {formData.lastName}</h5>
                                </div>
                            </div>
                            <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>TEAM MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>{scoutUser ? 'Scout' : 'Full Access'}</h4>
                            </div>
                            {props?.review && (
                                <>
                                 <div className="text-center">
                                <ReactSVG src={downFullArrow} className={classNames(css.mb_16, css.mt_16)} />
                            </div>
                            <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>{!scoutUser ? 'Scout' : 'Full Access'}</h4>
                            </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className={css.clsTMReviewPayCard2}>
                            <h3 className={css.clsTMReviewHeading}>Review And Confirm Changes</h3>
                            {(scoutUser || isPaidUser) && (
                                <>
                            <div className={css.clsTMReviewPayTimelineContainer}>
                                <div className={css.clsTMReviewPayTimeline}>
                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Today</h4>
                                                <h5 className={css.clsTMTimelinePrice}>${numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)*1}</h5>
                                            </div>
                                            <p className={css.clsTMTimelineDescription}>Team Member gains full access, {numberOfDaysFromToday(teamMember?.accountPlanMrc?.billDate)} days until next billing cycle.</p>
                                        </div>
                                    </div>

                                    <div className={css.clsTMTimelineLine}></div>

                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Starting {formatToLongDate(teamMember.accountPlanMrc?.billDate)}</h4>
                                                <h5 className={css.clsTMTimelinePrice}>$30/month</h5>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className={css.pb_48}>
                                <div className={css.clsTMReviewPMCard}>



                                    <h4 className={css.clsTMReviewPMCardHeading}>Payment Method</h4>

                                    <CreditCardDropdown
                                                                tag={false}
                                                                options={[{ label: "A", cardEnding: '0059' , expDate: '05/2026' }, { label: "B", cardEnding: '0100' , expDate: '65/2027' }, { label: "C",cardEnding: '0099' , expDate: '05/2028' }]}
                                                            />
                                </div>
                            </div>
                            {/* for payment Failed case */}
                            {/* <>
                                <div className={css.failedPay}>
                                    <div className={css.failedPay_Inner}>
                                        <img src={payFailedIcon} />
                                        <span>Payment Failed</span>
                                    </div>
                                    <p>There was an issue with your payment. Please choose a different payment method and try again.</p>
                                </div>

                                <p className={css.clsParagraph}>Have a Question? <span className={css.text_primary}>Contact Support</span></p>
                            </> */}
                            </> )}
                            <div className={`${css.clsTMReviewContentPara} ${(scoutUser || !isPaidUser) ? css.mt_16 : ""}`}>
                            {(scoutUser || isPaidUser) && (
                                <p className={classNames(css.clsPara, css.mb_16)}>
                                    <span className={css.text_b}>Starting on {formatToLongDate(teamMember.accountPlanMrc?.billDate)} you’ll be charged $30.00 automatically every month until you cancel.</span> This does not include any other PropStream services you may be subscribed to. Your price may change as described in the <a target="_blank" href="https://www.propstream.com/terms-of-use" className={css.text_primary}>PropStream Terms and Conditions.</a>
                                </p>
                            )}
                                <p className={css.clsPara}>
                                    By clicking “Confirm Changes” you agree to the <a target="_blank" href="https://www.propstream.com/terms-of-use" className={css.text_primary}>PropStream Terms and Conditions.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewTeam;
