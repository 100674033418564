/** @flow */
// don't remove dotsm because it'll break the help:me script.
import getCurrent from '../../../utils/URL/getCurrent';
import getURL from '../../../utils/URL/getURL';
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

let NO_CODE;

const DEFAULT_URL = 'http://127.0.0.1:3001';

const PRICING_UPDATE_URL = "https://pricing.dev.propstream.com";

const getFormattedUrl = (base) => {
  if (process.env.NODE_ENV !== 'dev-server') {
    return base || DEFAULT_URL;
  }

  const current = getCurrent();

  if (!base) {
    current.pathname = '';
  }
  const url = getURL(
    (((base) || DEFAULT_URL)),
  );
  url.protocol = current.protocol;
  url.hostname = current.hostname;

  if (current.port) {
    url.port = current.port;
  }

  return url.href;
};

const getBaseUrl = () => {
  if (process.env.API_BASE_URL === 'NO_BASE_URL') return DEFAULT_URL;
  return getFormattedUrl(process.env.API_BASE_URL);
};

const getCancelationBaseUrl = () => {
  if (process.env.CANCELATION_BASE_URL === 'NO_BASE_URL') return DEFAULT_URL;
  return process.env.CANCELATION_BASE_URL;
};
const getAduCalculatorBaseUrl = () => {
  if (process.env.ADU_CALCULATOR_BASE_URL === 'NO_BASE_URL') return DEFAULT_URL;
  return process.env.ADU_CALCULATOR_BASE_URL;
};

const getDemographicsUrl = () => {
  if (process.env.DEMOGRAPHICS_URL === 'NO_BASE_URL') return DEFAULT_URL;
  return process.env.DEMOGRAPHICS_URL;
};

const getGoogleAnalytics = () => {
  return process.env.GOOGLE_ANALYTICS;
};

const getIntelligenceUrl = () => {
  return process.env.INTELLIGENCE_URL;
};

const getPricingUpdateUrl = () => {
  return process.env.PRICING_UPDATE_URL || PRICING_UPDATE_URL;
};

export const CANCELATION_BASE_URL = process.env.CANCELATION_BASE_URL;
export const GOOGLE_ANALYTICS = process.env.GOOGLE_ANALYTICS;

const getExtraUrl = () => Object.keys(process.env)
  .filter(key => key.startsWith('EXTRA_URL_'))
  .reduce((result, key) => {
    const nextKey = key.replace('EXTRA_URL_', '');
    return {
      ...result,
      [nextKey]: getFormattedUrl(process.env[key]).replace(/\/$/, ''),
    };
  }, {});

const retrieveSecrets = async () => {
  let client = new SecretsManagerClient({
    region: '',
  });
  let secrets;
  try {
    secrets = await client.send(
      new GetSecretValueCommand({
        SecretId: '',
        VersionStage: "AWSCURRENT",
      })
    );
    return JSON.parse(secrets.SecretString);
  } catch (error) {
    throw error;
  }
}

export default {
  BASE_URL: getBaseUrl(),
  CANCELATION_BASE_URL: getCancelationBaseUrl(),
  ADU_CALCULATOR_BASE_URL: getAduCalculatorBaseUrl(),
  DEMOGRAPHICS_URL: getDemographicsUrl(),
  GOOGLE_ANALYTICS: getGoogleAnalytics(),
  INTELLIGENCE_URL: getIntelligenceUrl(),
  PRICING_UPDATE_URL: getPricingUpdateUrl(),
  EXTRA_URL: getExtraUrl(),
  CODE: {
    SUCCESS: [
      'success',
      null,
      NO_CODE,
    ],
    ERROR: [
      'validation_error',
    ],
    VALIDATION_ERROR: 'validation_error',
  },
  FIELD: {
    VALIDATION_ERRORS: 'validationErrors',
    VALIDATION_DETAILS: 'detail',
  },
};
