import classNames from "classnames";
import config from 'config';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bannerImg from '../../../assets/images/account-page/prepaid-credits-banner.png';
import { loadPrepaidCreditsSuccess } from "../../../data/user/actions";
import { selectPrepaidCredits, selectProfile } from '../../../data/user/selectors';
import ErrorDisplay from '../components/ErrorDisplay';
import ReloadPrepaidCreditsPopup from '../modals/ReloadPrepaidCredits';
import css from '../style.scss';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const PrepaidCredits = (props) => {

  const [errorFetched, setErrorFetched] = useState(0);
  const balance = useSelector(selectPrepaidCredits)?.toJS();

  useEffect(() => {
    getPrepaidCredits();
  }, [])

  const getPrepaidCredits = () => {
    const userId = props.profile.id;
    fetch(`${PRICING_UPDATE_URL}/ps/prepaidcredits?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status !== 200) {
          setErrorFetched(prevCount => prevCount + 1);
         } else {
           if (data && data.length) {
            loadPrepaidCreditsSuccess(data[0].remainingAmount)
          } else {
            loadPrepaidCreditsSuccess(null)
          }
          setErrorFetched(0);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorFetched(prevCount => prevCount + 1);
      });
  }

  const openReloadPrepaidCredits = () => {
    props.openReloadPrepaidCreditsPopup()
  }

  return (
    <div className={classNames(css.clsCard, css.clsPreCreditsCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <div className={classNames(css.clearfix, 'w-100')}>
          <h4 className={classNames(css.clsCardTitle)}>Prepaid Credits</h4>
        </div>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary, {
          [css.disabled]: errorFetched,
        })}
          onClick={errorFetched ? undefined : openReloadPrepaidCredits}
        > Reload</a>
      </div>

      <div className={css.clsCardBody}>
        {errorFetched > 0 ? (<ErrorDisplay errorFetched={errorFetched} onReload={getPrepaidCredits} />) :
          (
            <>
              {balance ? (<h4 className={css.clsValue}>${balance}</h4>) : (
                <div className={css.clsPreCreditsCardBanner}>
                  <div className={css.clsBannerImg}>
                    <img src={bannerImg} alt='banner' />
                  </div>
                  <div className={css.clsBannerContent}>
                    <div className={classNames(css.pb_49, "clearfix w-100")}>
                      <h4 className={css.clsBannerHeading}>Using the Mobile App?</h4>
                      <p className={css.clsBannerPara}>Add credits to send postcards, skip trace contacts, and download documents on the go.</p>
                    </div>
                    <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)} onClick={openReloadPrepaidCredits}>Purchase Credits</button>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    </div>
  )
}

export default withRouter(connect(state => ({
  profile: selectProfile(state).toJS()
}), {
  openReloadPrepaidCreditsPopup: ReloadPrepaidCreditsPopup.open,
})(PrepaidCredits));