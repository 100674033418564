import userIcon from 'assets/images/account-page/user-icon.png';
import classNames from "classnames";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import EditMarketingProfilePopup from "../modals/EditMarketingProfile";
import css from "../style.scss";

import { selectMarketingProfile, selectUserId } from "data/user/selectors";
import { useEffect } from "react";
import MarketingProfile from '../../../assets/images/account-page/marketing-profile.png';

import config from 'config';
import { loadMarketingProfileSuccess } from "data/user/actions";
import ErrorDisplay from '../components/ErrorDisplay';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const MarketingProfileSections = (props) => {
  const [isNewMarketingProfile, setMarketingProfile] = useState(null)
  const [errorFetched, setErrorFetched] = useState(0);
  const userID = useSelector(selectUserId)
  const selectMarketingProfileSection = useSelector(selectMarketingProfile).toJS();
  const dispatch = useDispatch();


  useEffect(() => {
    if (userID) {
      getMarketingProfile()
    }
  }, [userID])

  useEffect(() => {
    if (selectMarketingProfileSection && !errorFetched) {
      if (selectMarketingProfileSection?.businessAddressLine1 && selectMarketingProfileSection?.businessCity && selectMarketingProfileSection?.businessState && selectMarketingProfileSection?.businessZipCode) {
        setMarketingProfile(true)
      } else {
        setMarketingProfile(false)
      }
    }
  }, [selectMarketingProfileSection, errorFetched])

  const openEditMarketingProfile = () => {
    props.EditMarketingProfilePopup()
  }

  const getFullDataURL = (base64String) => {
    // Decode the Base64 string to check its format
    const binaryString = atob(base64String);
    // Get the first 4 bytes (magic number) as hexadecimal
    const first4Bytes = binaryString
      .slice(0, 4)
      .split("")
      .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
      .join(" ");
    // Determine the MIME type based on the magic number
    let mimeType = "";
    if (first4Bytes === "89 50 4e 47") {
      mimeType = "image/png";
    } else if (first4Bytes.startsWith("ff d8 ff")) {
      mimeType = "image/jpeg";
    } else if (binaryString.trim().startsWith("<svg")) {
      mimeType = "image/svg+xml";
    } else {
      throw new Error("Unknown image format");
    }

    // Construct the full Data URL
    return `data:${mimeType};base64,${base64String}`;
  }


  const getMarketingProfile = () => {
    fetch(`${PRICING_UPDATE_URL}/ps/profile/marketing/${userID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.userId) {
          dispatch(loadMarketingProfileSuccess(data))
          setErrorFetched(0);
        } else {
          setErrorFetched(prevCount => prevCount + 1);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorFetched(prevCount => prevCount + 1);
      });
  }


  return (
    <div className={classNames(css.clsCard, css.clsProfileCard, css.clsProfileCard2)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <div className={css.clearfix}>
          <h4 className={classNames(css.clsCardTitle)}>Marketing Profile</h4>
          <h5 className={classNames(css.clsCardSubTitle)}>Used for Marketing Campaigns</h5>
        </div>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary, {
          [css.disabled]: errorFetched,
        })}
          onClick={errorFetched ? undefined : openEditMarketingProfile}
        > {isNewMarketingProfile ? 'Edit' : 'Add'}</a>
      </div>

      <div className={css.clsCardBody}>

        {errorFetched > 0 ? (<ErrorDisplay errorFetched={errorFetched} onReload={getMarketingProfile} />) :
          (
            <>
              {isNewMarketingProfile ? (
                <>
                  <div className={css.clsProfileSec}>
                    <div className={css.clsProfileImg}>
                      <img src={selectMarketingProfileSection?.marketingProfilePicture
                        ? getFullDataURL(selectMarketingProfileSection?.marketingProfilePicture)
                        : userIcon} alt="User" />
                    </div>
                    <div className={css.clearfix}>
                      <h3 className={css.clsUserName}>{selectMarketingProfileSection?.businessFullName ? selectMarketingProfileSection?.businessFullName : '-'}</h3>
                      <h4 className={css.clsUserPost}>Marketing Profile</h4>
                    </div>
                  </div>

                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>WEBSITE</h3>
                    <h4 className={css.clsContactLine2}>{selectMarketingProfileSection?.businessWebsite ? selectMarketingProfileSection?.businessWebsite : '-'}</h4>
                  </div>

                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>Email</h3>
                    <h4 className={css.clsContactLine2}>{selectMarketingProfileSection?.businessEmail ? selectMarketingProfileSection?.businessEmail : '-'}</h4>
                  </div>

                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>REPLY TO EMAIL</h3>
                    <h4 className={css.clsContactLine2}>{selectMarketingProfileSection?.replyToEmail ? selectMarketingProfileSection?.replyToEmail : '-'}</h4>
                  </div>

                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>Email FRIENDLY FROM</h3>
                    <h4 className={css.clsContactLine2}>{selectMarketingProfileSection?.emailFriendlyFrom ? selectMarketingProfileSection?.emailFriendlyFrom : '-'}</h4>
                  </div>

                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>Phone</h3>
                    <h4 className={css.clsContactLine2}>{selectMarketingProfileSection?.businessPhone ? selectMarketingProfileSection?.businessPhone : '-'}</h4>
                    {/* <h4 className={css.clsContactLine2}>(818) 123-4567</h4> */}
                  </div>
                  <div className={css.clsProfileContact}>
                    <h3 className={css.clsContactLine1}>Address</h3>
                    <h4 className={css.clsContactLine2}>
                      {selectMarketingProfileSection?.businessAddressLine1}  {selectMarketingProfileSection?.businessAddressLine2} <br></br>
                      {selectMarketingProfileSection?.businessCity} {selectMarketingProfileSection?.businessState} {selectMarketingProfileSection?.businessZipCode}
                    </h4>
                  </div>

                </>
              ) : (
                <div className={css.clsMarketingProfileCardBanner}>
                  <div className={css.clsBannerContent}>
                    <h4 className={css.clsBannerHeading}>Complete your
                      marketing profile!</h4>
                    <p className={css.clsBannerPara}>Please complete your profile to experience PropStream’s add-on services, such as emails, postcards, and landing pages.</p>
                  </div>
                  <div className={css.clsBannerImg}>
                    <img src={MarketingProfile} alt='banner' />
                  </div>
                  <div className="text-right">
                    <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)} onClick={openEditMarketingProfile}>Start Now</button>
                  </div>
                </div>
              )}
            </>)}
      </div>
    </div>
  )
}


export default withRouter(connect(null, {
  EditMarketingProfilePopup: EditMarketingProfilePopup.open
})(MarketingProfileSections));