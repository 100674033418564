import React from "react";
import { useFormContext } from "react-hook-form";
import css from "./style.scss";
import classNames from "classnames";

const TextField = ({ name, label, placeholder = label, type = "text", required = true , numbers = false , decimal = false }) => {
  const {
    register,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={classNames(css.clsFormGroup)}>
      <label  className={classNames(css.clsFormLabel, errors[name] && css.text_danger)} htmlFor={name}>{label}{required ? <span className={css.text_primary}>*</span>: null}</label>
      <input className={classNames(
               css.clsFormControl,
               errors[name] && css.clsBorderDanger
             )} {...register(name)} id={name} placeholder={placeholder} type={type} 
     onChange={(e) => {
      let value = e.target.value;
     
      if(numbers || decimal) {
        if (numbers && !decimal) {
          // Allow only whole numbers
          if (value.startsWith("00")) value = value.replace(/^0+/, "0");
          value = value.replace(/\D/g, ""); 
        } else if (!numbers && decimal) {
          // Allow only decimal format, e.g., ".12"
          value = value.replace(/[^0-9.]/g, "");
          
          // Ensure only one leading zero or decimal
          if (value.startsWith("00")) value = value.replace(/^0+/, "0");
          if (value.startsWith(".")) value = "0" + value;
    
          // Restrict to one decimal point and max two decimal places
          const parts = value.split(".");
          if (parts.length > 2) {
            value = parts[0] + "." + parts.slice(1).join("");
          }
          if (parts.length === 2) {
            parts[1] = parts[1].slice(0, 2); // Limit to 2 decimal places
            value = parts.join(".");
          }
        } else if (numbers && decimal) {
          // Allow both numbers and up to 2 decimal places
          value = value.replace(/[^0-9.]/g, "");
          
          // Ensure only one leading zero or decimal
          if (value.startsWith("00")) value = value.replace(/^0+/, "0");
          if (value.startsWith(".")) value = "0" + value;
    
          // Restrict to one decimal point and max two decimal places
          const parts = value.split(".");
          if (parts.length > 2) {
            value = parts[0] + "." + parts.slice(1).join("");
          }
          if (parts.length === 2) {
            parts[1] = parts[1].slice(0, 2); // Limit to 2 decimal places
            value = parts.join(".");
          }
        }
      }
     

      setValue(name, value);
      trigger(name)
    }}
      />
      
      {errors[name] && <div className={classNames(css.clsFormErrorText, css.text_danger)}>{errors[name].message}</div>}
    </div>
  );
};

export default TextField;