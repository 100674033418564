import MultiStepForm from "components/MultiStepForm";
import FormStep from "components/MultiStepForm/FormStep";
import { getTeamMembers } from "data/user/actions";
import { selectCanCreate, selectIsPaidUser, selectUserId } from "data/user/selectors";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormProvider } from "../../../context/FormContext";
import LeaveMessagePopup from "../modals/LeaveMessage";
import mainCss from "../style.scss";
import ChooseTier from "./chooseTier";
import CreateTeamProfile from "./createTeamProfile";
import ReviewTeam from "./reviewTeam";
import SuccessTeamProfile from "./successTeamProfile";
import { chooseCreateTeamDefaultValue, chooseTierDefaultValue, profileValidationSchema, reviewTeamValidationSchema, step1ValidationSchema } from "./validationSchemas";

const TeamScreen = ({ history, leaveMessagePopup }) => {

  const userID = useSelector(selectUserId)

  const isPaidUser = useSelector(selectIsPaidUser)
  const canCreate = useSelector(selectCanCreate)

  const dispatch = useDispatch();

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    const isReload = navigationEntries?.length > 0 && navigationEntries[0]?.type === "reload";

    if (isReload) {
      console.log("Page was reloaded");
      if (userID) {
        dispatch(getTeamMembers(userID));
      }
    }
  }, [userID]);

  const steps = [
    {
      component: (
        <FormStep defaultValues={chooseTierDefaultValue} CancelButtonLabel="Cancel" showCancelButton={true} continueButtonLabel={"Confirm Changes"}>
          <ChooseTier />
        </FormStep>
      ),
      validationSchema: step1ValidationSchema,
      screen: '1'
    },
    {
      component: (
        <FormStep defaultValues={chooseCreateTeamDefaultValue} backButtonLabel="Back to Tiers">
          <CreateTeamProfile />
        </FormStep>
      ),
      validationSchema: profileValidationSchema,
      screen: '2'
    },
    {
      component: (
        <FormStep backButtonLabel="Back to Profile" continueButtonLabel={"Confirm Changes"}>
          <ReviewTeam />
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isSubmissionStep: true,
      screen: '3'
    },
    {
      component: (
        <FormStep backButtonLabel="Back to Profile" showBackButton={false} continueButtonLabel="Return to Account Home">
          <SuccessTeamProfile />
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isRedirectStep: true,
      screen: '4'
    },
  ];



  const handleNextStep = (formData, nextStep, currentStep) => {
    if (
      formData?.type !== "SCOUT" &&
      currentStep?.screen === "2" &&
      (!formData?.spendingLimit || !formData?.propertyLimit)
    ) {
      leaveMessagePopup({
        title: 'Proceed Without Setting Team Member Limits?',
        message: 'Your Team Member will have unlimited access to Saves, Exports and Spending.',
        autoWidth : true,
        CancelRedirect: () => null, // Stay on the same step
        yesRedirect: () => nextStep(), // Move to the next step
      });
      return; // Prevent navigation until the user selects an option
    }

    // chooseTier

    if (
      currentStep?.screen === "1" && formData?.type !== "SCOUT"
    ) {

      if (canCreate && isPaidUser) {
        leaveMessagePopup({
          title: "Are you sure want to proceed?",
          message: "Each additional user will cost $30.",
          CancelRedirect: () => null, // Stay on the same step
          yesRedirect: () => nextStep(), // Move to the next step
        });
        return; // Prevent navigation until the user selects an option
      } else {
        if (canCreate) {

        } else {
          leaveMessagePopup({
            title: "Maximum Limit of Full Access Team Members Reached.",
            message: "Please contact Customer Support at [vendor_phone_number] if you need more.",
            autoWidth : true,
            yesLable: "Ok",
            cancelLable: "",
            CancelRedirect: () => null, // Stay on the same step
            yesRedirect: () => null, // Move to the next step
          });
          return; // Prevent navigation until the user selects an option
        }

      }

    }

    if (typeof nextStep === "function") {
      nextStep(); // Move to the next step if no popup is needed    
    }
  };


  const handleSubmit = async (finalData) => {
    try {
      console.log("Submitting: ", finalData);

      // Call API and wait for response
      // await createTeamMemberApi(finalData);

      // If API is successful, move to next step
      // Call the next step after successful API call
      // if (typeof nextStep === "function") {
      //   nextStep();
      // }
    } catch (error) {
      console.error("Submission error: ", error);
      alert("An error occurred while submitting the form.");
    }
  };

  const createTeamMemberApi = async (userDetails) => {
    let payload = {
      ...userDetails,  // Spread existing form data
      userid: userID,
      created_by: userID,
      username: userDetails?.username?.trim(),
      confirmEmail: userDetails?.confirmEmail?.trim()
    };
    // Apply validation if type is "SCOUT"
    if (userDetails?.type === "SCOUT") {
      payload = {
        ...payload,
        propertyLimit: 1,
        security_domain_id: 1,
        spendingLimit: 1,
      };
    }

    try {
      const response = await createTeamMember(payload); // Await the async function
      if (response.success) {
        console.log("create successful:", response.teamMember);
      } else {
        console.error("create failed:", response.error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }

  };

  const createTeamMember = async (teamMemberData) => {
    try {
      const response = await apiService.put('/ps/teammember', teamMemberData);
      if (response.status === 200 || response.status === 201) {
        return { success: true, data: response.data };
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const handleCancel = (currentStep = "") => {
    if (currentStep?.screen !== "1" && !canCreate) {
      leaveMessagePopup({
        CancelRedirect: () => null,
        yesRedirect: () => history.push("/accountnew/landing"),
      });
    } else {
      history.push("/accountnew/landing")
    }
  };

  return (
    <div className={mainCss.clsInnerMainBody}>
      <div>
        <FormProvider>
          <MultiStepForm steps={steps} onSubmit={handleSubmit} onCancel={handleCancel} history={history} redirectPath="/accountnew/landing"
            onNext={handleNextStep}
          />
        </FormProvider>
      </div>
    </div>
  );
};


export default withRouter(connect(null, {
  leaveMessagePopup: LeaveMessagePopup.open
})(TeamScreen));