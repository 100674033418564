import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap/lib";
import BillingSection from "./Sections/BillingSection";
import LeadAutomator from "./Sections/LeadAutomator";
import PlanSection from "./Sections/PlanSection";
import PrepaidCreditsSection from "./Sections/PrepaidCreditsSection";
import ProfileSection from "./Sections/ProfileSection";
import TeamSection from "./Sections/TeamSection";
import UsageSections from "./Sections/UsageSections";
import MarketingProfileSections from "./Sections/MarketingProfileSections";

import css from "./style.scss";
import AccountHeader from "./AccountHeader";
import { selecFullAccount } from "data/user/selectors";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "data/user/selectors";
import { loadWalletList } from "data/user/actions";

const MainPage = ({ children }) => {
  return (
    <>
      {/* header code */}
      <div className={css.clsPageWrapper}>
        <AccountHeader />
        <div className={css.clsBodyMain}>{children}</div>
      </div>
    </>
  );
};

export const LandingPage = () => {
  const isFullAccount = useSelector(selecFullAccount);

   const userID = useSelector(selectUserId)
    const dispatch = useDispatch();

  // LOAD api WHICH ARE REQUIRED for all screens
   useEffect(() => {
      if(userID) {
        dispatch(loadWalletList(userID))
      }
    }, [userID])

  return (
    <>
    {isFullAccount ? (
      <div className={css.clsCardsMain}>
        <div className={css.clsCardsLeft}>
          <ProfileSection isFullAccount={isFullAccount} />
        
        </div>
        <div className={css.clsCardsRight}>
          <UsageSections  isFullAccount={isFullAccount} />
          <LeadAutomator isFullAccount={isFullAccount} />
        </div>
      </div>
    ) : (
      <div className={css.clsCardsMain}>
        <div className={css.clsCardsLeft}>
          <ProfileSection />
          <MarketingProfileSections />
          <PrepaidCreditsSection />
          <BillingSection />
        </div>
        <div className={css.clsCardsRight}>
          <PlanSection />
          <UsageSections />
          <LeadAutomator />
          <TeamSection />
        </div>
      </div>
    )}
    </>
  );
};

export default MainPage;
