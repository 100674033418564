import userImg from 'assets/images/account-page/user-icon.png';
import classNames from "classnames";
import { selectUserId, selectUserProfile } from "data/user/selectors";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import EditUserEmailPasswordPopup from "../modals/editUserEmailPassword";
import EditUserProfilePopup from "../modals/editUserProfile";
import css from '../style.scss';

import config from 'config';
import { loadUserProfileSuccess } from "data/user/actions";
import ErrorDisplay from '../components/ErrorDisplay';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const ProfileSection = (props) => {

  const [errorFetched, setErrorFetched] = useState(0);

  const {isFullAccount} = props

  const userID = useSelector(selectUserId);
  const selectUserProfileSection = useSelector(selectUserProfile).toJS();


  const dispatch = useDispatch();


  useEffect(() => {
    if (userID) {
      getUserProfile()
    }
  }, [userID])

  const openEditUserProfile = () => {
    props.EditUserProfilePopup()
  }

  const openEditUserEmailPassword = () => {
    props.EditUserEmailPasswordPopup({ getUserProfile });
  }


  const getFullDataURL = (base64String) => {
    // Decode the Base64 string to check its format
    const binaryString = atob(base64String);
    // Get the first 4 bytes (magic number) as hexadecimal
    const first4Bytes = binaryString
      .slice(0, 4)
      .split("")
      .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
      .join(" ");
    // Determine the MIME type based on the magic number
    let mimeType = "";
    if (first4Bytes === "89 50 4e 47") {
      mimeType = "image/png";
    } else if (first4Bytes.startsWith("ff d8 ff")) {
      mimeType = "image/jpeg";
    } else if (binaryString.trim().startsWith("<svg")) {
      mimeType = "image/svg+xml";
    } else {
      throw new Error("Unknown image format");
    }
    // Construct the full Data URL
    return `data:${mimeType};base64,${base64String}`;
  }


  const getUserProfile = () => {
    fetch(`${PRICING_UPDATE_URL}/ps/profile/${userID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.userId) {
          dispatch(loadUserProfileSuccess(data))
          setErrorFetched(0);
        } else {
          setErrorFetched(prevCount => prevCount + 1);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorFetched(prevCount => prevCount + 1);
      });
  }

  return (
    <div className={classNames(css.clsCard, css.clsProfileCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <h4 className={classNames(css.clsCardTitle)}>Profile</h4>
    {!isFullAccount && (
        <a href="javascript:;" onClick={errorFetched ? undefined : openEditUserProfile} className={classNames(css.clsCardTxt, css.text_primary, {
        [css.disabled]: errorFetched,
      })}>Edit</a>
    )}
      </div>
      <div className={css.clsCardBody}>
        {errorFetched > 0 ? (<ErrorDisplay errorFetched={errorFetched} onReload={getUserProfile} />) :
          (
            <>
              <div className={css.clsProfileSec}>
                <div className={css.clsProfileImg}>
                  <img src={selectUserProfileSection?.profilePicture
                    ? getFullDataURL(selectUserProfileSection?.profilePicture)
                    : userImg} alt="User" />
                </div>
                <div className={css.clearfix}>
                  <h3 className={css.clsUserName}>{selectUserProfileSection.firstName} {selectUserProfileSection.lastName}</h3>
                  <h4 className={css.clsUserPost}>{selectUserProfileSection.owner}</h4>
                </div>
              </div>
              <div className={css.clsProfileContact}>
                <h3 className={css.clsContactLine1}>Email</h3>
                <h4 className={css.clsContactLine2}>{selectUserProfileSection.username}</h4>
              </div><div className={css.clsProfileContact}>
                <h3 className={css.clsContactLine1}>Phone</h3>
                <h4 className={css.clsContactLine2}>{selectUserProfileSection.phone}</h4>
              </div><div className={css.clsProfileContact}>
                <h3 className={css.clsContactLine1}>Address</h3>
                <h4 className={css.clsContactLine2}>
                  {selectUserProfileSection.addressLine1 || '-'} {selectUserProfileSection.addressLine2}<br></br>
                  {selectUserProfileSection.city} {selectUserProfileSection.state} {selectUserProfileSection.zipCode}
                </h4>
              </div>
            </>
          )}
      </div>
      {!isFullAccount && (
           <div className={classNames(css.clsCardFooter, css.text_center)}>
    <a
      href="javascript:;"
      onClick={errorFetched ? undefined : openEditUserEmailPassword}
      className={classNames(css.clsCardTxt, css.text_primary, {
        [css.disabled]: errorFetched,
      })}
    >
      Update Email and Password
    </a>
  </div>
)}
      
    </div>
  );
};

export default withRouter(connect(null, {
  EditUserProfilePopup: EditUserProfilePopup.open,
  EditUserEmailPasswordPopup: EditUserEmailPasswordPopup.open
})(ProfileSection));
