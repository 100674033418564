import leadLightIcon from 'assets/images/account-page/lead-auto-light-img.svg';
import classNames from "classnames";
import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import { CircularProgress } from "./UsageSections";
import { selectLeadAutomator, selectUserId } from '../../../data/user/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import config from 'config';
import { loadLeadAutomatorSuccess } from '../../../data/user';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const LeadAutomator = (props) => {

  const [errorFetched, setErrorFetched] = useState(0);
  const userID = useSelector(selectUserId);
  const selectLeadAutomatorSection = useSelector(selectLeadAutomator)?.toJS();
  const {isFullAccount} = props

  const dispatch = useDispatch();
  
  const goToLeadAutomator = () => {
    props.history.push(`/accountnew/leadautomator`);
  }

  useEffect(() => {
    if (userID) {
      getLeadAutomator()
    }
  }, [userID])
  
  const getLeadAutomator = () => {
    fetch(`${PRICING_UPDATE_URL}/ps/listautomator/users?userId=${userID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.userId) {
          dispatch(loadLeadAutomatorSuccess(data))
          setErrorFetched(0);
        } else {
          setErrorFetched(prevCount => prevCount + 1);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <div className={classNames(css.clsCard, css.clsLeadCard)}>
    <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
      <h4 className={classNames(css.clsCardTitle)}>Lead Automator</h4>
    {!isFullAccount && (
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={goToLeadAutomator}>Manage Plan</a>
    )}
    </div>

    <div className={css.clsCardBody}>
    {errorFetched > 0 ? (<ErrorDisplay errorFetched={errorFetched} onReload={getLeadAutomator} />) :
          (
            <>
{selectLeadAutomatorSection && selectLeadAutomatorSection.planTypeLA ? (
  <>
    <ul className={classNames(css.clsLeadList, css.unstyled)}>
    <li className={classNames(css.clsBorderRight)}>
        <div className={css.clsLeadContent}>
          <h5 className={css.clsLeadContent1}> Plan Type </h5>
          <h4 className={css.clsLeadContent2}>{selectLeadAutomatorSection.planTypeLA || "TESTJFUFI"}</h4>
        </div>
      </li>
      <li>
        <div className={css.clsLeadContent}>
          <h5 className={css.clsLeadContent1}>Monitored Properties </h5>
          <h4 className={css.clsLeadContent2}>{selectLeadAutomatorSection.monitoredProperties}</h4>
        </div>
      </li>
    </ul>
    </>

) : (
  <div className={css.clsLeadContentCard}>
  <h4 className={css.clsLeadContentTxt1}>
    Take the guesswork out of maintaining and updating lead lists. With Lead Automator, you can:
  </h4>

  <div className={css.clsLeadContentList}>
    <ul className={css.clsLeadListLeft}>
      <li>
        <span className={css.clsLeadListChek}></span>
        Add new leads as they appear in our system
      </li>
      <li>
        <span className={css.clsLeadListChek}></span>
        Remove ones that no longer match your criteria
      </li>
      <li>
        <span className={css.clsLeadListChek}></span>
        Get notifications regarding changes to your list
      </li>
    </ul>
    <div className={css.clsLeadContenRight}>
      <img src={leadLightIcon} />
    </div>
  </div>

  <div className={classNames(css.mt_18, "text-right")}>
    <button className={classNames(css.text_primary, css.btn_primary, css.clsBtnWhite)} onClick={goToLeadAutomator}> Try Lead Automator for 14 Days Free</button>
  </div>
</div>
)}

  </>
)}
       
    </div>

    {selectLeadAutomatorSection && selectLeadAutomatorSection.planTypeLA ? ( <div className={classNames(css.clsCardBody, css.clsBorderTop)}>
      <div className={css.clsLeadBodyContent}>
        {/* <img src={groupIocn} className={css.mr_5px} /> */}

             <CircularProgress
                percentage={100 - 76}
                type="setting"
                className={css.mr_5px}
              />

        <div className={css.clsLeadContentTxt}>
          <h5 className={classNames(css.clsLeadContent1, css.monitoredRemaining)}>
            Monitored Properties Remaining
          </h5>
          <h4 className={css.clsLeadContent2}>{selectLeadAutomatorSection.monitoredPropertiesRemaining}</h4>
          <div className={css.clsLeadContent3}>
            <h6>{selectLeadAutomatorSection.monitoredPropertiesUsed}</h6>
             <span className={css.clsBorder}></span>
             <h6>{selectLeadAutomatorSection.percentOfUsedProperties}</h6>
             <h6>Used</h6>
          </div>
        </div>
      </div>
    </div>) : (<></>)}

   
    
  </div>
  );
};

export default withRouter(connect(null, {})(LeadAutomator));
