import { createSelector } from 'reselect';
import { List } from 'immutable';
import { ReportTypes, Reports, Permissions, planTypes } from './constants';


const emptyList = List();

const checkState = state => state;

export const selectUser = createSelector(
  checkState,
  state => state.get('user'),
);

export const selectProfile = createSelector(
  selectUser,
  user => user.get('profile'),
);

export const getMarketingUrl = (profile, type) => profile.get(`${type.name.toLowerCase()}Url`);

export const selectPermissions = createSelector(
  selectUser,
  user => user.get('permissions'),
);

export const selectFullAccessPermission = createSelector(
  selectPermissions,
  permissions => !!(permissions && permissions.includes(Permissions.searchFull)),
);

export const selectPages = createSelector(
  selectUser,
  user => user.get('pages', emptyList),
);

export const selectReports = createSelector(
  selectUser,
  user => user.get('reports', emptyList).sort((a, b) => a.get('seq') - b.get('seq')),
);

export const selectAlerts = createSelector(
  selectUser,
  user => user.get('alerts', emptyList),
);

export const selectPremiumReports = createSelector(
  selectReports,
  reports => reports.filter(report => report.get('type') === ReportTypes.premium),
);

export const selectPropertyReports = createSelector(
  selectReports,
  reports => reports.filter(report => (report.get('type') === ReportTypes.property || report.get('type') === ReportTypes.analysis) && report.get('code') !== Reports.comparativeMarketAnalysis),
);

export const selectAnalysisReports = createSelector(
  selectReports,
  reports => reports.filter(report => report.get('type') === ReportTypes.analysis),
);

export const selectIsAuthed = createSelector(
  selectUser,
  user => user.get('isAuthed'),
);

export const selectIsLoading = createSelector(
  selectUser,
  user => user.get('isLoading'),
);

export const selectLoading = createSelector(
  selectUser,
  user => user.get('loading'),
);

export const selectAlertLoading = createSelector(
  selectUser,
  user => user.get('alertLoading'),
);

export const selectOffers = createSelector(
  selectUser,
  user => user.get('offers'),
);

export const selectOfferLoading = createSelector(
  selectUser,
  user => user.get('offerLoading'),
);

export const selectUsers = createSelector(
  selectUser,
  user => user.get('users'),
);

export const selectTransactions = createSelector(
  selectUser,
  user => user.get('transactions'),
);

export const selectLayouts = createSelector(
  selectUser,
  user => user.get('layouts'),
);

export const selectDuplicateSession = createSelector(
  selectUser,
  user => user.get('duplicateSession'),
);

export const selectLoggedOut = createSelector(
  selectUser,
  user => user.get('loggedOut'),
);

export const selectChatVisible = createSelector(
  selectUser,
  user => user.get('chatVisible'),
);

export const selectLogoutMessage = createSelector(
  selectUser,
  user => user.get('logoutMessage'),
);

export const selectPropertyGridLayout = createSelector(
  selectUser,
  user => user.get('pages').find(p => p.get('type') === 'GRID' && p.get('code') === 'SAVED_PROPERTY'),
);

export const selectPropertyDetailLayouts = createSelector(
  selectUser,
  user => user.get('pages').filter(p => p.get('type') === 'PROPERTY'),
);

export const selectCardCodeRequired = createSelector(
  selectUser,
  user => user.get('cardCodeRequired'),
);

export const selectListManagementEnabled = createSelector(
  selectProfile,
  profile => profile.get('listManagementEnabled'),
);

export const selectPabEnabled = createSelector(
  selectProfile,
  profile => profile.get('pabEnabled'),
);

export const selectUserId = createSelector(
  selectProfile,
  profile => profile.get("id"),
);

// marketing Profile
export const selectMarketingProfile = createSelector(
  selectUser,
  user => user.get('marketingProfile'),
);
export const selectUserProfile = createSelector(
  selectUser,
  user => user.get('userProfile'),
);
export const selectPrepaidCredits = createSelector(
  selectUser,
  user => user.get('prepaidCredits'),
);

export const selectPlanDetails = createSelector(
  selectUser,
  user => user.get('planDetails'),
);
export const selectLeadAutomator = createSelector(
  selectUser,
  user => user.get('leadAutomator'),
);


export const selectTeams = createSelector(
  selectUser,
  user => user.get('teams'),
);

export const selectTeamMembers = createSelector(
  selectTeams,
  teams => teams.get('teamMembers').toJS(),
);

export const selectSelectedTeamMember = createSelector(
  selectTeams,
  teams => teams.get('selectedTeamMember'),
);


export const selectUserPlan = createSelector(
  selectUser, // Assuming selectUser gets the user state
  user => user.get('plan') // Extracts the user's plan (e.g., "Pro", "Elite")
);

export const selectTeamCounts = createSelector(
  selectTeams,
  teams => teams.get('teamCounts').toJS(),
);

// Selector to check if full access creation is allowed
export const selectCanCreateFullAccess = createSelector(
  selectUserPlan,  // Assuming this selector returns the user's current plan (e.g., 'Pro', 'Elite')
  selectTeamCounts,
  (userPlan, counts) => {
    const plan = planTypes.find(plan => plan.id === userPlan);
    if (!plan) return false;

    return counts.fullAccessCount < plan.maxfull;
  }
);

export const selectShowFinishBuildingYourTeam = createSelector(
  selectUserPlan,
  selectTeamCounts,
  (userPlan, counts) => {
    const plan = planTypes.find(plan => plan.id === userPlan);
    return plan ? counts.fullAccessCount < plan.preLoaded : false;
  }
);


// this need to move 
export const selectPaidFullAccess = createSelector(
  selectUserPlan,
  selectTeamCounts,
  (userPlan, counts) => {
    const plan = planTypes.find(plan => plan.id === userPlan);
    if (!plan) return { canCreate: false, isPaidUser: false };

    if (counts.fullAccessCount >= plan.maxfull) {
      return { canCreate: false, isPaidUser: false }; // Max limit reached, cannot create
    }

    if (counts.fullAccessCount >= plan.preLoaded) {
      return { canCreate: true, isPaidUser: true }; // Paid user warning
    }

    return { canCreate: true, isPaidUser: false }; // Free user, within limits
  }
);


export const selectIsPaidUser = createSelector(
  selectTeams,
  teams => teams?.get('isPaidUser')
);

export const selectCanCreate = createSelector(
  selectTeams,
  teams => teams?.get('canCreate')
);


export const selecFullAccount = createSelector(
  selectProfile,
  // profile => false
    profile => !profile.get("fullAccount")
);

// billing

export const selectBillingState = createSelector(
  selectUser,
  user => user.get('billing'),
);

export const selectBillingDashboard = createSelector(
  selectBillingState,
  user => user.get('dashboard').toJS(),
);


export const selectWalletList = (state) => selectBillingState(state).get('wallet').toJS()

export const selectWalletIsLoading = createSelector(
  selectBillingState,
  billing => billing.get('isLoading'),
);

export const selectCardList = createSelector(
  selectBillingState,
  billing => billing.get('cardList').toJS(),
);

export const selectDefaultPaymentSetting = createSelector(
  selectBillingState,
  billing => billing.get('defaultPaymentSettingList').toJS(),
);

export const selectBillingHistory = createSelector(
  selectBillingState,
  billing => billing.get('billingHistory').toJS(),
);

export const selectPaymentMethodById = (state, paymentMethodId) =>
  selectWallet(state).find((method) => method.get('id') === paymentMethodId);
