import config from 'config';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const API_BASE_URL = PRICING_UPDATE_URL;


const defaultHeaders = {
  'Content-Type': 'application/json',
};



const apiService = {
  get: async (endpoint, params = {}) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': window.appAuthToken, // Always fetch latest token
    };
    try {
      const url = new URL(`${API_BASE_URL}${endpoint}`);

      // Append query parameters (if any)
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      return handleResponse(response);
    } catch (error) {
      console.error('API GET Error:', error);
      throw error;
    }
  },

  post: async (endpoint, body) => {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(body),
      });

      return handleResponse(response);
    } catch (error) {
      console.error('API POST Error:', error);
      throw error;
    }
  },

  put: async (endpoint, body) => {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: defaultHeaders,
        body: JSON.stringify(body),
      });

      return handleResponse(response);
    } catch (error) {
      console.error('API PUT Error:', error);
      throw error;
    }
  },

  delete: async (endpoint,body = null) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': window.appAuthToken, // Always fetch latest token
    };
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers,
        ...(body ? { body: JSON.stringify(body) } : {})
      });

      return handleResponse(response);
    } catch (error) {
      console.error('API DELETE Error:', error);
      throw error;
    }
  },
};

// Common response handler
const handleResponse = async (response) => {
  const status = response.status; // Capture status before parsing
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(`Error: ${status} - ${responseData.message || 'Unknown error'}`);
  }
  return { status, data: responseData };
};

export default apiService;
