import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import leftArrow from "assets/images/account-page/left-arrow.svg";
import css from "./style.scss";

const AccountHeader = ({ location, history }) => {
  // Define the mapping for routes and titles
  const routeTitleMap = {
    "/accountnew/billing": "Billing",
    "/accountnew/teams": "Teams",
    "/accountnew/profile": "Profile",
    "/accountnew/leadautomator": "Lead Automator",
    "/accountnew/manageleadautomator": "Manage Lead Automator",
    "/accountnew/teamdowngrade": "Manage Team Member",
    "/accountnew/team": "New Team Member"
  };

  // Find the section title based on the current route
  const sectionTitle = useMemo(() => {
    return Object.entries(routeTitleMap).find(([path]) =>
      location.pathname.startsWith(path)
    )?.[1] || "Account";
  }, [location.pathname]);

  const showBackButton = sectionTitle !== "Account";

  return (
    <div className={css.clsMainHeader}>
      {showBackButton ? (
        <>
          <a
            onClick={() => history.push("/accountnew/landing")}
            className={classNames(
              css.text_link_hover, css.backIcon)}
            aria-label="Go back"
          >
            <img src={leftArrow} alt="Back" />
          </a>
          <div className={css.clearfix}>
            <h5 className={css.clsHeaderSubHeading}>ACCOUNT</h5>
            <h4 className={css.clsHeaderHeading}>{sectionTitle}</h4>
          </div>
        </>
      ) : (
        <h4 className={css.clsHeaderHeading}>Account</h4>
      )}
    </div>
  );
};

export default withRouter(AccountHeader);
