

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { Form, Formik } from "formik";
import React, { useEffect } from 'react';
import { isValidEmail, isValidPhone } from 'utils/validation';
import * as Yup from "yup";
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

import { UsStateOptions } from 'data/user/constants';
import { selectMarketingProfile } from "data/user/selectors";
import { useDispatch, useSelector } from 'react-redux';
import FormikSelect from '../components/Formik/FormikSelect';

import config from 'config';
import { loadMarketingProfileSuccess } from "data/user/actions";
import { useState } from 'react';
import UploadProfilePicture from '../components/uploadProfilePicture';
import { getMarketingProfileValidationRules } from '../../../utils/validation';

const {
    constants: {
        API: { PRICING_UPDATE_URL },
    },
} = config;

const EditMarketingProfilePopup = (props) => {
    const dispatch = useDispatch();
    const selectMarketingProfileSection = useSelector(selectMarketingProfile).toJS();
    const [imageUrl, setImageUrl] = useState(selectMarketingProfileSection?.marketingProfilePicture || '');
     const [isNewMarketingProfile, setMarketingProfile] = useState(true)
    
     const validationRules = getMarketingProfileValidationRules();

       useEffect(() => {
         if (selectMarketingProfileSection) {
           if(selectMarketingProfileSection?.businessAddressLine1 && selectMarketingProfileSection?.businessCity && selectMarketingProfileSection?.businessState && selectMarketingProfileSection?.businessZipCode) {
             setMarketingProfile(true)
           } else {
             setMarketingProfile(true)
           }
         }
       }, [selectMarketingProfileSection])

// Validation schema
const validationSchema = Yup.object({
    businessFirstName: Yup.string().required("First Name is required"),
    businessLastName: Yup.string().required("Last Name is required"),
    businessPhone: Yup.string()
        .test(
            'is-valid-phone',
            'Business Phone must be exactly 10 digits',
            (value) => {
                if (!value) return true; // no validations if the value is empty
                const digitsOnly = value.replace(/\D/g, ''); // Remove non-digit characters
                return digitsOnly.length === 10;
            }
        ),
    businessFullName: Yup.string()
        .max(50, 'Business Name cannot exceed 50 characters'),
    businessEmail: Yup.string()
        .test('is-valid-email', 'Business Email is invalid', (value) =>
            value ? isValidEmail(value.trim()) : true
        ),
    businessAddressLine1: Yup.string().required("Address is required"),
        businessCity: Yup.string().required("City is required"),
        businessState: Yup.string().required("State is required"),
        businessZipCode: Yup.string().required("Zip Code is required")
            .matches(/^\d{5}(-?\d{4})?$/, "Zip Code is invalid"),
    });
    
    const getFullDataURL = (base64String) => {
        // Decode the Base64 string to check its format
        const binaryString = atob(base64String);

        // Get the first 4 bytes (magic number) as hexadecimal
        const first4Bytes = binaryString
            .slice(0, 4)
            .split("")
            .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
            .join(" ");

        // Determine the MIME type based on the magic number
        let mimeType = "";
        if (first4Bytes === "89 50 4e 47") {
            mimeType = "image/png";
        } else if (first4Bytes.startsWith("ff d8 ff")) {
            mimeType = "image/jpeg";
        } else if (binaryString.trim().startsWith("<svg")) {
            mimeType = "image/svg+xml";
        } else {
            throw new Error("Unknown image format");
        }

        // Construct the full Data URL
        return `data:${mimeType};base64,${base64String}`;
    }

    // Ensure `initialValues` contains a valid object
    const initialValues = {
        businessFirstName: selectMarketingProfileSection?.businessFirstName || '',
        businessLastName: selectMarketingProfileSection?.businessLastName || '',
        businessFullName: selectMarketingProfileSection?.businessFullName || '',
        businessPhone: selectMarketingProfileSection?.businessPhone || '',
        businessWebsite: selectMarketingProfileSection?.businessWebsite || '',
        businessEmail: selectMarketingProfileSection?.businessEmail || '',
        businessAddressLine1: selectMarketingProfileSection?.businessAddressLine1 || '',
        businessAddressLine2: selectMarketingProfileSection?.businessAddressLine2 || '',
        businessCity: selectMarketingProfileSection?.businessCity || '',
        businessState: selectMarketingProfileSection?.businessState || '',
        businessZipCode: selectMarketingProfileSection?.businessZipCode || '',
        marketingProfilePicture: selectMarketingProfileSection?.marketingProfilePicture || '',
        emailFriendlyFrom: selectMarketingProfileSection?.emailFriendlyFrom || '',
        replyToEmail: selectMarketingProfileSection?.replyToEmail || ''
    };

    const handleClose = () => {
        props.closePopup();
    };

    const handleSubmit = (values) => {
        let payload = { ...selectMarketingProfileSection, ...values }
        fetch(`${PRICING_UPDATE_URL}/ps/profile/marketing`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    if(response.status === 400) {                        
                        const errorMessage = response.text(); // Get response body for debugging
                       throw new Error(`Failed with status ${response.status}: ${errorMessage}`);
                    }
                }
            })
            .then((data) => {
                console.log('Response from API:', data);
                dispatch(loadMarketingProfileSuccess(data));
                handleClose()
            })
            .catch((error) => {
                console.error("Error occurred:", error.message);
                console.error('Error:', error);
            });
    };


    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccEditMarketingProfileModal)} isOpen uniqId="EditMarketingProfilePopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                    <div className={classNames(css.clsModalContentBody, "modal-body")}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                                handleSubmit(values)
                        }}
                    >
                        {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className={css.clsModalHeader}>
                                    <h2 className={css.clsModalTitle}>{isNewMarketingProfile ?  'Edit' : 'Create' } Marketing Profile Info</h2>
                                </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> Business Information </h4>

                                                <div className={classNames(css.rowInfo1, "flex-column-reverse flex-lg-row row")}>
                                                    <div className={classNames(css.col_lg_8, "col-lg-8 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="businessFirstName" label="First Name" placeholder="First Name" errors={errors} touched={touched} required={true}
                                                            maxLength={validationRules.maxLength.firstName}
                                                            validationPattern={validationRules.validationPattern.firstName}
                                                            />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessLastName" label="Last Name" placeholder="Last Name" errors={errors} touched={touched} required={true} 
                                                             maxLength={validationRules.maxLength.lastName}
                                                             validationPattern={validationRules.validationPattern.lastName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_4, "col-lg-4 col-md-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                                                            <label className={classNames(css.clsFormLabel)}>  Profile Picture </label>
                                                            <div className={css.clsSelectProfileImg}>
                                                                <div className={css.clstProfileImg}>
                                                                {imageUrl ? (
                                                                    <img src={getFullDataURL(imageUrl)} alt="Profile" />
                                                                ) : (
                                                                    <img src={userImg} alt="Profile" />
                                                                )}
                                                            </div>
                                                                <UploadProfilePicture key={imageUrl} label={imageUrl ? 'Edit Photo' : 'Add Photo'} filed={'marketingProfilePicture'} setFieldValue={setFieldValue} setImageUrl={setImageUrl} />
                                                                {imageUrl && (
                                                                    <a
                                                                        href="#"
                                                                        className={css.clsAddTxt}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setImageUrl(null);
                                                                            setFieldValue("marketingProfilePicture", null); // Set form value to null
                                                                        }}
                                                                    >
                                                                        Remove Photo
                                                                    </a>
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessFullName" label="Business Name" errors={errors} placeholder="Business Name" touched={touched} 
                                                             maxLength={validationRules.maxLength.businessName}
                                                             validationPattern={validationRules.validationPattern.businessName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessPhone" label="Business Phone" errors={errors} placeholder="Business Phone" touched={touched} 
                                                             maxLength={validationRules.maxLength.businessPhone}
                                                             validationPattern={validationRules.validationPattern.businessPhone}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessWebsite" label="Business Website" errors={errors} placeholder="Business Website" touched={touched} 
                                                             maxLength={validationRules.maxLength.businessPhone}
                                                             validationPattern={validationRules.validationPattern.businessPhone}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessEmail" label="Business Email" errors={errors} placeholder="Business Email" touched={touched} 
                                                             maxLength={validationRules.maxLength.businessEmail}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="emailFriendlyFrom" label="Email Friendly From" errors={errors} placeholder="Email Friendly From" touched={touched} 
                                                             maxLength={validationRules.maxLength.emailFriendlyFrom}
                                                             validationPattern={validationRules.validationPattern.emailFriendlyFrom}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="replyToEmail" label="Reply To Email" errors={errors} placeholder="Reply To Email" touched={touched} 
                                                              maxLength={validationRules.maxLength.replyToEmail}
                                                              validationPattern={validationRules.validationPattern.replyToEmail}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.mb_24, "text-left")}> Business Address </h4>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessAddressLine1" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} required={true} 
                                                             maxLength={validationRules.maxLength.addressLine1}
                                                             validationPattern={validationRules.validationPattern.addressLine1}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessAddressLine2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} 
                                                              maxLength={validationRules.maxLength.addressLine2}
                                                              validationPattern={validationRules.validationPattern.addressLine2}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessCity" label="City" placeholder="City" errors={errors} touched={touched} required={true} 
                                                             maxLength={validationRules.maxLength.city}
                                                             validationPattern={validationRules.validationPattern.city}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.rowAddressInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <label
                                                                    className={classNames(
                                                                            css.clsFormLabel,
                                                                            errors['businessState'] && touched['businessState'] && css.text_danger
                                                                        )}
                                                                    >State<span className={css.text_primary}>*</span></label>
                                                                    <FormikSelect
                                                                    name="businessState"
                                                                    label=""
                                                                    placeholder="State"
                                                                    options={UsStateOptions}
                                                                    showError={true}
                                                                    className={css.clsFormSelect}
                                                                    required={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <FormikText name="businessZipCode" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} required={true} 
                                                                      maxLength={validationRules.maxLength.zipCode}
                                                                      validationPattern={validationRules.validationPattern.zipCode}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter, css.d_flex, css.justify_content_center)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng, css.ml_20)} type="submit" >  Save </button>
                                    </div>
                            </Form>
                        )}
                    </Formik>
                    </div>

                </div>
            </div>
        </Modal>
    );
};


const registrationId = getPopupRegistration(EditMarketingProfilePopup);
EditMarketingProfilePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditMarketingProfilePopup.close = () => closePopup({ popup: registrationId });

export default EditMarketingProfilePopup;