

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { ErrorMessage, Form, Formik } from "formik";
import React from 'react';
import { isValidEmail } from 'utils/validation';
import * as Yup from "yup";
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

import config from 'config';
import { loadUserProfileSuccess } from "data/user/actions";
import { selectUserProfile } from "data/user/selectors";
import { useDispatch, useSelector } from 'react-redux';
import encryptPassword from '../../../utils/passwordValidation';

const {
    constants: {
        API: { PRICING_UPDATE_URL },
    },
} = config;

const EditUserEmailPasswordPopup = (props) => {

    const dispatch = useDispatch();
    const selectUserProfileSection = useSelector(selectUserProfile).toJS();

    // Validation schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .required("Email is required.")
            .test('is-valid-email', 'Email is invalid.', (value) =>
                value ? isValidEmail(value.trim()) : true
            )
            .oneOf([Yup.ref('confirmEmail')], 'Emails do not match.'),
        confirmEmail: Yup.string()
            .required("Confirm Email is required")
            .test('is-valid-email', 'Confirm Email is invalid.', (value) =>
                value ? isValidEmail(value.trim()) : true
            )
            .oneOf([Yup.ref('email')], 'Emails do not match.'),

        currentPwd: Yup.string().required("Current Password is required"),
        newPwd: Yup.string().required("New Password is required")
            .oneOf([Yup.ref('confirmNewPwd')], 'Passwords do not match.'),
        confirmNewPwd: Yup.string().required("Confirm New Password is required")
            .oneOf([Yup.ref('newPwd')], 'Passwords do not match.'),
    });

    const initialValues = {
        email: '',
        confirmEmail: '',
        currentPwd: '',
        newPwd: '',
        confirmNewPwd: '',
    };

    const handleClose = () => {
        props.closePopup();
    };


    const handleSubmit = (values) => {
        let payload = { ...values,
            userId: selectUserProfileSection.userId, 
            currentPwd: encryptPassword(values.currentPwd),
            newPwd: encryptPassword(values.newPwd),
            confirmNewPwd: encryptPassword(values.confirmNewPwd),
        }
        fetch(`${PRICING_UPDATE_URL}/ps/profile/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    const errorMessage = response.text(); // Get response body for debugging
                    throw new Error(`Failed with status ${response.status}: ${errorMessage}`);
                }
            })
            .then((data) => {
                console.log('Response from API:', data);
                dispatch(loadUserProfileSuccess(data));
                props.getUserProfile();
                handleClose()
            })
            .catch((error) => {
                console.error("Error occurred:", error.message);
                console.error('Error:', error);
            });
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccUpdateProfileModal)} isOpen uniqId="EditUserEmailPasswordPopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                   <div className={classNames(css.clsModalContentBody, "modal-body")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values, errorMessage }) => (
                                <Form onSubmit={handleSubmit}> 
                                <div className={css.clsModalHeader}>
                                    <h2 className={css.clsModalTitle}>Update Email and Password</h2>
                                </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_24, "text-left")}>Email/Username</h4>

                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.pb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="email" label="Email/Username" placeholder="Email Address" errors={errors} touched={touched} required={true} />
                                                            {/* <ErrorMessage name="email" component="p" className={css.clsFormErrorText} render={errorMessage} /> */}
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="confirmEmail" label="Confirm Email/Username" placeholder="Confirm Email Address" errors={errors} touched={touched} required={true} />
                                                            {/* <ErrorMessage name="confirmEmail" component="p" className={css.clsFormErrorText} render={errorMessage} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_24, "text-left")}> Password </h4>

                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.mb_24, css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="currentPwd" type="password" label="Enter Current Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                            {/* <ErrorMessage name="currentPwd" component="p" className={css.clsFormErrorText} render={errorMessage} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="newPwd" type="password" label="New Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                            {/* <ErrorMessage name="newPwd" component="p" className={css.clsFormErrorText} render={errorMessage} /> */}
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="confirmNewPwd" type="password" label="Confirm New Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                            {/* <ErrorMessage name="confirmNewPwd" component="p" className={css.clsFormErrorText} render={errorMessage} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit" >Save </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

        </Modal >
    );
};


const registrationId = getPopupRegistration(EditUserEmailPasswordPopup);
EditUserEmailPasswordPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditUserEmailPasswordPopup.close = () => closePopup({ popup: registrationId });

export default EditUserEmailPasswordPopup;
