// src/components/MultiStepForm/FormStep.js

import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import css from "./style.scss";
import classNames from "classnames";

const FormStep = ({
  defaultValues,
  validationSchema,
  onNext,
  onBack,
  backButtonLabel = "Back",
  showNextButton = true,
  showBackButton = true,
  showCancelButton = false,
  CancelButtonLabel = 'Cancel',
  continueButtonLabel = 'Continue',
  onCancel,
  children,
}) => {
  const methods = useForm({
    defaultValues,
    resolver: async (data) => {
      try {
        await validationSchema.validate(data, { abortEarly: false });
        return { values: data, errors: {} };
      } catch (validationErrors) {
        if (validationErrors.inner) {
          const errors = validationErrors.inner.reduce((allErrors, currentError) => {
            allErrors[currentError.path] = { message: currentError.message };
            return allErrors;
          }, {});
          
          return { values: {}, errors };
        }
        // Handle edge cases where validationErrors.inner is undefined
        return {
          values: {},
          errors: { form: { message: validationErrors.message || "Validation failed" } },
        };
      }
    },
    mode: "onSubmit",
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = (values) => {
    console.log(values,'datadata')
    onNext(values); // Proceed to the next step
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>{children}</div>
        <div className={css.clsInnerFooter}>

        {showCancelButton && (
            <button
              className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)}
              type="button"
              onClick={onCancel}
            >
              {CancelButtonLabel}
            </button>
          )}

          {showBackButton && onBack && (
            <button
              className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)}
              type="button"
              onClick={onBack}
            >
              {backButtonLabel}
            </button>
          )}
          {showNextButton && (
            <button
              type="submit"
              className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}
              disabled={formState.isSubmitting}
            >
              {continueButtonLabel}
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default FormStep;