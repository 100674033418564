import React from 'react'
import Modal from "components/base/Modal";
import classNames from "classnames";
import css from "../style.scss";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";

const LeaveMessagePopup = ({ yesLable = 'Yes', cancelLable = 'Cancel' , title, message, closePopup, CancelRedirect, yesRedirect , autoWidth = false }) => {

  const handleClose = () => {
    closePopup();
  };

  const handleCancel = () => {
    if (CancelRedirect) {
      CancelRedirect(); // Execute the cancel redirect logic
    }
    closePopup();
  };

  const handleYes = () => {
    if (yesRedirect) {
      yesRedirect(); // Execute the yes redirect logic
    }
    closePopup();
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="LeaveMessagePopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
      <div className={classNames(css.clsModalContent, "modal-content")}>
            <div className={classNames(css.confirmLeaveModalBody, "modal-body", (autoWidth ? css.autoWidth : ''))}>

            <div className={classNames(css.confirmLeaveModalHeader)}>
              <p className={classNames(autoWidth ? css.autoWidth : '')}>{title ? title : 'Are You Sure You Want to Leave?'}</p>
              <span>{message ? message : 'Your Changes Will Not Be Saved.'}</span>
            </div>

            <div className={classNames(css.clsModalFooter, "d-flex justify-content-end")}>
              {cancelLable ? (<button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleCancel}> Cancel</button>) : ''}
              <button className={classNames(css.btn_lg, css.clsBtnOrng)} onClick={handleYes} > {yesLable ? yesLable : 'Yes'} </button>
            </div>
          </div>
        </div>
        </div>

    </Modal>
  );
};

const registrationId = getPopupRegistration(LeaveMessagePopup);

LeaveMessagePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
LeaveMessagePopup.close = () => closePopup({ popup: registrationId });

export default LeaveMessagePopup;
