import leftArrow from "assets/images/account-page/left-arrow.svg";
import classNames from 'classnames';
import React from 'react';
import css from '../style.scss';


const AccountHeader = ({ title, subTitle, history }) => {

    return (
        <div className={css.clsMainHeader}>
            <>
                <a
                    onClick={() => history.push("/accountnew/landing")}
                    className={classNames(
                        css.text_link_hover, css.backIcon)}
                    aria-label="Go back"
                >
                    <img src={leftArrow} alt="Back" />
                </a>
                <div className={css.clearfix}>
                    <h5 className={css.clsHeaderSubHeading}>{subTitle}</h5>
                    <h4 className={css.clsHeaderHeading}>{title}</h4>
                </div>
            </>
        </div>
    );
}

export default AccountHeader;