import amex from 'assets/images/account-page/amex.svg';
import discover from 'assets/images/account-page/discover-card.svg';
import mc from 'assets/images/account-page/master-card.svg';
import visa from 'assets/images/account-page/visa-card.svg';

const CardTypeList = [
  { code: 'visa', name: 'Visa', image: visa },
  { code: 'mc', name: 'MasterCard', image: mc },
  { code: 'discover', name: 'Discover', image: discover },
  { code: 'amex', name: 'American Express', numberMask: '1111 111111 11111', codeLength: 4, codePosition: 'front', image: amex },
  { code: 'def',image: '' },
].map(c => ({
  ...c,
  numberMask: c.numberMask || '1111 1111 1111 1111',
  codeLength: c.codeLength || 3,
  codePosition: c.codePosition || 'back',
  valid: c.code !== 'def',
})).map(c => ({
  ...c,
  codeMask: '1111'.substr(0, c.codeLength),
  codePattern: new RegExp(`^\\d{${c.codeLength}}$`),
}));

const CardTypes = CardTypeList.reduce((m, t) => ({ ...m, [t.code]: t }), {});

const getCardType = (num) => {
  const d1 = Number(num.substr(0, 1));
  const d2 = Number(num.substr(0, 2));
  const d4 = Number(num.substr(0, 4));

  let code = 'def';
  const len = num.length;
  if ((len === 13 || len === 16) && d1 === 4) code = 'visa';
  else if (len === 16 && (d2 >= 51 && d2 <= 55)) code = 'mc';
  else if (len === 15 && (d2 === 34 || d2 === 37)) code = 'amex';
  else if (len === 16 && d4 === 6011) code = 'discover';

  return CardTypes[code];
};

export const inferCardType = num => CardTypes[{ 3: 'amex', 4: 'visa', 5: 'mc', 6: 'discover' }[(num || '').substring(0, 1)] || 'def'];


export const maskCardNumber = (cardNumber,type) => {
  const cardType = inferCardType(cardNumber)
  const { numberMask } = cardType;
  let maskedNumber = '';
  let cardIndex = 0;
  for (let i = 0; i < numberMask.length; i++) {
    if (numberMask[i] === ' ') {
      maskedNumber += ' ';
    } else if (cardIndex < cardNumber.length) {
      maskedNumber += (i < numberMask.length - 4) ?  `${type ? type : '.' }`  : cardNumber[cardIndex];
      cardIndex++;
    }
  }
  return maskedNumber;
};



export default getCardType;
