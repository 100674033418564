import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import downArrow from "assets/images/account-page/down-arrow.svg";
import classNames from "classnames";
import Modal from "components/base/Modal";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import css from "../style.scss";

import { addWalletAPI, loadWalletList, updateWalletAPI } from "data/user/actions";
import { CcMonthOptions, CountryMap, getCcYearOptions } from 'data/user/constants';
import { selectUserId } from "data/user/selectors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidCardExpiration, isValidCardNumber, validateCvv } from 'utils/validation';
import { inferCardType, maskCardNumber } from "../../../utils/payment";

// Validation schema
const validationSchema = Yup.object({
  nameOnCard: Yup.string().required("Card Name is required."),
  cardNumber: Yup.lazy((value, context) =>
    context?.parent?.paymentMethodId
      ? Yup.string() // No validation for edit case
      : Yup.string()
          .required("Credit Card Number is required.")
          .test("isValidCardNumber", "Credit Card Number is invalid.", (val) => {
            if (!val) return false; // Ensure value exists
            const cleanedValue = val.replace(/\D/g, ""); // Keep only digits
            return isValidCardNumber(cleanedValue); // ✅ Return validation result
          })
  ),
  cardExpiryMonth: Yup.string()
    .required("Expiration Month is required."),
  cardExpiryYear: Yup.string()
    .required("Expiration Year is required.")
    .test("isValidExpiration", function (value) {
      const { cardExpiryMonth } = this.parent; // Get cardExpiryMonth value
      // If both are missing, show a combined message
      if (!value && !cardExpiryMonth) {
        return this.createError({ message: "Expiration Month & Year are required." });
      }
      if (!value) {
        return this.createError({ path: "cardExpiryYear", message: "Expiration Year is required." });
      }
      if (!cardExpiryMonth) {
        return this.createError({ path: "cardExpiryMonth", message: "Expiration Month is required." });
      }
      // Validate expiration date
      if (!isValidCardExpiration(value, cardExpiryMonth)) {
        return this.createError({ message: "Credit card is expired." });
      }
      return true;
    }),
  cardCode: Yup.string()
    .required("Credit Card CVV is required.")
    .test("isValidCardCode", "Credit Card CVV is invalid.", function (value) {
      const { cardNumber } = this.parent; // Accessing cardNumber
      return value ? validateCvv(value, cardNumber) : true;
    }),

  billingStreetAddress: Yup.string().required("Address is required."),
  billingCity: Yup.string().required("City is required."),
  billingState: Yup.string().required("State is required."),
  billingZip: Yup.string().required("Zip Code is required.").matches(/^\d{5}(-?\d{4})?$/, "Zip Code is invalid."),
  // terms: Yup.boolean().oneOf([true], "You must accept the terms")
});

const AddPaymentPopup = (props) => {
  const [isEdit, setEdit] = useState(props?.type ? true : false)

    const userID = useSelector(selectUserId)

  const [cardDetails, setCardDetails] = useState(inferCardType(props?.data?.cardNumber || ''))
  const dispatch = useDispatch();

  let editPayment = props?.data
  const [month, year] = editPayment?.cardExp ? editPayment.cardExp.split("/") : ["", ""];
  const initialValues = {
    nameOnCard: isEdit ? editPayment?.cardName || "" : "",
    cardNumber: isEdit ?  maskCardNumber(editPayment?.cardNumber,'x') : "",
    cardExpiryMonth: isEdit ? month || "" : "",
    cardExpiryYear: isEdit ? year || "" : "",
    cardCode: "",
    billingStreetAddress: isEdit ? editPayment?.billingStreetAddress || "" : "",
    billingStreetAddress2: isEdit ? editPayment?.billingStreetAddress2 || "" : "",
    billingCity: isEdit ? editPayment?.billingCity || "" : "",
    billingState: isEdit ? editPayment?.billingState || "" : "",
    billingZip: isEdit ? editPayment?.billingZip || "" : "",
    defaultpayment: isEdit ? editPayment?.defaultpayment : "none",
    billingPaymentMethod: isEdit ? editPayment?.billingPaymentMethod || false : false,
    purchasePaymentMethod: isEdit ? editPayment?.purchasePaymentMethod || false : false,
    paymentMethodId: isEdit ? editPayment?.paymentMethodId || null : null,
  };

  const country = CountryMap.US;
  const { states, stateLabel, postalCodeLabel } = country;
  const stateOptions = (states || []).map(state => ({ label: state.name, value: state.code }));

  const handleClose = () => {
    props.closePopup();
  };

  const formatCardNumber = (num, mask) => {
    if (!mask) return num; // No mask, return as is
  
    let formatted = "";
    let numIndex = 0;
  
    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === "1" && num[numIndex]) {
        formatted += num[numIndex];
        numIndex++;
      } else if (mask[i] === " " && numIndex < num.length) {
        formatted += " ";
      }
    }
    return formatted;
  };

  const handleCardNumberChange = (value, setFieldValue) => {
    let num = value.replace(/\D/g, ""); // Remove non-digit characters
    const detectedCard = inferCardType(num);
    setCardDetails(detectedCard);
  
    // Get maximum length from numberMask (count all digits and spaces)
    const maxLength = detectedCard?.numberMask?.replace(/\s/g, "").length || 19;
  
      if (num.length > maxLength) {
        num = num.slice(0, 19);
      }

    // Limit the card number length
      const formattedValue = formatCardNumber(num, detectedCard?.numberMask);
      setFieldValue("cardNumber", formattedValue);
  };



  const handleCheckboxChange = (e, values, setFieldValue) => {
    const { name, checked } = e.target;
    // Update checkbox state
    setFieldValue(name, checked);
    // Compute the new state after updating the checkbox
    const isAnyCheckboxChecked =
      (name === "billingPaymentMethod" ? checked : values.billingPaymentMethod) ||
      (name === "purchasePaymentMethod" ? checked : values.purchasePaymentMethod);

    // Set `defaultpayment` based on the updated checkbox state
    setFieldValue("defaultpayment", isAnyCheckboxChecked ? "" : "none");
  };

  const handleDefaultpaymentChange = (e, values, setFieldValue) => {
    const { value } = e.target;
    setFieldValue("defaultpayment", value);
    if (value === "none") {
      // Uncheck both checkboxes when "None" is selected
      setFieldValue("billingPaymentMethod", false);
      setFieldValue("purchasePaymentMethod", false);
    }
  };


  const saveCardDetails = async (values) => {
    console.log("Form values", values);

    delete values.defaultpayment
    values.userId = userID
    values.billinCountry = 'US'

    if(values.cardNumber) {
      if(isEdit) {
        delete values.cardNumber
      } else {
        values.cardNumber = values?.cardNumber?.replace(/\D/g, "")
      }
    }
    
    try {
      const apiFunction = isEdit ? updateWalletAPI : addWalletAPI;
      const response = await apiFunction(values);
      if (response.success) {
        console.log(`${isEdit ? 'Update' : 'Add'} wallet successful:`, response);
        dispatch(loadWalletList(userID))
        handleClose()
      } else {
        console.error(`${isEdit ? 'Update' : 'Add'} wallet failed:`, response.error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="AddPaymentPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >

      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0")}>
        <div className={classNames(css.clsModalContent, "modal-content w-100")}>
          <div className={classNames(css.clsModalContentBody, "modal-body rounded")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}> {isEdit ? "Edit" : "Add New"}  Payment Method </h2>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                saveCardDetails(values)
              }}
            >
              {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>

                  <div className={classNames(css.clsModalBody, "align-items-start")}>
                    <div className="clearfix w-100">
                      {isEdit ? null : (
                        <>
                          <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}>Default Payment Usage</h4>
                          <div className={classNames(css.clsDefaltPay, "d-flex")}>
                            <div className={classNames(css.clsCustomCheckbox, css.pr_20)}>
                              <Field type="radio" id="radio1" name="defaultpayment" value="none"
                                onChange={(e) => handleDefaultpaymentChange(e, values, setFieldValue)}
                              />
                              <label className={css.clsLabeltxtLine1} htmlFor="radio1">   None  </label>
                            </div>

                            <div className={classNames(css.clsCustomCheckbox, css.pr_20)}>
                              <Field type="checkbox" id="checkbox1" name="billingPaymentMethod" value={true}
                                checked={values.billingPaymentMethod}
                                onChange={(e) => handleCheckboxChange(e, values, setFieldValue)}
                              />
                              <label className={css.clsLabeltxtLine1} htmlFor="checkbox1">Subscriptions</label>
                            </div>

                            <div className={css.clsCustomCheckbox}>
                              <Field type="checkbox" id="checkbox2" name="purchasePaymentMethod" value={true}
                                checked={values.purchasePaymentMethod}
                                onChange={(e) => handleCheckboxChange(e, values, setFieldValue)}
                              />
                              <label className={css.clsLabeltxtLine1} htmlFor="checkbox2">In-App Purchases</label>
                            </div>
                          </div>
                          <hr className='my-5'></hr>
                        </>
                      )}


                      <div className="clearfix pb-4">
                        <h4 className={css.clsRequiredTxt}>*Indicates Required Field</h4>
                        <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}> Card Info </h4>
                        <div className="row pt-2">
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="nameOnCard" label="Name on Card" placeholder="Name on Card" errors={errors} touched={touched} required={isEdit ? false : true} disabled={isEdit ? true : false} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")} style={{ position: 'relative', height: '110px' }}>
                            <FormField name="cardNumber" label="Card Number" placeholder="Card Number" errors={errors} touched={touched} required={isEdit ? false : true} disabled={isEdit ? true : false}
                             onChange={(value) => {
                              handleCardNumberChange(value, setFieldValue);
                            }}
                              className="pr-5"
                            />
                            {cardDetails?.valid && cardDetails?.image && (
                              <img
                                src={cardDetails?.image}
                                alt={cardDetails?.name}
                                className={css.cardTypeIcon}
                              />
                            )}
                          </div>
                          <div className={classNames(css.pb_20, "col-xl-4 col-lg-6 col-12")}>
                            <div className={css.clsFormGroup}>
                              <label
                                className={classNames(
                                  css.clsFormLabel,
                                  (errors['cardExpiryMonth'] && touched['cardExpiryMonth'] || errors['cardExpiryYear'] && touched['cardExpiryYear']) && css.text_danger
                                )}
                              > *Expiration Date </label>
                              <div className={css.clsInputGroup}>

                                <SelectField
                                  name="cardExpiryMonth"
                                  label=""
                                  placeholder="MM"
                                  options={CcMonthOptions}
                                  className={css.clsFormSelect}
                                  required={true}  // Make this field required
                                  showError={false}
                                  errors={errors}
                                  touched={touched}
                                />
                                <SelectField
                                  name="cardExpiryYear"
                                  label=""
                                  placeholder="YY"
                                  options={getCcYearOptions(values.cardExpYear)}
                                  className={css.clsFormSelect}
                                  required={true}  // Make this field required
                                  showError={false}
                                  errors={errors}
                                  touched={touched}
                                />

                                {/* Custom error message for both fields */}
                                {((errors?.cardExpiryMonth && touched?.cardExpiryMonth) ||
                                  (errors?.cardExpiryYear && touched?.cardExpiryYear)) && (
                                    <div className={classNames(css.clsFormLabel, css.text_danger, css.clsErrorMeaasge)}>
                                      {errors?.cardExpiryMonth || errors?.cardExpiryYear}
                                    </div>
                                  )}

                              </div>
                            </div>
                          </div>
                          <div className={classNames(css.pb_20, "col-xl-3 col-lg-6 col-12")}>
                            <FormField name="cardCode" label="CVV" placeholder={cardDetails?.code === 'amex' ? '****' : '***'} errors={errors} touched={touched} required={true} />
                          </div>
                        </div>
                      </div>

                      <div className="clearfix pt-4">
                        <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}> Billing Address </h4>
                        <div className="row pt-2">
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="billingStreetAddress" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} required={true} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="billingStreetAddress2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="billingCity" label="City" placeholder="City" errors={errors} touched={touched} required={true} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <div className="row">
                              <div className="col-6">
                                <div className={css.clsFormGroup}>
                                  <label className={classNames(
                                    css.clsFormLabel,
                                    errors['billingState'] && touched['billingState'] && css.text_danger
                                  )}> *State</label>

                                  <SelectField
                                    name="billingState"
                                    label=""
                                    placeholder="State"
                                    options={stateOptions}
                                    className={css.clsFormSelect}
                                    required={true}  // Make this field required
                                    showError={true}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <FormField name="billingZip" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} required={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classNames(css.clsModalFooter)}>
                    <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} type="button" onClick={handleClose}> Cancel</button>
                    <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit"> {isEdit ? "Confirm Changes" : "Confirm"} </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(AddPaymentPopup);
AddPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
AddPaymentPopup.close = () => closePopup({ popup: registrationId });

const FormField = ({ name, label, type = 'text', placeholder, required, errors, touched, disabled = false, onChange }) => {

  const { setFieldValue } = useFormikContext();

  const handleInputChange = (e) => {
    let value = e.target.value;
    setFieldValue(name, value);
    if (onChange) {
      onChange(value)
    }
  };

  return (
    <div className={css.clsFormGroup}>
      <label
        className={classNames(
          css.clsFormLabel,
          errors[name] && touched[name] && css.text_danger
        )}
      >
        {required && '*'}{label}
      </label>
      <Field
        name={name}
        type={type}
        className={classNames(
          css.clsFormControl,
          errors[name] && touched[name] && css.clsBorderDanger,
          { [css.disabled]: disabled }
        )}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleInputChange}
      />
      <ErrorMessage name={name} component="div" className={classNames(
        css.clsFormLabel,
        css.text_danger,
        css.clsErrorMeaasge
      )} />
    </div>
  )
};


const SelectField = ({ name, label, options, placeholder, required, showError = true, errors, touched, disabled }) => {
  return (
    <>
      <Field
        as="select"
        name={name}
        className={classNames(css.clsFormControl, css.clsFormSelect,
          errors[name] && touched[name] && css.clsBorderDanger,
          { [css.disabled]: disabled }
        )}
        style={{ backgroundImage: `url(${downArrow})` }}
      >
        <option value="" disabled hidden>
                    {placeholder}
          </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      {showError ? (<ErrorMessage name={name} component="div" className={classNames(
        css.clsFormLabel,
        css.text_danger,
        css.clsErrorMeaasge
      )} />) : ''}

    </>
  );
};



export default AddPaymentPopup;
