// src/app/AccountNew/Team/validationSchemas.js

import * as Yup from "yup";

export const chooseTierDefaultValue = {type: ""}
export const chooseCreateTeamDefaultValue = {firstName: "" ,lastName: "",phone:"",username:"",confirmEmail:"",password:"",confirmPassword: "",spendingLimit:"0",propertyLimit:"0",profilePicture:"",security_domain_id:"1"}
export const step1ValidationSchema = Yup.object().shape({
  type: Yup.string().required('You must select a tier before continuing.')
});

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone Number is required.") // Check empty
  .test(
    "is-valid-phone",
    "Phone Number must be exactly 10 digits.",
    (value) => {
      if (!value) return true; // Skip test if empty
      const digitsOnly = value.replace(/\D/g, "");
      return digitsOnly.length === 10;
    }
  ),
  username: Yup.string()
    .transform((value) => value?.trim())
    .email("Invalid email")
    .required("Email is required"),
  confirmEmail: Yup.string()
    .transform((value) => value?.trim())
    .oneOf([Yup.ref("username"), null], "Emails must match")
    .required("Please confirm your email"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
    spendingLimit: Yup.string(),
    propertyLimit: Yup.string(),
    profilePicture: Yup.string()
});

export const editProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone Number is required.") // Check empty
  .test(
    "is-valid-phone",
    "Phone Number must be exactly 10 digits.",
    (value) => {
      if (!value) return true; // Skip test if empty
      const digitsOnly = value.replace(/\D/g, "");
      return digitsOnly.length === 10;
    }
  ),
  email: Yup.string()
  .transform((value) => value?.trim())
    .email("Invalid email")
    .required("Email is required"),
   confirmEmail: Yup.string()
    .transform((value) => value?.trim())
    .oneOf([Yup.ref("email"), null], "Emails must match")
    .required("Please confirm your email"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password")
});


export const reviewTeamValidationSchema = Yup.object().shape({
  agreeToTerms: Yup.boolean().default(false).required("You must agree to the terms"),
});


export const getFullDataURL = (base64String) => {
  // Decode the Base64 string to check its format
  const binaryString = atob(base64String);

  // Get the first 4 bytes (magic number) as hexadecimal
  const first4Bytes = binaryString
      .slice(0, 4)
      .split("")
      .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
      .join(" ");

  // Determine the MIME type based on the magic number
  let mimeType = "";
  if (first4Bytes === "89 50 4e 47") {
      mimeType = "image/png";
  } else if (first4Bytes.startsWith("ff d8 ff")) {
      mimeType = "image/jpeg";
  } else if (binaryString.trim().startsWith("<svg")) {
      mimeType = "image/svg+xml";
  } else {
      throw new Error("Unknown image format");
  }

  // Construct the full Data URL
  return `data:${mimeType};base64,${base64String}`;
}