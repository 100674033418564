import { CALL_API } from 'store/middleware/api';
import { setCode } from 'store/middleware/api/activate';
import { isProduction } from 'utils/brand';
import apiService from './apiService';
import { billingDashboardMock, defaultPaymentSettingList, getBillingList, teamRes, walletListMock } from './constants';
import { joinPath } from 'utils/string';

export const AUTHENTICATE = ('AUTHENTICATE');
export const AUTHENTICATION_SUCCESS = ('AUTHENTICATION_SUCCESS');
export const AUTHENTICATION_ERROR = ('AUTHENTICATION_ERROR');
export const LOAD_USER_PROFILE_SUCCESS = ('LOAD_USER_PROFILE_SUCCESS');
export const LOAD_MARKETING_PROFILE_SUCCESS = ('LOAD_MARKETING_PROFILE_SUCCESS');
export const LOAD_LEAD_AUTOMATOR_SUCCESS = ('LOAD_LEAD_AUTOMATOR_SUCCESS');
export const LOAD_TEAM_MEMBERS_SUCCESS = ('LOAD_TEAM_MEMBERS_SUCCESS');
export const LOAD_TEAM_MEMBERS_ERROR = ('LOAD_TEAM_MEMBERS_ERROR');
export const SET_SELECTED_TEAM_MEMBER = ('SET_SELECTED_TEAM_MEMBER');
export const LOAD_PREPAID_CREDITS_SUCCESS = ('LOAD_PREPAID_CREDITS_SUCCESS');
export const LOAD_PLAN_SUCCESS = ('LOAD_PLAN_SUCCESS');

export function authenticate(token, activationCode, overtake, afterSuccess, afterError) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: '/resource/auth',
    query: {
      t: token,
      c: activationCode,
      overtake,
      dev: !isProduction(),
    },
    startType: AUTHENTICATE,
    successType: AUTHENTICATION_SUCCESS,
    errorType: AUTHENTICATION_ERROR,
    afterSuccess: (res) => {
      setCode(res.response.activationCode);
      sessionStorage.setItem('isLoggedIn', JSON.stringify(true))
      sessionStorage.removeItem('logOutClicked')
      if (afterSuccess) afterSuccess(res);
    },
    afterError,
  };

  return result;
}

export const LOGOUT = ('LOGOUT');
export const LOGOUT_SUCCESS = ('LOGOUT_SUCCESS');
export const LOGOUT_ERROR = ('LOGOUT_ERROR');

export function logout(mode) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: '/resource/auth/logout',
    query: { mode },
    startType: LOGOUT,
    successType: LOGOUT_SUCCESS,
    errorType: LOGOUT_ERROR,
  };

  return result;
}

export const SET_LOGOUT = ('SET_LOGOUT');
export const setLogout = message => ({ type: SET_LOGOUT, message });

export const SET_CHAT_VISIBLE = ('SET_CHAT_VISIBLE');
export const setChatVisible = visible => ({ type: SET_CHAT_VISIBLE, visible });

export const EXTEND_SESSION = ('EXTEND_SESSION');
export const EXTEND_SESSION_SUCCESS = ('EXTEND_SESSION_SUCCESS');
export const EXTEND_SESSION_ERROR = ('EXTEND_SESSION_ERROR');

export function extendSession() {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: '/resource/auth/extend',
    startType: EXTEND_SESSION,
    successType: EXTEND_SESSION_SUCCESS,
    errorType: EXTEND_SESSION_ERROR,
  };

  return result;
}

export const SAVE_PRELIMINARY_DATA = ('SAVE_PRELIMINARY_DATA');
export const SAVE_PRELIMINARY_DATA_SUCCESS = ('SAVE_PRELIMINARY_DATA_SUCCESS');
export const SAVE_PRELIMINARY_DATA_ERROR = ('SAVE_PRELIMINARY_DATA_ERROR');

export function savePreliminaryData(body, afterError) {
  const result = {};
  result[CALL_API] = {
    method: 'post',
    path: '/resource/auth/pre',
    body,
    afterError,
    startType: SAVE_PRELIMINARY_DATA,
    successType: SAVE_PRELIMINARY_DATA_SUCCESS,
    errorType: SAVE_PRELIMINARY_DATA_ERROR,
  };

  return result;
}

export const SAVE_PROFILE = ('SAVE_PROFILE');
export const SAVE_PROFILE_SUCCESS = ('SAVE_PROFILE_SUCCESS');
export const SAVE_PROFILE_ERROR = ('SAVE_PROFILE_ERROR');

export function saveProfile(body) {
  const result = {};
  result[CALL_API] = {
    caseTransform: true,
    method: 'post',
    body,
    path: '/resource/auth/ps4/user/profile',
    startType: SAVE_PROFILE,
    successType: SAVE_PROFILE_SUCCESS,
    errorType: SAVE_PROFILE_ERROR,
  };

  return result;
}

export const DISABLE_ALERT = ('DISABLE_ALERT');
export const DISABLE_ALERT_SUCCESS = ('DISABLE_ALERT_SUCCESS');
export const DISABLE_ALERT_ERROR = ('DISABLE_ALERT_ERROR');

export function disableAlert(id, afterSuccess) {
  const result = {};
  result[CALL_API] = {
    caseTransform: true,
    method: 'post',
    path: `/resource/auth/ps4/user/alerts/${String(id)}`,
    startType: DISABLE_ALERT,
    successType: DISABLE_ALERT_SUCCESS,
    errorType: DISABLE_ALERT_ERROR,
    afterSuccess,
  };

  return result;
}

export const UPGRADE_ACCOUNT = ('UPGRADE_ACCOUNT');
export const UPGRADE_ACCOUNT_SUCCESS = ('UPGRADE_ACCOUNT_SUCCESS');
export const UPGRADE_ACCOUNT_ERROR = ('UPGRADE_ACCOUNTERROR');

export function upgradeAccount(user, catalogProductId, planMrcId, quantity, approvedAmount, accountPassword, afterSuccess) {
  const result = {};
  result[CALL_API] = {
    method: 'post',
    body: user,
    query: { catalogProductId, planMrcId, quantity, approvedAmount, accountPassword },
    path: '/resource/auth/ps4/user/upgrade',
    startType: UPGRADE_ACCOUNT,
    successType: UPGRADE_ACCOUNT_SUCCESS,
    errorType: UPGRADE_ACCOUNT_ERROR,
    afterSuccess,
  };

  return result;
}

export const REMOVE_ERROR = ('REMOVE_ERROR');

export function removeError() {
  return {
    type: REMOVE_ERROR,
  };
}

export const SAVE_PREPAID_CREDIT = ('SAVE_PREPAID_CREDIT');
export const SAVE_PREPAID_CREDIT_SUCCESS = ('SAVE_PREPAID_CREDIT_SUCCESS');
export const SAVE_PREPAID_CREDIT_ERROR = ('SAVE_PREPAID_CREDIT_ERROR');
export const GET_PREPAID_CREDIT = ('GET_PREPAID_CREDIT');
export const GET_PREPAID_CREDIT_SUCCESS = ('GET_PREPAID_CREDIT_SUCCESS');
export const GET_PREPAID_CREDIT_ERROR = ('GET_PREPAID_CREDIT_ERROR');
export const LOAD_OFFERS = ('LOAD_OFFERS');
export const LOAD_OFFERS_SUCCESS = ('LOAD_OFFERS_SUCCESS');
export const LOAD_OFFERS_ERROR = ('LOAD_OFFERS_ERROR');
export const SAVE_OFFER = ('SAVE_OFFER');
export const SAVE_OFFER_SUCCESS = ('SAVE_OFFER_SUCCESS');
export const SAVE_OFFER_ERROR = ('SAVE_OFFER_ERROR');
export const DELETE_OFFER = ('DELETE_OFFER');
export const DELETE_OFFER_SUCCESS = ('DELETE_OFFER_SUCCESS');
export const DELETE_OFFER_ERROR = ('DELETE_OFFER_ERROR');
export const LOAD_OFFER_REPORT = ('LOAD_OFFER_REPORT');
export const LOAD_OFFER_REPORT_SUCCESS = ('LOAD_OFFER_REPORT_SUCCESS');
export const LOAD_OFFER_REPORT_ERROR = ('LOAD_OFFER_REPORT_ERROR');
export const GET_USERS = ('GET_USERS');
export const GET_USERS_SUCCESS = ('GET_USERS_SUCCESS');
export const GET_USERS_ERROR = ('GET_USERS_ERROR');
export const SAVE_USER = ('SAVE_USER');
export const SAVE_USER_SUCCESS = ('SAVE_USER_SUCCESS');
export const SAVE_USER_ERROR = ('SAVE_USER_ERROR');
export const DELETE_USER = ('DELETE_USER');
export const DELETE_USER_SUCCESS = ('DELETE_USER_SUCCESS');
export const DELETE_USER_ERROR = ('DELETE_USER_ERROR');
export const GET_TRANSACTIONS = ('GET_TRANSACTIONS');
export const GET_TRANSACTIONS_SUCCESS = ('GET_TRANSACTIONS_SUCCESS');
export const GET_TRANSACTIONS_ERROR = ('GET_TRANSACTIONS_ERROR');
export const SAVE_LAYOUT = ('SAVE_LAYOUT');
export const SAVE_LAYOUT_SUCCESS = ('SAVE_LAYOUT_SUCCESS');
export const SAVE_LAYOUT_ERROR = ('SAVE_LAYOUT_ERROR');
export const DELETE_LAYOUT = ('DELETE_LAYOUT');
export const DELETE_LAYOUT_SUCCESS = ('DELETE_LAYOUT_SUCCESS');
export const DELETE_LAYOUT_ERROR = ('DELETE_LAYOUT_ERROR');
export const SAVE_TAG = ('SAVE_TAG');
export const SAVE_TAG_SUCCESS = ('SAVE_TAG_SUCCESS');
export const SAVE_TAG_ERROR = ('SAVE_TAG_ERROR');
export const DELETE_TAG = ('DELETE_TAG');
export const DELETE_TAG_SUCCESS = ('DELETE_TAG_SUCCESS');
export const DELETE_TAG_ERROR = ('DELETE_TAG_ERROR');
export const GET_HS_IDENTIFICATION_TOKEN = ('GET_HS_IDENTIFICATION_TOKEN');
export const GET_HS_IDENTIFICATION_TOKEN_SUCCESS = ('GET_HS_IDENTIFICATION_TOKEN_SUCCESS');
export const GET_HS_IDENTIFICATION_TOKEN_ERROR = ('GET_HS_IDENTIFICATION_TOKEN_ERROR');



// Billing 
// Actions Types

export const LOAD_BILLING_DASHBOARD_SUCCESS = ('LOAD_BILLING_DASHBOARD_SUCCESS');
export const LOAD_BILLING_DASHBOARD_ERROR = ('LOAD_BILLING_DASHBOARD_ERROR');

export const LOAD_PAYMENT_LIST = ('LOAD_PAYMENT_LIST');
export const LOAD_PAYMENT_LIST_SUCCESS = ('LOAD_PAYMENT_LIST_SUCCESS');
export const LOAD_PAYMENT_LIST_ERROR = ('LOAD_PAYMENT_LIST_ERROR');

export const ADD_PAYMENT_METHOD = ('ADD_PAYMENT_METHOD');
export const ADD_PAYMENT_METHOD_SUCCESS = ('ADD_PAYMENT_METHOD_SUCCESS');
export const ADD_PAYMENT_METHOD_ERROR = ('ADD_PAYMENT_METHOD_ERROR');

export const UPDATE_PAYMENT_METHOD = ('UPDATE_PAYMENT_METHOD');
export const UPDATE_PAYMENT_METHOD_SUCCESS = ('UPDATE_PAYMENT_METHOD_SUCCESS');
export const UPDATE_PAYMENT_METHOD_ERROR = ('UPDATE_PAYMENT_METHOD_ERROR');

export const DELETE_PAYMENT_METHOD = ('DELETE_PAYMENT_METHOD');
export const DELETE_PAYMENT_METHOD_SUCCESS = ('DELETE_PAYMENT_METHOD_SUCCESS');
export const DELETE_PAYMENT_METHOD_ERROR = ('DELETE_PAYMENT_METHOD_ERROR');



export const LOAD_DEFAULT_PAYMENT_SETTING = ('LOAD_DEFAULT_PAYMENT_SETTING');
export const LOAD_DEFAULT_PAYMENT_SETTING_SUCCESS = ('LOAD_DEFAULT_PAYMENT_SETTING_SUCCESS');
export const LOAD_DEFAULT_PAYMENT_SETTING_ERROR = ('LOAD_DEFAULT_PAYMENT_SETTING_ERROR');


export const LOAD_WALLET_HISTORY_LIST = ('LOAD_WALLET_HISTORY_LIST');
export const LOAD_WALLET_HISTORY_LIST_SUCCESS = ('LOAD_WALLET_HISTORY_LIST_SUCCESS');
export const LOAD_WALLET_HISTORY_LIST_ERROR = ('LOAD_WALLET_HISTORY_LIST_ERROR');




function request(path, method, startType, successType, errorType, body, { afterSuccess, afterError, params, successParams, download = false, query } = {}) {
  return {
    [CALL_API]: {
      method,
      startType,
      successType,
      errorType,
      body,
      afterSuccess,
      afterError,
      params,
      successParams,
      query,
      download,
      path: joinPath('/resource/auth/ps4', path),
      // ...(method === 'post' && !isFormData(body) ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : {}),
    },
  };
}


const user = (path, method, startType, successType, errorType, body, params) => request(joinPath('user', path), method, startType, successType, errorType, body, params);
const offer = (path, method, startType, successType, errorType, body, params) => user(joinPath('offers', path), method, startType, successType, errorType, body, params);


export const getUsers = () => user('team/members', 'get', GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR);

export const saveUser = (data, afterSuccess) => user('team/members', 'put', SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_ERROR, data, { afterSuccess });

export const deleteUser = (id, afterSuccess) => user(`team/members/${id}`, 'delete', DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR, null, { afterSuccess });

export const savePrepaidCredit = (paymentMethodId, amount, cardCode, afterSuccess) => user('credits', 'post', SAVE_PREPAID_CREDIT, SAVE_PREPAID_CREDIT_SUCCESS, SAVE_PREPAID_CREDIT_ERROR, null, { query: { paymentMethodId, amount, cardCode }, afterSuccess });

export const getPrepaidCredit = afterSuccess => user('credits', 'get', GET_PREPAID_CREDIT, GET_PREPAID_CREDIT_SUCCESS, GET_PREPAID_CREDIT_ERROR, null, { afterSuccess });

export const getTransactions = () => user('transactions', 'get', GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_ERROR);

export const loadOffers = savedPropertyId => offer(`property/${savedPropertyId}`, 'get', LOAD_OFFERS, LOAD_OFFERS_SUCCESS, LOAD_OFFERS_ERROR);

export const saveOffer = (offerAnalysis, afterSuccess) => offer('', 'put', SAVE_OFFER, SAVE_OFFER_SUCCESS, SAVE_OFFER_ERROR, offerAnalysis, { afterSuccess });

export const deleteOffer = (id, afterSuccess) => offer(id, 'delete', DELETE_OFFER, DELETE_OFFER_SUCCESS, DELETE_OFFER_ERROR, null, { afterSuccess });

export const loadOfferReport = (offerAnalysis, reportCode) => offer(`reports/${reportCode}`, 'post', LOAD_OFFER_REPORT, LOAD_OFFER_REPORT_SUCCESS, LOAD_OFFER_REPORT_ERROR, offerAnalysis);

export const loadSavedOfferReport = (id, reportCode) => offer(`${id}/reports/${reportCode}`, 'get', LOAD_OFFER_REPORT, LOAD_OFFER_REPORT_SUCCESS, LOAD_OFFER_REPORT_ERROR);

export const saveLayout = (layout, afterSuccess) => user('layouts', 'put', SAVE_LAYOUT, SAVE_LAYOUT_SUCCESS, SAVE_LAYOUT_ERROR, layout, { afterSuccess });

export const deleteLayout = (id, afterSuccess) => user(`layouts/${id}`, 'delete', DELETE_LAYOUT, DELETE_LAYOUT_SUCCESS, DELETE_LAYOUT_ERROR, null, { afterSuccess });

export const saveTag = (tag, afterSuccess) => user('tags', 'put', SAVE_TAG, SAVE_TAG_SUCCESS, SAVE_TAG_ERROR, tag, { afterSuccess });

export const deleteTag = id => user(`tags/${id}`, 'delete', DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_ERROR);

export const getHsIdentificationToken = (afterSuccess, afterError) => user('hsidtoken', 'get', GET_HS_IDENTIFICATION_TOKEN, GET_HS_IDENTIFICATION_TOKEN_SUCCESS, GET_HS_IDENTIFICATION_TOKEN_ERROR, null, { afterSuccess, afterError });

// Marketing Profile
export const loadMarketingProfileSuccess = (payload) => ({
  type: LOAD_MARKETING_PROFILE_SUCCESS,
  payload, // The data fetched from the API
});
export const loadUserProfileSuccess = (payload) => ({
  type: LOAD_USER_PROFILE_SUCCESS,
  payload,
});

export const loadPrepaidCreditsSuccess = (payload) => ({
  type: LOAD_PREPAID_CREDITS_SUCCESS,
  payload,
});

export const loadPlanSuccess = (payload) => ({
  type: LOAD_PLAN_SUCCESS,
  payload,
});

export const loadLeadAutomatorSuccess = (payload) => ({
  type: LOAD_LEAD_AUTOMATOR_SUCCESS,
  payload,
});

export const loadTeamMembersSuccess = (payload) => ({
  type: LOAD_TEAM_MEMBERS_SUCCESS,
  payload,
});

export const loadTeamMembersFailure = (payload) => ({
  type: LOAD_TEAM_MEMBERS_ERROR,
  payload,
});


export const selectedTeamMember = (payload) => ({
  type: SET_SELECTED_TEAM_MEMBER,
  payload,
});

// Billing
export const loadBillingDashboardSuccess = (payload) => ({
  type: LOAD_BILLING_DASHBOARD_SUCCESS,
  payload,
});

export const loadBillingDashboardError = (payload) => ({
  type: LOAD_BILLING_DASHBOARD_ERROR,
  payload,
});

export const loadWalletSuccess = (payload) => ({
  type: LOAD_PAYMENT_LIST_SUCCESS,
  payload,
});

export const loadWalletError = (payload) => ({
  type: LOAD_PAYMENT_LIST_ERROR,
  payload,
});

export const loadWalletDefaultSettingSuccess = (payload) => ({
  type: LOAD_DEFAULT_PAYMENT_SETTING_SUCCESS,
  payload,
});

export const loadWalletDefaultSettingError = (payload) => ({
  type: LOAD_DEFAULT_PAYMENT_SETTING_ERROR,
  payload,
});

export const loadBillingHistorySuccess = (payload) => ({
  type: LOAD_WALLET_HISTORY_LIST_SUCCESS,
  payload,
});

export const loadBillingHistoryError = (payload) => ({
  type: LOAD_WALLET_HISTORY_LIST_ERROR,
  payload,
});




export const getTeamMembers =  (userID) => {
  return async (dispatch) => {
    try {
      const response = await apiService.get('/ps/team/users', { userId: userID });
      // const data =await teamRes
      dispatch(loadTeamMembersSuccess(response.data.teamMembers));
    } catch (error) {
      dispatch(loadTeamMembersFailure(error.message));
    }
  };
};


// Undo Changes
export const UndoPendingTeamChangesAPI = async (payload) => {
  try {
    const response = await apiService.put('/team/member/undo/', payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response.data };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

// teamdowngrade
export const DowngradeMemberTeamAPI = async (payload) => {
  try {
    const response = await apiService.put('/team/member/downgrade', payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response.data };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const RemoveTeamMemberAPI = async (payload) => {
  try {
    const response = await apiService.delete('/team/member/remove', payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response.data };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};


// Billing section 

export const getBillingDashboardAPI =  (userID) => {
  return async (dispatch) => {
    try {
      // const data = await apiService.get(`/ps/billing/details/${userId}`);
      const data =await billingDashboardMock
      dispatch(loadBillingDashboardSuccess(data));
    } catch (error) {
      dispatch(loadBillingDashboardError(error.message));
    }
  };
};

export const loadWalletList =  (userID) => {
  return async (dispatch) => {
    try {
      const response = await apiService.get(`/wallet/all/${userID}`);
      // const data =await walletListMock
      dispatch(loadWalletSuccess(response?.data?.wallets));
    } catch (error) {
      dispatch(loadWalletError(error?.message));
    }
  };
};

export const addWalletAPI = async (payload) => {
  try {
    const response = await apiService.post('/wallet/add', payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const updateWalletAPI = async (payload) => {
  try {
    const response = await apiService.put('/wallet/update', payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const deleteWalletAPI = async (userID,paymentId) => {
  try {
    const response = await apiService.delete(`/wallet/delete/${userID}/${paymentId}`);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};


export const loadDefaultPaymentSettingList =  (userID) => {
  return async (dispatch) => {
    try {
      const response = await apiService.get(`/wallet/all/defaults/${userID}`);
      // const data =await defaultPaymentSettingList
      dispatch(loadWalletDefaultSettingSuccess(response?.data?.wallets));
    } catch (error) {
      dispatch(loadWalletDefaultSettingError(error?.message));
    }
  };
};

export const updateDefaultPaymentSettingList = async (payload) => {
  try {
    const response = await apiService.put(`/wallet/all/defaults`, payload);
    if (response.status === 200 || response.status === 201) {
      return { success: true, data: response };
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const loadBillingHistoryList =  (userId) => {
  return async (dispatch) => {
    try { 
      const response = await apiService.get(`/billing/history/${userId}`);
      // const data =await getBillingList
      dispatch(loadBillingHistorySuccess(response?.data?.history));
    } catch (error) {
      dispatch(loadBillingHistoryError(error.message));
    }
  };
};
