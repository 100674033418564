import React from "react";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import Modal from "components/base/Modal";
import css from "../style.scss";
import classNames from "classnames";
import { formatToLongDate } from "../../../utils/date/formatDate";
import { useSelector } from "react-redux";
import { selectSelectedTeamMember ,selectUserId } from "data/user/selectors";
import { RemoveTeamMemberAPI } from "data/user/actions";

const RemoveTeamMemberPopup = (props) => {

  const teamMemberDetails = useSelector(selectSelectedTeamMember).toJS();
  const userId = useSelector(selectUserId)

  const handleClose = () => {
    props.closePopup();
  };

  const handleConfirmChanges = async () => {
    console.log("Remove Team Member");
    const req = { 
        userId: userId,
        teamMemberId : teamMemberDetails?.id
    }
    // Enable after API works
    try {
      const response = await RemoveTeamMemberAPI(req); // Await the async function
      if (response.success) {
        console.log("successful:", response.teamMember);
        props.closePopup();
      } else {
        console.error("failed:", response.error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="RemoveTeamMemberPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >
      <div className={classNames(css.modal_lg, "modal-dialog m-0")}>
        <div className={classNames(css.clsModalContent, "modal-content w-100")}>
          <div className={classNames(css.clsModalContentBody, "modal-body rounded")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Remove Team Member?</h2>
            </div>

            <div className={classNames(css.clsModalBody)}>
              <div className={classNames(css.clsModalBodyInner, "clearfix w-100")}>
                <h3 className={classNames(css.clsNameSubTitle,)}>
                  {teamMemberDetails?.fullName}
                </h3>
                <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle, 'pt-0')}>
                 {teamMemberDetails?.scoutUser ? 'Scout' : 'Full Access'}
                </h5>
                <div className={classNames(css.clsGreyCircle)}></div>
              </div>

              <div className={classNames("clearfix w-100")}>
                <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle,)}>
                  You are about to Delete this Team Member.
                </h5>
                
                {teamMemberDetails?.scoutUser ? (
                  <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle,)}>
                  You will have access to the Team Member until your next billing cycle, on {formatToLongDate(teamMemberDetails.accountPlanMrc?.billDate)}.
                  </h5>
                ) : (
                  <h5 className={classNames(css.clsModalSubTitle, css.clsReloadSubTitle,)}>
                  You will have access to the Team Member <span>and Full Access Team Member slot</span> until your next billing cycle, on {formatToLongDate(teamMemberDetails.accountPlanMrc?.billDate)}.
                </h5>
                )}
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
              <button className={classNames(css.btn_lg, css.clsBtnOrng)} onClick={handleConfirmChanges}>  Confirm </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(RemoveTeamMemberPopup);
RemoveTeamMemberPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
RemoveTeamMemberPopup.close = () => closePopup({ popup: registrationId });

export default RemoveTeamMemberPopup;
