import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import { useDispatch, useSelector } from "react-redux";
import { selectUserId , selectBillingDashboard } from "data/user/selectors";
import { getBillingDashboardAPI } from "data/user/actions";
import { useEffect } from "react";
import { useState } from "react";

const BillingSection = (props) => {

   const userId = useSelector(selectUserId)

   const dispatch = useDispatch();

   const dashboard = useSelector(selectBillingDashboard)

   const [BillingList, setBillingList] = useState([])
  
   

   
  useEffect(() => {
    if(dispatch && userId) {
      dispatch(getBillingDashboardAPI(userId))
    }
  }, [dispatch,userId])

  useEffect(() => {
    if(dashboard) {
      setBillingList([
        {
          type: 'Subscription',
          remainingdays: dashboard?.mainSubscription, 
          expires: dashboard?.mainSubscriptionExpiringSoon,
          renewDate : dashboard?.mainSubscriptionRenews,
          expireDate: dashboard?.mainSubscriptionExpires,
          fullAccessExpiring: false
        },
        {
          type: 'Lead Automator',
          remainingdays: dashboard?.leadAutomator,
          expires: dashboard?.leadAutomatorExpiringSoon,
          renewDate : dashboard?.leadAutomatorRenews,
          expireDate: dashboard?.leadAutomatorExpires,
          fullAccessExpiring: dashboard?.leadAutomatorExpiringSoon,
          fullAccessExpiringCount: dashboard?.leadAutomatorFAUserExpiring
        },
        {
          type: 'Team',
          remainingdays: dashboard?.teamMember,
          expires: dashboard?.teamMemberExpiringSoon,
          renewDate : dashboard?.teamMemberRenews,
          expireDate: dashboard?.teamMemberExpires,
          fullAccessExpiring: dashboard?.teamMemberExpiringSoon,
          fullAccessExpiringCount: dashboard?.teamMemberFAUserExpiring
        }
      ]
    )
    }
  }, [dashboard])
  



  const goToBilling = (tab) => {
    props.history.push(`/accountnew/billing/${tab}`);
  }

  return (
    <div className={classNames(css.clsCard, css.clsBillingCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <div className={css.clearfix}>
          <h4 className={classNames(css.clsCardTitle)}>Billing</h4>
        </div>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={() => goToBilling("0")}>Manage</a>
      </div>

      <div className={css.clsCardBody}>
        <ul className={classNames(css.clsBillingList, css.unstyled)}>
          {BillingList && BillingList.map((billing,index) => {
            return (
              <BillingSectionEach billing={billing} key={index} index={index} />
            )
          })}
        </ul>
      </div>

      <div className={classNames(css.clsCardFooter, css.text_center)}>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={() => goToBilling("1")}>View Billing History</a>
      </div>
    </div>
  );
};




 
 export const BillingSectionEach = ({billing , index}) => {

  const [isExpiringSoonHovered, setIsExpiringSoonHovered] = useState(false);

   return (
    <li key={index}>
    <h3 className={css.clsBillingListTitle}>{billing?.type}</h3>
    <div className={classNames(css.d_flex, css.align_items_center)}>
      <h2 className={css.clsBillingCount}>{billing?.remainingdays}</h2>
      <div className={css.clearfix}>
        <h4 className={css.clsBillingDaysTxt}>Days Remaining</h4>
        <h5 className={classNames(css.clsBillingRenewTxt, {
          [css.cssBillingExpiresTxt]: billing?.expires
        })}>{billing?.expires ? `Expires ${billing?.expireDate}` : `Renews  ${billing?.renewDate}`}</h5>
      </div>
    </div>
    {billing?.fullAccessExpiring && (
      <ExpiringSoonBillingSection billing={billing} onHoverChange={setIsExpiringSoonHovered} />
      // <p className={classNames(css.mt_5px, css.clsBtnSm, css.clsBtnDanger)}>{billing?.fullAccessExpiringCount}x Full-Access Expiring</p>
    )}
  </li>
   )
 }
 

const ExpiringSoonBillingSection = ({billing, onHoverChange }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange(true); // ✅ Send true to parent
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange(false); // ✅ Send false to parent
  };

  const getBillingStatus = (billing, isHovered) => {
    if (isHovered) return "Review Pending Changes";
  
    const statusMap = {
      Subscription: "Changes Pending",
      "Lead Automator": "Expiring Soon",
      Team: `${billing?.fullAccessExpiringCount}x Full-Access Expiring`
    };
  
    return statusMap[billing.type] || "";
  };

  return (
    <p
      className={classNames(css.mt_5px,css.clsBtnSm, {
        [css.clsBtnDanger]: !isHovered,
        [css.clsBtnPrimaryGreen]: isHovered,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
     {getBillingStatus(billing, isHovered)} 
    </p>
  );
};

export default withRouter(BillingSection);
