import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deferExecutor } from 'app/DeferredOnLocation';
import { saveProfile } from 'data/user';
import { selectIsLoading, selectProfile } from 'data/user/selectors';
import classNames from "classnames";
import { useState } from 'react';
import PageTitle from '../components/PageTitle';
import PlanCard from './PlanCard';
import { annualPlans, monthlyPlans } from './planUtils';
import css from './style.scss';
import { useEffect } from 'react';


const ChooseTier = (props) => {
    const { planType } = props.match.params;
    const [annualPlan, setAnnualPlan] = useState(false);

    useEffect(() => {
        if (planType === 'annual') {
            setAnnualPlan(true);
        }
    }, [props])

    const selectPlan = (plan) => {
        console.log(plan);
        props.history.push(`/accountnew/plan/review`);
    }

    const goToAccount = () => {
        props.history.push(`/accountnew/landing`);
      }

    return (
        <React.Fragment>
            <PageTitle
                title={'Choose A Plan Tier'}
                subTitle={'Prices shown are for base plans only and do not include add-on services or extra one-time payments'}
            />

            <div className={css.container}>
                <div className={css.planContainer}>
                    <div className={css.currentPlan}>Current Plan</div>
                    <div className={css.planName}>Essentials Monthly (25,000 Saves & Exports)</div>
                    <div>$99/mo</div>
                </div>
            </div>

            <div className={css.container}>
                <div className={css.tabContainer}>
                    <button onClick={() => setAnnualPlan(true)} className={annualPlan ? css.activeTab : css.tab}>Annual</button>
                    <button onClick={() => setAnnualPlan(false)} className={annualPlan ? css.tab : css.activeTab}>Monthly</button>
                </div>
            </div>

            {
                annualPlan ?
                    <div className={css.priceContainer}>
                        {
                            annualPlans.map((plan) =>
                                <PlanCard
                                    key={plan.planName}
                                    planName={plan.planName}
                                    planDescription={plan.planDescription}
                                    currentPrice={plan.currentPrice}
                                    discountPrice={plan.discountPrice}
                                    planIncludes={plan.planIncludes}
                                    extraValue={plan.extraValue}
                                    annualPlan={true}
                                    selectPlan={() => selectPlan(plan)}
                                />
                            )
                        }
                    </div>
                    :
                    <div className={css.priceContainer}>
                        {
                            monthlyPlans.map((plan) =>
                                <PlanCard
                                    key={plan.planName}
                                    planName={plan.planName}
                                    planDescription={plan.planDescription}
                                    currentPrice={plan.currentPrice}
                                    planIncludes={plan.planIncludes}
                                    selectPlan={() => selectPlan(plan)}
                                />
                            )
                        }
                    </div>
            }

            <div className={css.clsInnerFooter}>
                    <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)} onClick={() => goToAccount()}> Cancel</button>
                  </div> 
        </React.Fragment>
    );
}

export default withRouter(connect(state => ({
    profile: selectProfile(state).toJS(),
    isLoading: selectIsLoading(state)
}), {
    saveProfile
})(deferExecutor(ChooseTier)));
