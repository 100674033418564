import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import React from "react";
import { Field, ErrorMessage } from "formik";
import css from "./style.scss";
import accountCSS from "../style.scss";
import { useForm } from "react-hook-form";
import TextField from "components/MultiStepForm/TextField"
import DropdownField from "components/MultiStepForm/DropdownField"
import { useFormContext } from '../../../context/FormContext';
import { useEffect } from 'react';
import UploadProfilePicture from '../components/uploadProfilePicture';
import { useFormContext as useRHFormContext } from "react-hook-form";
import { useState } from 'react';
import { getFullDataURL } from './validationSchemas';
import CreditCardDropdown from '../components/CreditCardDropdown';


const CreateTeamProfile = (props) => {

  const { formData } = useFormContext();

  const { setValue, watch } = useRHFormContext();

  const [imageUrl, setImageUrl] = useState('');

  const setUploadImage = (field, value) => {
    if(value) {
      setValue(field, value); // Update the form field with the image
      setImageUrl(`data:image/png;base64,${value}`); // Set preview image
    } else {
      setImageUrl(null);
      setValue(field ? field : 'profilePicture', null);
    }
  };

  // setFieldValue
  


  return (
    <div className="container">
      <div className={css.clsTMCreateProfileSec}>
        <div className={css.clsTMCreateProfileHead}>
          <h2 className={css.clsMainTitle}>{props?.review ? 'Review Team Member Profile' : 'Create a Profile for Your New Team Member'}</h2>
          <p className={css.clsHeadPara}>Enter Profile and Account Information and Usage Limitations  for Your Team Member</p>
        </div>

        <div className={css.clsTMCreateProfileForm}>
          <div className="row justify-content-center" style={{"padding-bottom": '13rem'}}>
            <div className="col-lg-8">
              <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_16)}> General Information </h4>

                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="firstName" label="First Name" required={true} />
                    <TextField name="lastName" label="Last Name" required={true} />
                    <TextField name="phone" label="Phone Number" required={true} numbers={true} />
                  </div>

                  <div className={classNames("col-lg-6 col-12")}>
                    <div className={classNames(css.pb_12, css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                      <div className={accountCSS.clsSelectProfileImg}>
                        <label className={classNames(css.clsFormLabel)}>  Profile Picture </label>
                        <div className={accountCSS.clstProfileImg}>
                          {/* <img src={userImg} alt="Profile" /> */}
                           {imageUrl ? (
                                <img src={getFullDataURL(imageUrl)} alt="Profile" />
                            ) : (
                                <img src={userImg} alt="Profile" />
                            )}
                        </div>
                        <UploadProfilePicture key={imageUrl} label={imageUrl ? 'Edit Photo' : 'Add Photo'} filed={'profilePicture'} setFieldValue={setUploadImage}  setImageUrl={setImageUrl} />
                        {imageUrl && (
                                <a  href="#" className={accountCSS.clsAddTxt} onClick={() => setUploadImage()}>Remove Photo</a>
                            )}
                        {/* <a  href="#" className={accountCSS.clsAddTxt}>Edit Photo</a> */}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Account Information </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="username" label="Email/Username" placeholder="Email Address" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="confirmEmail" label="Confirm Email/Username" placeholder="Confirm Email Address" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="password" label="New Password" placeholder="New Password" type="password" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="confirmPassword" label="Confirm New Password" placeholder="Confirm New Password" type="password" />
                  </div>
                </div>
              </div>

            {formData.type !== 'SCOUT' && (
              <>
              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Usage Limits </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                     <TextField name="propertyLimit" label="Save Limit (Monthly)" placeholder="Save Limit" numbers={true} required={false} />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                  <TextField name="spendingLimit" label="Spending Limit (Monthly)" placeholder="Spending Limit" numbers={true} decimal={true} required={false} />
                  </div>
                </div>
              </div>

              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Billing </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                  <div className={classNames(css.clsFormGroup)}>
                  <label className={classNames(css.clsFormLabel)}> Default for Subscriptions</label>
                    <CreditCardDropdown 
                    tag={false}
                      label="Default for Subscriptions" 
                      options={[
                        { value: "EXM", label: "Save Limit (Monthly)" }
                      ]}
                    />
                    </div>
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                  <div className={classNames(css.clsFormGroup)}>
                  <label className={classNames(css.clsFormLabel)}> Default For In App Purchases</label>
                    <CreditCardDropdown 
                      tag={false}
                      name="spendingLimit" 
                      label="Default For In App Purchases" 
                      options={[
                        { value: "EXM", label: "Spending Limit (Monthly)" }
                      ]}
                    />
                    </div>
                  </div>
                </div>
              </div>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTeamProfile;